import React from 'react';
import styles from './styles.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { TongyongJiantou2Zuo } from '@sirius/icons';
import { useNavigate } from 'react-router-dom';
import { ROUTE_URLS } from '@system-global/config/route-urls';

const BackToLoginBtn: React.FC = () => {
  const intl = useIntl();
  const nav = useNavigate();

  return (
    <div className={styles.goBack} onClick={() => nav(ROUTE_URLS.login)}>
      <TongyongJiantou2Zuo style={{ fontSize: 16 }} />
      <span>
        <FormattedMessage id="后退到" values={{ page: intl.formatMessage({ id: '登录' }) }} />
      </span>
    </div>
  );
};

export default BackToLoginBtn;
