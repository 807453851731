import { useState, useCallback, useEffect, useMemo } from 'react';
import { Row, Col, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { productListGet } from '@api/system-global/__generate__/default/ProductService';
import { IApiProductResp, IApiQuotaConfig } from '@api/system-global/__generate__/default/models';
import PriceCard from './card';
import CustomSolution from './custom';
import styles from './styles.module.scss';

const OrderCheck = () => {
  const [loading, setLoading] = useState(true);
  const [productList, setProductList] = useState<IApiProductResp[]>([]);
  const [productType, setProductType] = useState<IApiProductResp['productType']>();
  const [amountPerMonth, setAmountPerMonth] = useState<number>(0);
  const [recommendType, setRecommendType] = useState('');

  const fetchProductList = useCallback(async () => {
    const res = await productListGet();
    setProductList(res?.data?.data?.products || []);
    setProductType(res?.data?.data?.recommendProductType || 'BASIC');
    setRecommendType(res?.data?.data?.recommendProductType || 'BASIC');
    setAmountPerMonth(res?.data?.data?.traffic30Days || 0);
    setLoading(false);
  }, []);

  const productData = useMemo<IApiProductResp | null>(() => {
    const data = productList.find(product => product.productType === productType);
    if (data) {
      return data;
    }
    return null;
  }, [productList, productType]);

  const recommendProduct = useMemo<IApiProductResp | null>(() => {
    const data = productList.find(product => product.productType === recommendType);
    if (data) {
      return data;
    }
    return null;
  }, [recommendType, productList]);

  useEffect(() => {
    fetchProductList();
  }, [fetchProductList]);

  if (loading) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.loading}>
          <Spin indicator={<LoadingOutlined spin color="" />} size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <div className={styles.title}>
          <FormattedMessage id="订单标题" />
        </div>
        <div className={styles.desc}>
          <FormattedMessage
            id="订单描述"
            values={{ amountPerMonth, recommendTypeName: recommendProduct?.productName || '' }}
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.solution}>
          <div className={styles.panelTitle}>
            <FormattedMessage id="订单选项" />
          </div>
          {productList.map(product => {
            const quotasValue = product.quotas?.reduce((map, cur) => {
              map[cur.quotaName] = cur.quotaCount;
              return map;
            }, {} as Record<IApiQuotaConfig['quotaName'], number>);

            return (
              <div
                className={classnames(styles.solutionCard, productType === product.productType ? styles.selected : '')}
                key={product.productId}
                onClick={() => setProductType(product.productType)}
              >
                <div className={styles.type}>
                  <div className={styles.typeTag}>{product.productName}</div>
                </div>
                <Row className={styles.amount}>
                  <Col span={7}>
                    <div className={styles.label}>
                      <FormattedMessage id="IP识别额度" />
                    </div>
                    <div className={styles.value}>{quotasValue.IP_IDENTIFY_QUOTA}</div>
                  </Col>
                  <Col span={6}>
                    <div className={styles.label}>
                      <FormattedMessage id="客户识别额度" />
                    </div>
                    <div className={styles.value}>{quotasValue.CONTACT_REVEAL_QUOTA}</div>
                  </Col>
                  <Col span={5}>
                    <div className={styles.label}>
                      <FormattedMessage id="公司数量" />
                    </div>
                    <div className={styles.value}>
                      <FormattedMessage id="无限制" />
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className={styles.label}>
                      <FormattedMessage id="浏览数据存储量" />
                    </div>
                    <div className={classnames(styles.value, styles.noborder)}>
                      <FormattedMessage id="无限制" />
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}

          <CustomSolution />
        </div>

        <div className={styles.priceInfo}>
          <div className={styles.panelTitle}>
            <FormattedMessage id="订单价格信息" />
          </div>
          {productData ? <PriceCard data={productData} /> : null}
        </div>
      </div>
    </div>
  );
};

export default OrderCheck;
