/** 全球搜接口 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 全球搜接口 IP地址库联系人 */
export async function getContactPagePost(payload: model.IApiGlobalSearchContactReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultContactListResp>({
    url: `/api/auth/global/getContactPage`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 全球搜接口 获取推荐词 */
export async function getGptRecommendPost(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultobject>({
    url: `/api/auth/global/getGptRecommend`,
    method: 'post',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 全球搜接口 获取关键词结果数量 */
export async function getProductTotalCountGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultobject>({
    url: `/api/auth/global/getProductTotalCount`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 全球搜接口 IP地址库详情页 */
export async function ipDetailGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultobject>({
    url: `/api/auth/global/ipDetail`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
