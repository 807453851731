import { useCallback, useState, useMemo } from 'react';
import { Form } from 'antd';
import { useStore } from '@system-global/store';
import { oAuthSignUpPost } from '@api/system-global/__generate__/default/UserSignupService';
import { localRegisterInfoHandler } from '@system-global/utils/common';

interface RegisterInfo {
  defaultEmail: string;
  accessCode: string;
}

export function useRegisterForm(regInfo: RegisterInfo) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const doLoginSucceed = useStore(state => state.login_doLoginSucceed);

  const initalValue = useMemo(() => {
    return {
      firstName: '',
      lastName: '',
      email: regInfo.defaultEmail,
    };
  }, [regInfo.defaultEmail]);

  const submit = useCallback(async () => {
    try {
      setLoading(true);
      const data = await form.validateFields();
      const res = await oAuthSignUpPost({
        accessCode: regInfo.accessCode,
        userIdentify: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        oauthType: 'GOOGLE',
      });

      const token = res?.data?.data?.token;
      localRegisterInfoHandler.del();
      doLoginSucceed(token);
      window.location.href = '/';
    } finally {
      setLoading(false);
    }
  }, [form, regInfo.accessCode, doLoginSucceed]);

  return { form, submit, loading, initalValue };
}
