/** 留资相关 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 留资相关 产品页留资 */
export async function productPost(payload: model.IApiProductLeaveMessageReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/biz/leaveMessage/product`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 留资相关 官网留资 */
export async function portalPost(payload: model.IApiPortalLeaveMessageReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/pub/leaveMessage/portal`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
