/* eslint-disable react-google-translate/no-conditional-text-nodes-with-siblings */
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { throttle } from 'lodash-es';
import { MediaIcon } from '@ui/components/media-icon';
import { useEffect, useMemo, useRef } from 'react';
import { TongyongGuanbiXian } from '@sirius/icons';
import { GlobalSearchCompanyDetail } from '@api/system-global/override/GlobalSearch';
import styles from './index.module.scss';
import CompanyAvatar from '@ui/components/company-avatar';
// TODO 单独从库里抽离出 Typography 组件
import { Typography } from '@douyinfe/semi-ui';
import { CountryFlag } from '@ui/components/country-flag';
import { CorrelationDot, CorrelationTag } from '../../visitor-list/correlation';
import { IApiVisitorScoreResp } from '@api/system-global/__generate__/default/models';

interface DataType {
  key: string;
  label: string;
  render?: (value: string, record?: GlobalSearchCompanyDetail) => React.ReactNode;
}

const { Paragraph } = Typography;

const FieldItem = ({ value, info }: { value: string; info: DataType }) => {
  const { label } = info || {};

  const renderField = useMemo(() => {
    return <div className={classNames(styles.textVal)}>{info.render ? info.render(value) : value}</div>;
  }, [info, value]);

  return (
    <div className={styles.textItem}>
      <div className={styles.textKey}>{label}:</div>
      {renderField}
    </div>
  );
};

interface Props {
  toggleSticky?: boolean;
  onClose?: () => void;
  data?: GlobalSearchCompanyDetail;
  scoreData?: IApiVisitorScoreResp;
}
export const socialMedias = [
  'linkedin',
  'google',
  'bing',
  'facebook',
  'whatsapp',
  'instagram',
  'twitter',
  'youtube',
  'tiktok',
  'email',
];
/**
 * 因为Paragraph的文本内容撑满宽度时，圆点会遮住文本内容，因此给Paragraph增加padding-right宽度为24;
 */
const safeDotWidth = 16 + 8;
/**
 * 为了保证圆点在文本内容右侧（间距8px），需要根据文本标签宽度来调整圆点的定位
 * @param cardRef
 */
const useCorrelationStyle = (cardRef: React.RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const fn = throttle(
      () => {
        if (cardRef.current) {
          const p = cardRef.current.querySelector('.semi-typography');
          const span = cardRef.current.querySelector('.semi-typography>span');
          const dot = cardRef.current.querySelector(`.${styles['correlation-dot']}`) as HTMLDivElement | null;
          if (p && span && dot) {
            const pRect = p.getBoundingClientRect();
            const spanRect = span.getBoundingClientRect();
            // 16是宽度，8是左侧间距
            const right = pRect.width - spanRect.width - safeDotWidth;
            const newValue = `${right}px`;
            // 不一样时才更新
            if (newValue !== dot.style.right) {
              dot.style.right = newValue;
            }
          }
        }
      },
      100,
      {
        leading: true,
        trailing: true,
      }
    );
    const observer = new MutationObserver(fn);
    if (cardRef.current) {
      observer.observe(cardRef.current, {
        childList: true,
        attributes: true,
        subtree: true,
        characterData: true,
      });
    }
    return () => {
      return observer.disconnect();
    };
  }, [cardRef]);
};

const DetailHeader = (props: Props) => {
  const { toggleSticky, onClose, data, scoreData } = props;
  const intl = useIntl();
  const cardRef = useRef<HTMLDivElement>(null);
  useCorrelationStyle(cardRef);
  const filedInfo: DataType[] = useMemo(() => {
    return [
      {
        label: intl.formatMessage({ id: '国家' }),
        key: 'country',
        render: value => {
          return (
            <div className={styles.countryText}>
              <CountryFlag code={value || ''} size={14} />
              <span>{value}</span>
            </div>
          );
        },
      },
      {
        label: intl.formatMessage({ id: '公司官网' }),
        key: 'domain',
        render: value => {
          return (
            <a href={value} target="_blank" className={styles.special} rel="noreferrer">
              {value}
            </a>
          );
        },
      },
      {
        label: intl.formatMessage({ id: '公司地址' }),
        key: 'location',
        render: value => {
          return (
            <a
              href={`https://maps.google.com?q=${encodeURIComponent(value)}&mrt=loc`}
              target="_blank"
              className={styles.special}
              rel="noreferrer"
            >
              {value}
            </a>
          );
        },
      },
      {
        label: intl.formatMessage({ id: '所属行业' }),
        key: 'industries',
      },
      {
        label: intl.formatMessage({ id: '公司电话' }),
        key: 'phone',
      },
      {
        label: intl.formatMessage({ id: '成立年份' }),
        key: 'foundedDate',
      },
      {
        label: intl.formatMessage({ id: '公司收入' }),
        key: 'revenue',
      },
      {
        label: intl.formatMessage({ id: '公司员工' }),
        key: 'staff',
      },
    ];
  }, [intl]);

  return (
    <div className={styles.companyInfo}>
      <div className={styles.header}>
        <TongyongGuanbiXian onClick={onClose} style={{ fontSize: 20, color: '#6F7485' }} />
      </div>
      <div
        hidden={!toggleSticky}
        className={classNames({
          [styles.sticky]: toggleSticky,
          [styles.stickyHeader]: toggleSticky,
        })}
      >
        <CompanyAvatar
          name={data?.name}
          mode="dark"
          size={30}
          textStyle={{ lineHeight: '33px', fontSize: 31, marginTop: 7, marginLeft: 7 }}
        />
        <span className={styles.stickyTitle}>{data?.name || '--'}</span>
        <TongyongGuanbiXian onClick={onClose} style={{ fontSize: 20, color: '#6F7485' }} />
      </div>
      <div className={styles.card} ref={cardRef}>
        <CompanyAvatar name={data?.name} mode="dark" className={styles.avatar} />
        <Paragraph style={{ width: 588 + safeDotWidth }} ellipsis={{ rows: 2 }} className={styles.title}>
          {data?.name || '--'}

          {scoreData && scoreData.scoreLevel !== 'EMPTY' ? (
            <CorrelationDot
              className={styles['correlation-dot']}
              scoreLevel={scoreData.scoreLevel}
              desc={scoreData.recommendReason}
              size="large"
            />
          ) : null}
        </Paragraph>

        <Paragraph
          className={styles.profile}
          ellipsis={{
            rows: 7,
            expandable: true,
            collapsible: true,
            collapseText: 'Show less',
            expandText: 'Show more',
            onExpand: (bool, e) => console.log(bool, e),
          }}
        >
          {data?.overviewDescription || ''}
        </Paragraph>
        {/* 
        <Paragraph
          className={styles.profile}
          ellipsis={{
            rows: 8,
            expandable: 'collapsible',
            expanded,
            // defaultExpanded: false,
            symbol: expanded ? (
              <div className={styles.foldText}>
                Fold
                <TongyongJiantou1Shang style={{ fontSize: 16, color: '#8A33AD' }} />
              </div>
            ) : (
              <div className={styles.foldText}>
                Unfold
                <TongyongJiantou1Xia style={{ fontSize: 16, color: '#8A33AD' }} />
              </div>
            ),
            onExpand: (_, info) => setExpanded(info.expanded),
            onEllipsis: ellipsis => {
              console.log('onEllipsis', ellipsis);
            },
          }}
        >
          {/* {
            'Founded in 2001, YLighting is the leading retailer dedicated to the best in modern and contemporary lighting, with a complementary selection of furniture and decor. We offer design-driven consumers and trade professionals a premier selection from over 300 top'.repeat(
              20,
            )
          } */}
        {/* {
            'Founded in 2001, YLighting is the leading retailer dedicated to the best in modern and contemporary lighting, with a complementary selection of furniture and decor. We offer design-driven consumers and trade professionals a premier selection from over 300 top'.repeat(
              4,
            )
          } */}
        {/* {data?.overviewDescription || ''}
        </Paragraph>  */}
        <div className={styles.brief}>
          {filedInfo.map(f => {
            const val = (data as any)?.[f.key] || '';
            if (!val) return null;
            return <FieldItem info={f} value={val} />;
          })}
        </div>
        {data && socialMedias.filter(i => (data as any)[i]).length ? (
          <span className={styles.socialMedias}>
            {socialMedias
              .filter(i => (data as any)[i])
              .map(i => (
                <MediaIcon
                  key={i}
                  type={i.toLocaleUpperCase()}
                  size={20}
                  onClick={() => {
                    window.open((data as any)[i], '_blank');
                  }}
                />
              ))}
          </span>
        ) : null}
      </div>
    </div>
  );
};
export default DetailHeader;
