import React, { MouseEvent, useMemo } from 'react';
import styles from './styles.module.scss';
import { Checkbox, ConfigProvider } from 'antd';
import type { CheckboxProps } from 'antd';
import { FormattedMessage } from 'react-intl';

interface ICompProps {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  scene: 'register' | 'login';
  style?: React.CSSProperties;
}

const UserAgreementChecker: React.FC<ICompProps> = ({ checked, setChecked, scene, style }) => {
  const onChange: CheckboxProps['onChange'] = e => {
    setChecked(e.target.checked);
  };

  const onLinkClick = (e: MouseEvent, type: 'customer' | 'platform') => {
    const url = type === 'customer' ? '/customer-policy' : '/platform-policy';
    window.open(`${import.meta.env.VITE_WEBSITE_URL}${url}`, '_blank');
    e.preventDefault();
  };

  const CustomerPrivacy = useMemo(
    () => (
      <span className={styles.link} onClick={e => onLinkClick(e, 'customer')}>
        <FormattedMessage id="用户隐私协议" />
      </span>
    ),
    []
  );

  const PlatformPrivacy = useMemo(
    () => (
      <span className={styles.link} onClick={e => onLinkClick(e, 'platform')}>
        <FormattedMessage id="平台隐私协议" />
      </span>
    ),
    []
  );

  const ContentEle = useMemo(() => {
    if (scene === 'register') {
      return (
        <p className={styles.userAgreement} style={style}>
          <span>
            <FormattedMessage id="注册时同意用户协议" />
          </span>
          {CustomerPrivacy}, {PlatformPrivacy}
        </p>
      );
    }
    return (
      <p className={styles.userAgreement} style={style}>
        <span>
          <FormattedMessage id="登录时同意用户协议" />
        </span>
        {CustomerPrivacy}
      </p>
    );
  }, [CustomerPrivacy, PlatformPrivacy, scene, style]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: '#C0C8D6',
          colorPrimary: '#8A33AD',
          colorPrimaryHover: '#8A33AD',
        },
      }}
    >
      <Checkbox checked={checked} onChange={onChange}>
        {ContentEle}
      </Checkbox>
    </ConfigProvider>
  );
};

export default UserAgreementChecker;
