import React from 'react';
import styles from './styles.module.scss';
import LoginCoverBg from '@system-global/assets/images/login-cover.png';
import LoginCover from '@ui/components/login-cover';
import LogoImage from '@system-global/assets/images/logo.png';

interface ICompProps {
  children: React.ReactElement;
}

const LoginContainer: React.FC<ICompProps> = ({ children }) => {
  return (
    <div className={styles.loginContainer}>
      <div className={styles.logo} style={{ backgroundImage: `url(${LogoImage})` }} />
      <div className={styles.left}>{children}</div>
      <LoginCover
        coverImage={LoginCoverBg}
        containerStyle={{ flexBasis: 853 }}
        contentStyle={{ width: '82.6%' }}
        bgStyle={{ width: '81.8%', marginBottom: 16 }}
      />
    </div>
  );
};

export default LoginContainer;
