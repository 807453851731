import React, { useMemo } from 'react';
import { Button, Flex } from 'antd';
import cn from 'classnames';
import { TongyongCuowutishiXian } from '@sirius/icons';
import { FormattedMessage } from 'react-intl';
import { IApiReachLimitStatisticResp } from '@api/system-global/__generate__/default/models';

import { UpgradeTooltip, sendMailHref } from './upgrade-tooltip';
import { UpgradeReminderStatus } from './upgrade-mini-tip';

import styles from './index.module.scss';
import dayjs from 'dayjs';

export * from './upgrade-tooltip';
export * from './upgrade-mini-tip';
export interface UpgradeReminderCardProps {
  startTime?: string;
  endTime?: string;
  status?: UpgradeReminderStatus;
  paidBefore: boolean;
  companyInfo: IApiReachLimitStatisticResp | undefined;
  className?: string;
  /**
   * 跳转到购买页
   * @returns
   */
  jumpToPurchasePage?: () => void;
}

const formatStartTime = (time?: number | string) => {
  if (!time) {
    return '';
  }

  //return  6/26/2024 15:30
  return dayjs(time).format('M/D/YYYY HH:mm');
};
const formatEndTime = (time?: number | string) => {
  if (!time) {
    return '';
  }
  const endTime = dayjs(time);
  if (endTime.isSame(dayjs(), 'day')) {
    return (
      <span className={styles['end-day']}>
        <FormattedMessage id="今天" /> {dayjs(time).format('HH:mm')}
      </span>
    );
  }
  if (endTime.isSame(dayjs().subtract(1, 'day'), 'day')) {
    return (
      <span className={styles['end-day']}>
        <FormattedMessage id="昨天" /> {dayjs(time).format('HH:mm')}
      </span>
    );
  }
  return dayjs(time).format('M/D/YYYY HH:mm');
};

export const UpgradeReminderCard: React.FC<UpgradeReminderCardProps> = props => {
  const { status, className, companyInfo, paidBefore, jumpToPurchasePage } = props;
  const { from, to, companyNames, companyNum, companyCountries } = companyInfo || {};

  const tipInfo = useMemo(() => {
    return {
      companyA: companyNames?.[0] ?? '-',
      companyB: companyNames?.[1] ?? '-',
      companyCount: companyNum,
      countryA: companyCountries?.[0] ?? '-',
      countryB: companyCountries?.[1] ?? '-',
    };
  }, [companyNames, companyNum, companyCountries]);
  return (
    <div className={cn(styles['container'], className)}>
      <div className={styles['reminder-title']}>
        <div>
          <TongyongCuowutishiXian />
          {status === 'expired' ? <FormattedMessage id="免费体验期已结束" /> : <FormattedMessage id="服务已到期" />}
        </div>
        <div>
          <FormattedMessage
            id="高级套餐可访问#个公司"
            values={{
              // 第一期先写死
              range: (
                <span className={status === 'usedUp' ? styles['highlight'] : styles['range-text']}>
                  &nbsp;{!paidBefore ? `0 - 400` : '400 - 1000'}&nbsp;
                </span>
              ),
            }}
          />
        </div>
      </div>

      {/* 如果公司数量大于等于2 则展示黄色视图 */}
      {companyNames && companyNames.length >= 2 ? (
        <Flex align="center" gap={28} justify="space-between" className={styles['usage-wrapper']}>
          <div>
            <div>
              <FormattedMessage
                id="从#服务到#"
                values={{
                  serveStartTime: formatStartTime(from),
                  serveEndTime: formatEndTime(to),
                }}
              />
            </div>
            <div>
              {companyCountries && companyCountries.length >= 2 ? (
                <FormattedMessage id="包含#和#公司，其中#个公司来自#国家#国家" values={tipInfo} />
              ) : null}
              {companyCountries && companyCountries.length === 1 ? (
                <FormattedMessage id="包含#和#公司，其中#个公司来自#国家" values={tipInfo} />
              ) : null}
              {companyCountries && companyCountries.length === 0 ? (
                <FormattedMessage id="包含#和#公司，公司数为#" values={tipInfo} />
              ) : null}
            </div>
          </div>
          <UpgradeTooltip>
            <Button type="primary" href={jumpToPurchasePage ? undefined : sendMailHref} onClick={jumpToPurchasePage}>
              <FormattedMessage id="增加配额" />
            </Button>
          </UpgradeTooltip>
        </Flex>
      ) : null}
    </div>
  );
};
