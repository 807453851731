import React from 'react';
import styles from './styles.module.scss';
import { TongyongChenggongMian } from '@sirius/icons';
import classnames from 'classnames';

interface ICompProps {
  checked: boolean;
  label: string;
  style?: React.CSSProperties;
}

const PasswordFormItemChecker: React.FC<ICompProps> = ({ checked, label, style }) => {
  return (
    <div className={styles.pwdChecker} style={style}>
      <TongyongChenggongMian className={classnames([[styles.icon], checked ? styles.checked : styles.unChecked])} />
      <span>{label}</span>
    </div>
  );
};

export default PasswordFormItemChecker;
