import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import LoginContainer from '@system-global/components/login-and-register-form/login-container';
import InputResetPwdEmailForm from './components/input-reset-pwd-email-form';
import SetPwdForm from './components/set-pwd-form';
import { useSearchParams } from 'react-router-dom';

type IStep = 'inputEmail' | 'setPwd';

const ResetPasswordPage = () => {
  const [cs, setCs] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const [step, setStep] = useState<IStep>('inputEmail');

  const onFail = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('cs');
    setSearchParams(newSearchParams);
    setCs('');
  };

  useEffect(() => {
    const reg = /cs=([^&#]*)/;
    const match = window.location.search.match(reg);
    setCs(match ? decodeURIComponent(match[1]) : '');
  }, []);

  useEffect(() => {
    setStep(cs ? 'setPwd' : 'inputEmail');
  }, [cs]);

  return (
    <div className={styles.resetPwdPage}>
      <LoginContainer>
        <div className={styles.content}>
          {step === 'inputEmail' && <InputResetPwdEmailForm />}
          {cs && step === 'setPwd' && <SetPwdForm cs={cs} onFail={onFail} />}
        </div>
      </LoginContainer>
    </div>
  );
};

export default ResetPasswordPage;
