import { FormattedMessage } from 'react-intl';
import { IMenuItem } from './types';
import { ROUTE_URLS } from './route-urls';

export const contentMenus: IMenuItem[] = [
  {
    label: <FormattedMessage id="基本" />,
    key: ROUTE_URLS.basic,
    children: [
      {
        label: <FormattedMessage id="全部公司" />,
        key: ROUTE_URLS.allVisitors,
      },
      {
        label: <FormattedMessage id="新公司" />,
        key: ROUTE_URLS.newVisitors,
      },
    ],
  },
  {
    label: <FormattedMessage id="访问历史" />,
    key: ROUTE_URLS.history,
    children: [
      {
        label: <FormattedMessage id="联系人" />,
        key: ROUTE_URLS.visitorHistory,
      },
    ],
  },
];

export const settingMenus: IMenuItem[] = [
  {
    label: <FormattedMessage id="设置" />,
    key: ROUTE_URLS.settings, // personal
    children: [
      {
        label: <FormattedMessage id="个人信息" />,
        key: ROUTE_URLS.personalSetting,
      },
      {
        label: <FormattedMessage id="邮件通知" />,
        key: ROUTE_URLS.emailNotificatons,
      },
    ],
  },
  {
    label: <FormattedMessage id="公司设置" />,
    key: ROUTE_URLS.company,
    children: [
      {
        label: <FormattedMessage id="SDK信息菜单" />,
        key: ROUTE_URLS.companyTracker,
      },
    ],
  },
  {
    label: <FormattedMessage id="支付" />,
    key: ROUTE_URLS.billing,
    children: [
      {
        label: <FormattedMessage id="订阅" />,
        key: ROUTE_URLS.subscriptions,
      },
    ],
  },
];
