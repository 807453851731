import React, { Suspense, useMemo } from 'react';
import { App, ConfigProvider, theme } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import { IntlProvider } from 'react-intl';
import { AppErrorBoundary } from '@ui/components/error-boundary/app-error-boundry';
import { LANG_MAP, LANGUAGES } from '@system-global/lang';
import { useStore } from '@system-global/store';
import { projectThemeConfig } from '@ui/styles/themes';
import { appThemeConfig } from '@system-global/styles/themes';
import { RouterProvider } from 'react-router-dom';
import { router } from '@system-global/config/routes';

export function AppPage() {
  const { token } = theme.useToken();

  const lang = useStore(state => state.global_lang);

  const langInfo = useMemo(
    () => ({
      messages: LANG_MAP[lang],
      locale: lang === LANGUAGES.english ? enUS : zhCN,
    }),
    [lang]
  );

  return (
    <AppErrorBoundary>
      <ConfigProvider
        locale={langInfo.locale}
        theme={{
          token: {
            ...token,
            ...projectThemeConfig.themeToken,
            ...appThemeConfig.themeToken,
          },
          components: {
            Button: {
              ...token.Button,
              ...projectThemeConfig.buttonTheme,
            },
            Tabs: {
              ...token.Tabs,
              ...projectThemeConfig.tabTheme,
            },
            Layout: {
              ...token.Layout,
              ...projectThemeConfig.layoutTheme,
            },
            Form: {
              ...token.Form,
              ...projectThemeConfig.formTheme,
            },
            Input: {
              ...token.Input,
              ...projectThemeConfig.inputTheme,
            },
            Modal: {
              ...token.Modal,
              ...projectThemeConfig.modalTheme,
            },
          },
        }}
      >
        <IntlProvider messages={langInfo.messages} locale={lang} defaultLocale={lang}>
          <App style={{ height: '100%' }}>
            <Suspense>
              <RouterProvider router={router} />
            </Suspense>
          </App>
        </IntlProvider>
      </ConfigProvider>
    </AppErrorBoundary>
  );
}

export default AppPage;
