import React, { useState } from 'react';
import { pageNewPost } from '@api/system-global/__generate__/default/VisitorPageService';
import styles from './index.module.scss';
import { VisitorList } from '@feature/components/visitor-list';
import { IApiCompanyPageReq } from '@api/system-global/__generate__/default/models';
import { FormattedMessage } from 'react-intl';
import { useStore } from '@system-global/store';
import { CompanyDetailTabsType } from '@api/system-global/override/GlobalSearch';
import CompanyDetail from '@system-global/components/company-detail';
import { UpgradeCard } from '@system-global/components/upgrade-card';

const getNewVisitorList = (params: IApiCompanyPageReq) => {
  return pageNewPost({
    pageNo: 1,
    pageSize: 10,
    ...params,
  }).then(res => res.data.data);
};
const NewVisitorList = () => {
  const [curDomain, setCurDomain] = useState('');

  const [openDefaultActiveTable, setOpenDefaultActiveTable] = useState<CompanyDetailTabsType>(
    CompanyDetailTabsType.OVERVIEW
  );
  const siteId = useStore(state => state.user_currentBindSite?.siteId);
  const userInfo = useStore(state => state.user_userInfo);
  return (
    <div className={styles.container}>
      <UpgradeCard className={styles['mb28']} />
      <VisitorList
        initialEmail={userInfo?.email}
        isNewVisitorList={true}
        marginTop={0}
        title={<FormattedMessage id="新公司" />}
        siteId={siteId || ''}
        initialSearchParamsFlag="lastWeek"
        getVisitorList={getNewVisitorList}
        onCompanyClick={(data, activeTab) => {
          setCurDomain(data?.domain || '');
          setOpenDefaultActiveTable(activeTab ?? CompanyDetailTabsType.OVERVIEW);
        }}
      />
      <CompanyDetail domain={curDomain} onClose={() => setCurDomain('')} defaultActiveTab={openDefaultActiveTable} />
    </div>
  );
};

export default NewVisitorList;
