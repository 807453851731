import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, Form, Modal } from 'antd';
import { TongyongGuanbiXian } from '@sirius/icons';
import PasswordFormItem from '@system-global/components/login-and-register-form/pwd-form-item-package/password-form-item';
import PasswordRepeatFormItem from '@system-global/components/login-and-register-form/pwd-form-item-package/password-repeat-form-item';
import PasswordFormItemCheckerGroup from '@system-global/components/login-and-register-form/pwd-form-item-package/password-form-item-checker-group';
import RequiredFormItem from '@system-global/components/login-and-register-form/pwd-form-item-package/required-form-item';
import { changePwdPost } from '@api/system-global/__generate__/default/UserBizService';
import { useStore } from '@system-global/store';

interface ICompProps {
  visible: boolean;
  setVisible(visible: boolean): void;
}

interface FieldType {
  oldPwd: string;
  pwd: string;
  repeatPwd: string;
}

const ResetPwdModal: React.FC<ICompProps> = ({ visible, setVisible }) => {
  const intl = useIntl();
  const { message } = App.useApp();
  const doLogout = useStore(state => state.global_doLogout);

  const [form] = Form.useForm<FieldType>();
  const passwordInForm = Form.useWatch('pwd', form);

  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    form.validateFields().then(() => {
      setLoading(true);
      const { pwd, oldPwd } = form.getFieldsValue();
      changePwdPost({ oldPassword: oldPwd, newPassword: pwd })
        .then(v => {
          if (v.data.code === 0) {
            message.success({
              content: intl.formatMessage({ id: '密码修改完成请重新登录' }),
              duration: 1,
              onClose() {
                setVisible(false);
                doLogout(true);
              },
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const TitleEle = (
    <div className={styles.modalHeader}>
      <p className={styles.modalTitle}>
        <FormattedMessage id="重置密码" />
      </p>
      <TongyongGuanbiXian className={styles.closeIcon} onClick={() => setVisible(false)} />
    </div>
  );

  const FooterEle = (
    <div className={styles.modalFooter}>
      <Button type="primary" size="large" onClick={onSubmit} loading={loading}>
        <FormattedMessage id="重置密码" />
      </Button>
      <Button size="large" onClick={() => setVisible(false)}>
        <FormattedMessage id="取消" />
      </Button>
    </div>
  );

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setLoading(false);
    }
  }, [form, visible]);

  return (
    <Modal
      width={460}
      title={TitleEle}
      footer={FooterEle}
      open={visible}
      closeIcon={null}
      className={styles.resetPwdModal}
      centered
    >
      <Form name="form" form={form} layout="vertical" className={styles.resetPwdForm}>
        <RequiredFormItem
          name="oldPwd"
          type="pwd"
          field={intl.formatMessage({ id: '当前密码' })}
          onPressEnter={onSubmit}
        />
        <PasswordFormItem label={intl.formatMessage({ id: '新密码' })} onPressEnter={onSubmit} />
        <PasswordRepeatFormItem lastPwd={passwordInForm} onPressEnter={onSubmit} />
      </Form>
      <PasswordFormItemCheckerGroup value={passwordInForm} style={{ marginTop: 32 }} />
    </Modal>
  );
};

export default ResetPwdModal;
