import React, { useMemo } from 'react';
import { Form, SelectProps, Button, message, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useMemoizedFn } from 'ahooks';
import { validateEmail } from '@utils/common';
import { Modal } from '@ui/components/modal';
import { TupleSelectTest, Select } from '@ui/components/select';
import { Input } from '@ui/components/input';
import { exportPost } from '@api/system-global/__generate__/default/VisitorPageService';
import { useNextRest } from '../../hooks/use-next-reset';

import styles from './index.module.scss';
import { TongyongShangchuan } from '@sirius/icons';

export interface DataExportModalProps {
  visible: boolean;
  /**
   * 默认接受email的地址
   */
  initialEmail: string | undefined;
  /**
   * resolve 成功的提示在组件内部实现了。
   * @param formValues
   * @returns
   */
  onOk: (formValues: FormValues) => Promise<void>;
  onCancel: () => void;
}

interface FormValues {
  format: 'CSV' | 'XLSX';
  /**接受的email */
  receiverEmail: string;
}

const formatOptions: SelectProps['options'] = [
  {
    label: 'CSV',
    value: 'CSV',
  },
  {
    label: 'Excel',
    value: 'XLSX',
  },
];

export const DataExportModal: React.FC<DataExportModalProps> = props => {
  const { visible, onCancel, onOk, initialEmail } = props;
  const [form] = Form.useForm<FormValues>();
  const [saving, setSaving] = React.useState(false);

  const saveFormValues = useMemoizedFn(async () => {
    const values = await form.validateFields().catch(err => {
      console.error(`error: ${err}`);
    });
    if (values) {
      setSaving(true);
      await onOk(values).catch(err => {
        console.error('onOk handle error:', err);
      });
    }
    setSaving(false);
  });

  return (
    <Modal
      open={visible}
      onCancel={() => {
        onCancel();
      }}
      centered
      title={<FormattedMessage id="数据导出-标题" />}
      okText={<FormattedMessage id="数据导出-确定" />}
      cancelText={<FormattedMessage id="数据导出-取消" />}
      okButtonProps={{
        loading: saving,
      }}
      onOk={() => {
        saveFormValues();
      }}
    >
      <div className={styles['body']}>
        <Form<FormValues>
          form={form}
          className={styles['form']}
          layout="vertical"
          initialValues={
            {
              format: 'CSV',
              receiverEmail: initialEmail,
            } as FormValues
          }
        >
          <div className={styles['desc']}>
            <FormattedMessage id="数据导出-介绍" />
          </div>
          <Form.Item label={<FormattedMessage id="数据导出-标题-格式" />} name="format">
            <Select options={formatOptions} size="large" />
          </Form.Item>
          {/* <TupleSelectTest /> */}
          <Form.Item
            label={<FormattedMessage id="数据导出-标题-邮箱" />}
            name="receiverEmail"
            rules={[
              {
                validator(rule, value, callback) {
                  const bool = validateEmail(value);
                  if (bool) {
                    callback();
                  } else {
                    callback((<FormattedMessage id="请输入正确的邮箱" />) as unknown as string);
                  }
                },
              },
            ]}
          >
            <Input size="large" variant="filled" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

interface ExportBtnProps {
  tooltipTitle: string | JSX.Element;
  disabled?: boolean;
  left?: number;
  right?: number;
  onClick?: () => void;
}
export const ExportBtn = (props: ExportBtnProps) => {
  const { left, right, tooltipTitle, disabled, onClick } = props;
  const css = useMemo<React.CSSProperties>(() => {
    return {
      marginLeft: `${left || 0}px`,
      marginRight: `${right || 0}px`,
    };
  }, [left, right]);
  return (
    <Tooltip title={tooltipTitle}>
      <Button
        color="default"
        disabled={disabled}
        className={styles['icon-button']}
        style={css}
        onClick={onClick}
        icon={<TongyongShangchuan />}
      ></Button>
    </Tooltip>
  );
};

export const ExportModal = (props: DataExportModalProps) => {
  const k = useNextRest(props.visible);
  return <DataExportModal key={k} {...props} />;
};
