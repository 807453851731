import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import SuccessIcon from '@system-global/assets/icons/pay_success.svg?react';
import styles from './styles.module.scss';

const PurchaseSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.success}>
      <div className={styles.successCard}>
        <div className={styles.icon}>
          <SuccessIcon />
        </div>
        <div className={styles.desc}>
          <FormattedMessage id="支付成功" />
        </div>

        <div className={styles.actions}>
          <Button
            className={styles.btn}
            type="primary"
            onClick={() => navigate(ROUTE_URLS.allVisitors, { replace: true })}
          >
            <FormattedMessage id="返回到系统" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseSuccess;
