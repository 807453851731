/** 登录接口（无鉴权） */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 登录接口（无鉴权） 邮箱密码登录 */
export async function emailPwdLoginPost(payload: model.IApiEmailLoginReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultLoginResp>({
    url: `/api/pub/user/email/pwd/login`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 登录接口（无鉴权） OAuth登录 */
export async function oAuthLoginPost(payload: model.IApiOAuthLoginReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultLoginResp>({
    url: `/api/pub/user/oauth/login`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
