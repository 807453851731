import { ReactNode, useCallback, useEffect, useMemo, useState, memo, useRef } from 'react';
import SysTabs from '@ui/components/tabs';
import { VisitState } from '@feature/components/visitor-detail/visit-state';
import { useIntl, FormattedMessage } from 'react-intl';
import { ContactList } from '@feature/components/contact-list';
import SimilarCompany from '@feature/components/similar-company';
import styles from './index.module.scss';
import classNames from 'classnames';
import { CompanyDetailTabsType, GlobalSearchType } from '@api/system-global/override/GlobalSearch';
import { IApiPageContactResp, IApiSimilarCompanyItemResp } from '@api/system-global/__generate__/default/models';
import { similarCompanyListPost } from '@api/system-global/__generate__/default/VisitorPageService';
import { getContactPagePost } from '@api/system-global/__generate__/default/GlobalSearchService';
import { ExpiredCard } from '../expired-card';

export type ComDetailTabsType = keyof typeof CompanyDetailTabsType;
const { TabPane } = SysTabs;
const DetailBaseInfo = ({
  domain = '',
  siteId = '',
  globalSearchType = 'RELATED_COMPANY',
  defaultActiveTab = 'OVERVIEW',
  ...rest
}: {
  domain: string;
  siteId: string;
  globalSearchType: keyof typeof GlobalSearchType;
  defaultActiveTab?: ComDetailTabsType;
}) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState<ComDetailTabsType>('OVERVIEW');
  const [contactCount, setContactCount] = useState(0);
  const [similarCompanyCount, setSimilarCompanyCount] = useState(0);
  const [showExpired, setShowExpired] = useState(false);
  const [similarData, setSimilarData] = useState<IApiSimilarCompanyItemResp[]>([]);
  const [contactData, setContacData] = useState<IApiPageContactResp>();
  const [pageParams, setPageParams] = useState<{ page: number; size: number }>({ page: 0, size: 10 });
  const [contactLoading, setContactLoading] = useState<boolean>(false);
  const showSimilar = useRef<boolean>(false);

  const fetchSimilarData = useCallback(async () => {
    if (!siteId || !domain) return;
    try {
      // 相似公司
      const res = await similarCompanyListPost({
        siteId,
        domain,
      });
      const { data } = res || {};
      setSimilarData(data?.data || []);
      setSimilarCompanyCount(data?.data?.length || 0);
    } catch (error) {
      console.log('error: ', error);
    }
  }, [domain, siteId]);

  const fetchContactData = useCallback(
    async ({ page, size }: { page: number; size: number }) => {
      try {
        if (!siteId || !domain) return;
        setContactLoading(true);
        setPageParams({ page, size });
        const res = await getContactPagePost({
          filterItem: [1],
          globalSearchType,
          page,
          size,
          source: 1,
          accountId: '-1',
          siteId,
          domain,
        });
        setShowExpired(res?.data?.data?.expiredAfterDays);
        setContacData(res?.data?.data?.contactRespPage || []);
        setContactCount(res?.data?.data?.contactRespPage?.totalPages || 0);
      } catch (error) {
        console.log('error: ', error);
      } finally {
        setContactLoading(false);
      }
    },
    [domain, globalSearchType, siteId]
  );

  useEffect(() => {
    fetchContactData({ page: 0, size: 10 });
    fetchSimilarData();
  }, [fetchContactData, fetchSimilarData]);

  const baseTablist = useMemo(() => {
    const infoList: Array<{
      key: keyof typeof CompanyDetailTabsType;
      value: string;
      component: ReactNode;
    }> = [];
    infoList.push({
      key: 'OVERVIEW',
      value: intl.formatMessage({ id: '访问概要' }),
      component: domain ? <VisitState domain={domain} siteId={siteId} /> : null,
    });
    if (contactCount) {
      infoList.push({
        key: 'CONTACT',
        value: `${intl.formatMessage({ id: '联系人' })}`,
        component: showExpired ? (
          <ExpiredCard />
        ) : (
          <ContactList
            pageParams={pageParams}
            fetchData={fetchContactData}
            contactData={contactData}
            globalSearchType="RELATED_COMPANY"
            siteId={siteId}
            contactLoading={contactLoading}
            domain={domain}
            {...rest}
          />
        ),
      });
    }
    if (similarCompanyCount) {
      showSimilar.current = true;
      infoList.push({
        key: 'SIMILARCOMPANY',
        value: `${intl.formatMessage({ id: '相似公司' })}`,
        component: <SimilarCompany siteId={siteId} similarData={similarData} {...rest} />,
      });
    }
    return infoList;
  }, [
    contactCount,
    contactData,
    contactLoading,
    domain,
    fetchContactData,
    intl,
    pageParams,
    rest,
    similarCompanyCount,
    similarData,
    siteId,
    showExpired,
  ]);

  useEffect(() => {
    setActiveTab(similarCompanyCount ? defaultActiveTab : 'OVERVIEW');
  }, [defaultActiveTab, similarCompanyCount]);

  if (globalSearchType === 'SIMILAR_COMPANY') {
    return (
      <div className={styles.similarBaseInfo}>
        <span className={styles.title}>
          <FormattedMessage id="联系人" />
        </span>
        {showExpired ? (
          <ExpiredCard />
        ) : (
          <ContactList
            pageParams={pageParams}
            fetchData={fetchContactData}
            contactData={contactData}
            globalSearchType={globalSearchType}
            contactLoading={contactLoading}
            siteId={siteId}
            domain={domain}
            {...rest}
          />
        )}
      </div>
    );
  }
  return (
    <div
      className={classNames(styles.baseInfo, {
        [styles.extra]: activeTab === 'SIMILARCOMPANY',
      })}
    >
      <TabComponent
        activeTab={activeTab}
        baseTablist={baseTablist}
        onTabClick={val => setActiveTab(val as CompanyDetailTabsType)}
      />
    </div>
  );
};

export default DetailBaseInfo;

interface TabComponentProps {
  onTabClick: (val: string) => void;
  activeTab: ComDetailTabsType;
  baseTablist: Array<{
    key: ComDetailTabsType;
    value: string;
    component: ReactNode;
  }>;
}
const TabComponent = memo((props: TabComponentProps) => {
  const { onTabClick, activeTab, baseTablist } = props;
  return (
    <SysTabs type="capsule" activeKey={activeTab} onTabClick={onTabClick}>
      {baseTablist.map(item => (
        <TabPane forceRender tab={item.value} key={item.key}>
          {item.component}
        </TabPane>
      ))}
    </SysTabs>
  );
});
