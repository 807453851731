import React, { ErrorInfo, ReactNode } from 'react';
import ErrorPage from '@ui/components/error-page';

export class AppErrorBoundary extends React.Component<
  { children?: ReactNode },
  { error: Error | null; errorInfo: ErrorInfo | null }
> {
  constructor(props: { children?: ReactNode }) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    console.error('App Error', error, errorInfo);
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return <ErrorPage />;
    }
    // Normally, just render children
    return this.props.children;
  }
}
