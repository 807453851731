const TEXT_MAP: Record<string, string> = {
  登录: '登录',
  首页: '首页',
  注销: '注销',
  中文: '中文',
  英文: '英文',
  刷新: '刷新',
  '对不起，您访问的页面不存在': '对不起，您访问的页面不存在',
  访问统计: '访问统计',
  访问记录: '访问记录',
  访客人数: '访客人数',
  访问时长: '访问时长',
  页面访问次数: '页面访问次数',
  访问地区: '访问地区',
  访问来源: '访问来源',
  进入时间: '进入时间',
  退出时间: '退出时间',
  页面地址: '页面地址',
  访问次数: '访问次数',
  地区: '地区',
  '访客A访问了#个页面': '访客A访问了{count}个页面',
  访问概要: '访问概要',
  联系人: '联系人',
  相似公司: '相似公司',
  '姓名/职位': '姓名/职位',
  联系方式: '联系方式',
  更新时间: '更新时间',
  操作: '操作',
  '总计#个公司访问了站点': '总计{totalCompanies}个公司访问了站点',
  平均每天访问公司数: '平均每天访问公司数',
  '总计#公司': '总计{total}公司',
  '高级套餐可访问#个公司': 'The higher-tier plan grants you monthly access to a range of {range} companies.',
  服务已到期: 'The maximum limit has been reached.',
  额度已用完: 'The maximum monthly limit of identified companies has been reached.',
  免费体验期已结束: 'Your free experience has expired',
  增加配额: 'Increase Quota',
  '从#服务到#': 'From {serveStartTime} To {serveEndTime}',
  '包含#和#公司，其中#个公司来自#国家#国家':
    'Including {companyA} and {companyB}, there are {companyCount} companies from the {countryA}, the {countryB} companies',
  '还可以免费试用#天': 'You have {days} days left in your trial.',
  体验期已结束: 'Experience  expired.',
  '#天之前': '{days} days ago',
  升级套餐: 'Upgrade',
  今天: 'today',
  昨天: 'yesterday',
  解锁失败: '解锁失败',
  国家: '国家',
  公司官网: '公司官网',
  公司地址: '公司地址',
  所属行业: '所属行业',
  公司电话: '公司电话',
  成立年份: '成立年份',
  公司收入: '公司收入',
  公司员工: '公司员工',
};

export default TEXT_MAP;
