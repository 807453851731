import { useState, useCallback, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message, Popover, Avatar } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Logo from '@system-global/assets/images/logo.svg';
import UserIcon from '@system-global/assets/icons/default-user.svg?react';
import { getPreVerifySitesGet } from '@api/system-global/__generate__/default/SiteBizService';
import { useStore } from '@system-global/store';
import BindSite from './bind';
import SelectType from './select';
import Verify from './verify';
import styles from './index.module.scss';

const InstallTrackerPage = () => {
  const [loading, setLoading] = useState(true);
  const bind_step = useStore(store => store.bind_step);
  const setStep = useStore(store => store.bind_setStep);
  const setSiteInfo = useStore(store => store.bind_setSiteInfo);
  const userInfo = useStore(store => store.user_userInfo);
  const intl = useIntl();

  const doLogout = useStore(state => state.global_doLogout);
  const onLogout = () => {
    doLogout().finally(() => {
      message.success(intl.formatMessage({ id: '登出成功' }));
    });
  };

  const fetchSite = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getPreVerifySitesGet({});
      if (res?.data?.data && res.data.data?.sites?.length > 0) {
        const siteInfo = res.data.data.sites[0];
        setSiteInfo(siteInfo);
        if (siteInfo?.verifyStatus === 'VERIFIED') {
          // 已验证到第三步
          setStep(3);
        } else {
          // 未验证到第二步
          setStep(2);
        }
      } else {
        // 未绑定到第一步
        setStep(1);
        setSiteInfo({
          name: userInfo?.companyName || '',
          website: userInfo?.website || '',
          siteId: '',
          verifyStatus: '',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [setSiteInfo, setStep, userInfo]);

  useEffect(() => {
    if (userInfo) {
      fetchSite();
    }
  }, [userInfo]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <a href="/">
          <img className={styles.logo} src={Logo} alt="" />
        </a>

        <Popover
          overlayClassName={styles.headerMenu}
          content={
            <div>
              <div className={styles.headerMenuItem} onClick={onLogout}>
                <FormattedMessage id="登出" />
              </div>
            </div>
          }
          // trigger="click"
          arrow={false}
          placement="bottomRight"
        >
          <Avatar style={{ background: 'transparent', float: 'right', cursor: 'pointer' }} icon={<UserIcon />} />
        </Popover>
      </div>
      {loading ? (
        <div className={styles.loading}>
          <Spin indicator={<LoadingOutlined spin color="" />} size="large" />
        </div>
      ) : (
        <>
          {bind_step === 1 && <BindSite></BindSite>}
          {bind_step === 2 && <SelectType onBack={() => setStep(1)}></SelectType>}
          {(bind_step === 3 || bind_step === 4) && <Verify onBack={() => setStep(2)}></Verify>}
        </>
      )}
    </div>
  );
};

export default InstallTrackerPage;
