import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { TongyongJiantou1Zuo } from '@sirius/icons';
import { useStore } from '@system-global/store';
import googleTagIcon from '@system-global/assets/images/googletag_log.svg';
import htmlIcon from '@system-global/assets/images/html_icon.svg';
import styles from './index.module.scss';

interface Props {
  onBack?: () => void;
}

const SelectType = (props: Props) => {
  const { onBack } = props;
  const siteInfo = useStore(store => store.bind_siteInfo);
  const setStep = useStore(store => store.bind_setStep);

  return (
    <div className={styles.selectType}>
      <div className={styles.action}>
        <div className={styles.goBack} onClick={onBack}>
          <TongyongJiantou1Zuo width={16} height={16} />
          <span className={styles.text}>
            <FormattedMessage id="绑定网站返回" />
          </span>
        </div>
      </div>
      <div className={styles.selectTitle}>
        <FormattedMessage id="安装SDK标题" />
      </div>
      <div className={styles.selectDesc}>
        <FormattedMessage id="安装SDK描述" />
      </div>
      <div className={styles.selectLabel}>
        <FormattedMessage id="安装SDK网站域名" />
      </div>
      <div className={styles.selectVal}>{siteInfo.website || '-'}</div>
      <div className={styles.selectLabel}>
        <FormattedMessage id="安装SDK方式" />
      </div>
      <div className={styles.selectVal}>
        <FormattedMessage id="安装SDK方式描述" />
      </div>
      <div className={styles.typeList}>
        <div className={styles.typeCard}>
          <div className={styles.typeIcon}>
            <img src={googleTagIcon} alt="Google Tag Manager" />
          </div>
          <div className={styles.typeDesc}>
            <div className={styles.typeDescTitle}>
              <FormattedMessage id="安装SDK方式1" />
            </div>
            <div className={styles.typeDescText}></div>
          </div>
          <div className={styles.btns}>
            <Button className={styles.nextBtn} type="primary" onClick={() => setStep(3)}>
              <FormattedMessage id="安装SDK方式下一步" />
            </Button>
          </div>
        </div>

        <div className={styles.typeCard}>
          <div className={styles.typeIcon}>
            <img src={htmlIcon} alt="HTML Code" />
          </div>
          <div className={styles.typeDesc}>
            <div className={styles.typeDescTitle}>
              <FormattedMessage id="安装SDK方式2" />
            </div>
            <div className={styles.typeDescText}></div>
          </div>
          <div className={styles.btns}>
            <Button className={styles.nextBtn} type="default" onClick={() => setStep(4)}>
              <FormattedMessage id="安装SDK方式下一步" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectType;
