import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, App } from 'antd';
import { sendVerifyEmailPost } from '@api/system-global/__generate__/default/UserCommonService';
import EmailFormItem from '@system-global/components/login-and-register-form/email-form-item';
import BackToLoginBtn from '@system-global/components/login-and-register-form/back-to-login-btn';

export interface IAccountFieldType {
  email: string;
}

const InputResetPwdEmailForm: React.FC = () => {
  const intl = useIntl();
  const { message } = App.useApp();
  const [form] = Form.useForm<IAccountFieldType>();

  const [emailSend, setEmailSend] = useState(false);

  const onSendEmail = () => {
    form.validateFields().then(() => {
      const { email } = form.getFieldsValue();
      setEmailSend(true);
      sendVerifyEmailPost({ verifyEmail: email, bizType: 'forgetPwd' })
        .then(v => {
          if (v.data.code === 0) {
            message.success(intl.formatMessage({ id: '重置邮件已发送' }));
            return;
          }
        })
        .catch(() => {
          setEmailSend(false);
        });
    });
  };

  const EmailLabelItemExtra = useMemo(
    () =>
      emailSend ? (
        <p className={styles.extra}>
          <FormattedMessage id="重置邮件已发送" />
        </p>
      ) : undefined,
    [emailSend]
  );

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        <FormattedMessage id="忘记密码吗" />
      </p>
      <p className={styles.subTitle}>
        <span>
          <FormattedMessage id="忘记密码会发邮件" />
        </span>
      </p>
      <Form name="form" form={form} layout="vertical" className={styles.loginForm}>
        <EmailFormItem scene="resetPwd" disabled={emailSend} onPressEnter={onSendEmail} showResend />
        {EmailLabelItemExtra}
      </Form>
      <Button type="primary" size="large" className={styles.submitBtn} onClick={onSendEmail} disabled={emailSend}>
        <FormattedMessage id="发送重置链接" />
      </Button>
      <BackToLoginBtn />
    </div>
  );
};

export default InputResetPwdEmailForm;
