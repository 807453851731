import { useMemo, useRef } from 'react';
let num = 1;
/**
 * 为了实现modal下次打开时，重置内部状态，使用useNextRest用来使modal或者modal嵌套的组件重新实例化
 * @param visible
 * @returns
 * @example
 * export const ExportModal = (props: DataExportModalProps) => {
 *      const k = useNextRest(props.visible);
 *      return <DataExportModal key={k} {...props} />;
 * };
 */
export const useNextRest = (visible: boolean): number => {
  const seqId = useRef(num);
  useMemo(() => {
    if (visible) {
      num++;
      seqId.current = num;
    }
  }, [visible]);

  return seqId.current;
};
