import React, { CSSProperties } from 'react';
import PasswordFormItemChecker from '../password-form-item-checker';
import { validPasswordContent, validPasswordLength } from '@utils/common';
import { useIntl } from 'react-intl';

interface ICompProps {
  value: string;
  style?: CSSProperties;
}

const PasswordFormItemCheckerGroup: React.FC<ICompProps> = ({ value, style }) => {
  const intl = useIntl();

  return (
    <div style={style}>
      <PasswordFormItemChecker
        checked={validPasswordLength(value)}
        label={intl.formatMessage({ id: '密码8-20个字符' })}
      />
      <PasswordFormItemChecker
        style={{ marginTop: 12 }}
        checked={validPasswordContent(value)}
        label={intl.formatMessage({ id: '密码至少包含一个字母和一个数字' })}
      />
    </div>
  );
};

export default PasswordFormItemCheckerGroup;
