import { Outlet, useMatches } from 'react-router-dom';
import { Layout } from 'antd';
import AppHeader from '@system-global/components/app-header';
import ContentRootSider from './components/content-root-sider';
import { IRouteConfig } from '@system-global/config/types';
import styles from './styles.module.scss';

export function ContentRoot() {
  const matches = useMatches();
  const hideAside = matches.some(v => (v.handle as IRouteConfig)?.hideAside);
  const hideHeader = matches.some(v => (v.handle as IRouteConfig)?.hideHeader);
  const customLayout = matches.some(v => (v.handle as IRouteConfig)?.customLayout);

  const defaultMainStyle = {
    padding: '20px 16px 0 16px',
    marginRight: 4,
  };

  const defaultContentStyle = {
    margin: '0 auto',
    width: '100%',
    maxWidth: 1660,
    paddingBottom: 20,
    flex: '1 0 auto',
  };

  return (
    <Layout style={{ height: '100%' }}>
      {!hideHeader ? <AppHeader /> : null}
      <Layout>
        {!hideAside ? <ContentRootSider /> : null}
        <Layout className={customLayout ? '' : styles.content} style={customLayout ? undefined : defaultMainStyle}>
          <Layout.Content style={customLayout ? undefined : defaultContentStyle}>
            <Outlet />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default ContentRoot;
