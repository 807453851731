import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { onValidateEmail } from '@system-global/utils/validator';
import { TongyongGuanbiXian } from '@sirius/icons';
import { RegisterStatus } from '@system-global/config/user';
import styles from './styles.module.scss';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { useNavigate } from 'react-router-dom';
import { useStore } from '@system-global/store';

interface IComProps {
  scene: 'register' | 'resetPwd' | 'login';
  disabled?: boolean;
  validateDebounce?: number;
  onPressEnter?: () => void;
  showResend?: boolean;
}

interface IFieldType {
  email: string;
}

const EmailFormItem: React.FC<IComProps> = ({ scene, disabled, validateDebounce, onPressEnter, showResend }) => {
  const intl = useIntl();
  const nav = useNavigate();

  const [loading, setLoading] = useState(false);
  const [emailInfo, setEmailInfo] = useState<{ email: string; status: RegisterStatus }>({
    email: '',
    status: RegisterStatus.unregister,
  });

  const doResendEmail = useStore(state => state.login_doResendEmail);

  const onResend = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    doResendEmail('signup', emailInfo.email)
      .then(() => {
        setTimeout(() => {
          nav(ROUTE_URLS.verifyEmail);
        }, 500);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const baseLabel = intl.formatMessage({ id: '邮箱' }).toUpperCase();
  const fullLabel =
    showResend && emailInfo.status === RegisterStatus.toVerify ? (
      <div className={styles.resendContainer}>
        <span>{intl.formatMessage({ id: '邮箱' }).toUpperCase()}</span>
        <span className={styles.link} style={loading ? { cursor: 'wait', color: '#747a8c' } : {}} onClick={onResend}>
          <FormattedMessage id={loading ? '正在发送邮件' : '重发验证邮件'} />
        </span>
      </div>
    ) : (
      baseLabel
    );

  return (
    <Form.Item<IFieldType>
      name="email"
      label={fullLabel}
      validateDebounce={validateDebounce !== undefined ? validateDebounce : 500}
      rules={[
        {
          validator(rule, value) {
            setEmailInfo({ email: '', status: RegisterStatus.unregister });
            return onValidateEmail(value, {
              scene,
            }).catch((e: { status: RegisterStatus; content: string }) => {
              setEmailInfo({ email: value, status: e.status });
              return Promise.reject(e.content);
            });
          },
        },
      ]}
    >
      <Input
        size="large"
        variant="filled"
        disabled={disabled}
        onPressEnter={onPressEnter}
        allowClear={{ clearIcon: <TongyongGuanbiXian style={{ fontSize: 16 }} /> }}
      />
    </Form.Item>
  );
};

export default EmailFormItem;
