import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

export const proxyConsole = (prefix: string) => {
  if (window && window.console) {
    ['log', 'warn', 'info', 'error'].forEach(v => {
      const key = v as 'log' | 'warn' | 'info' | 'error';
      if (console[key]) {
        const originMethod = console[key];
        console[key] = (...params: unknown[]) => originMethod.apply(console, [prefix, ...params]);
      }
    });
  }
};
/**
 * 数字转成千分位数字
 * @param num
 * @returns
 * @example
 * ```ts
 * formatNumber(1234567) => '1,234,567'
 * ```
 */
export const formatNumber = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const validateEmail = (email = ''): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// 要求8-20个字符
export const validPasswordLength = (password = ''): boolean => {
  const pattern = /^.{8,20}$/;
  return pattern.test(password);
};

// 要求至少包含一个字母和一个数字
export const validPasswordContent = (password = ''): boolean => {
  const pattern = /^(?=.*[A-Za-z])(?=.*\d).+$/;
  return pattern.test(password);
};

export const safeJsonParse = <T>(str: string): T | undefined => {
  try {
    return JSON.parse(str) as T;
  } catch (e) {
    return undefined;
  }
};

/**
 *  将秒数转换为时间格式  xxh xxm xxs
 * @param seconds
 * @returns
 */
export const secondsToTime = (seconds: number, defaultVal = '<1s'): string => {
  if (!seconds) {
    return defaultVal;
  }
  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds % 3600) / 60);
  const second = Math.floor(seconds % 60);

  const timeParts = [];
  if (hour > 0) timeParts.push(`${hour}h `);
  if (minute > 0) timeParts.push(`${minute}m `);
  timeParts.push(`${second}s`);

  return timeParts.join('');
};

/**
 * 根据最后访问时间，返回描述
 * @param lastVisitAt
 * @returns
 * 今天：today
 * 昨天：yesterday
 * n天之前：n days ago
 */
export const lastVisitTimeDesc = (lastVisitAt: string): string => {
  const visitDate = dayjs(Number(lastVisitAt));
  const intl = useIntl();
  if (visitDate.isSame(dayjs(), 'day')) {
    return intl.formatMessage({ id: '今天' });
  } else if (visitDate.isSame(dayjs().subtract(1, 'day'), 'day')) {
    return intl.formatMessage({ id: '昨天' });
  } else {
    // n天之前
    return intl.formatMessage({ id: '#天之前' }, { days: dayjs().diff(visitDate, 'day') });
  }
};
