/** 公司相关 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 公司相关 导出公司信息（异步） */
export async function exportPost(payload: model.IApiExportCompanyReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/biz/company/export`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 公司相关 全部公司列表分页 */
export async function pageAllPost(payload: model.IApiCompanyPageReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultPageRespCompanyPageItemResp>({
    url: `/api/biz/company/pageAll`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 公司相关 新公司列表分页 */
export async function pageNewPost(payload: model.IApiCompanyPageReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultPageRespCompanyPageItemResp>({
    url: `/api/biz/company/pageNew`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 公司相关 相似公司列表 */
export async function similarCompanyListPost(
  payload: model.IApiSimilarCompanyListReq,
  customParams?: IAxiosCustomParams
) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultListSimilarCompanyItemResp>({
    url: `/api/biz/company/similarCompanyList`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 公司相关 公司统计信息 */
export async function statisticsPost(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultCompanyStatResp>({
    url: `/api/biz/company/statistics`,
    method: 'post',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
