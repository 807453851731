import React, { CSSProperties } from 'react';
import { Outlet, useLocation, useMatches, useNavigate, useSearchParams } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { IRouteConfig } from '@system-global/config/types';
import styles from './styles.module.scss';
import { localRegisterInfoHandler } from '@system-global/utils/common';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { useMount } from 'ahooks';
import { LoadingOutlined } from '@ant-design/icons';

const REDIRECT_LOGIN_KEY = 'token';
const REDIRECT_RESET_PWD_KEY = 'cs';

export function LoginRoot() {
  const nav = useNavigate();
  const location = useLocation();
  const matches = useMatches();
  const customLayout = matches.some(v => (v.handle as IRouteConfig)?.customLayout);

  const contentStyle: CSSProperties = {
    height: '100%',
    minHeight: 800,
  };

  const [searchParams] = useSearchParams();
  const redirectLoginToken = searchParams.get(REDIRECT_LOGIN_KEY);
  const resetPwdToken = searchParams.get(REDIRECT_RESET_PWD_KEY);

  useMount(() => {
    const localData = localRegisterInfoHandler.get();
    if (resetPwdToken && location.pathname === ROUTE_URLS.resetPwd) {
      // 重设密码的用户，去继续重设密码
      nav(ROUTE_URLS.resetPwd + `?${REDIRECT_RESET_PWD_KEY}=${resetPwdToken}`);
    } else if (localData?.email) {
      // 待验证的用户，去验证页
      nav(ROUTE_URLS.verifyEmail);
    } else if (location.pathname !== ROUTE_URLS.register) {
      nav(ROUTE_URLS.login, {});
    }
  });

  return (
    <Layout style={customLayout ? undefined : contentStyle} className={styles.loginRoot}>
      {redirectLoginToken ? (
        <Spin
          spinning
          fullscreen
          wrapperClassName="auto"
          delay={300}
          indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
        />
      ) : null}
      <Outlet />
    </Layout>
  );
}

export default LoginRoot;
