import { Link } from 'react-router-dom';
import { Popover, Layout, Flex, App } from 'antd';
import classnames from 'classnames';
import { UpgradeMiniTip } from '@feature/components/visitor-list/upgrade-reminder';
import styles from './style.module.scss';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { useStore } from '@system-global/store';
import { FormattedMessage } from 'react-intl';
import LogoImage from '@system-global/assets/images/logo.png';
import DefaultAvatarImage from '@system-global/assets/images/default-avatar.png';
import Logout from '@system-global/assets/icons/logout.svg?react';
import { intl } from '@system-global/lang';
import classNames from 'classnames';
import { TongyongQueren, TongyongShezhi } from '@sirius/icons';
import { useMemo } from 'react';
import { QuotaType } from '@system-global/config/types';
import { Typography } from 'antd';
// import { TongyongTianjia } from '@sirius/icons';

interface Props {
  className?: string;
}
interface ProgressBarProps {
  percentage: number;
  /**
   * 默认为 blue
   */
  theme?: 'blue' | 'red';
}

const themeClassNames: Record<Required<ProgressBarProps>['theme'], string> = {
  blue: styles['blue'],
  red: styles['red'],
};

const ProgressBar = (props: ProgressBarProps) => {
  const { percentage, theme = 'blue' } = props;
  const themeClassName = themeClassNames[theme];

  const limitPercentage = useMemo(() => {
    return Math.max(0, Math.min(100, percentage));
  }, [percentage]);
  const stylesProps = useMemo(() => {
    return {
      width: `${limitPercentage}%`,
      display: limitPercentage === 0 ? 'none' : 'block',
    };
  }, [limitPercentage]);
  return (
    <div className={classNames(styles['percentage-bar'], themeClassName)}>
      <div style={stylesProps}></div>
    </div>
  );
};

const { Text } = Typography;
export function AppHeader(props: Props) {
  const { className = '' } = props;
  const { message } = App.useApp();
  const doLogout = useStore(state => state.global_doLogout);
  const userInfo = useStore(state => state.user_userInfo);
  const userData = useStore(state => state.user_currentBindSite);

  const onLogout = () => {
    doLogout().finally(() => {
      message.success(intl.formatMessage({ id: '登出成功' }));
    });
  };

  const UserCenter = (
    <div className={styles.userCenter}>
      <div className={classNames(styles.userCenterItems, styles.userWrap)}>
        <div className={styles.user}>
          <div className={styles.avatar} style={{ backgroundImage: `url(${DefaultAvatarImage})` }}></div>
          <div className={styles.name}>
            <div>
              {userInfo?.firstName}
              {userInfo?.lastName}
            </div>
            <span>{userInfo?.email}</span>
          </div>
        </div>
      </div>
      {!!userInfo?.quotaCountInfoList.length && (
        <div className={classNames(styles.userCenterItems, styles.quotas)}>
          {userInfo?.quotaCountInfoList?.map((item, idx) => (
            <div className={styles.quotasItem} key={idx}>
              <div className={styles.info}>
                <div>
                  {QuotaType.CONTACT_REVEAL_QUOTA === item.quotaName ? 'Monthly Contacts' : 'Monthly Companies'}
                </div>
                <span>
                  {item.used}/{item.total}
                </span>
              </div>
              <ProgressBar percentage={(item.used / item.total) * 100} />
            </div>
          ))}
        </div>
      )}
      {userData?.website && (
        <div className={classNames(styles.userCenterItems, styles.site)}>
          <div className={styles.siteTitle}>
            All Website
            {/* <TongyongTianjia style={{ fontSize: 16, color: '#6F7485' }} /> */}
          </div>
          <div className={classNames(styles.userCenterItem, styles.itemActive)}>
            <Text style={{ maxWidth: 220 }} ellipsis={{ tooltip: userData?.website }}>
              {userData?.website}
            </Text>
            <TongyongQueren style={{ fontSize: 16, color: '#1D201F', marginLeft: 8 }} />
          </div>
        </div>
      )}
      <div className={classNames(styles.userCenterItems, styles.special)}>
        <Link to={ROUTE_URLS.personalSetting} className={styles.userCenterItem}>
          <TongyongShezhi style={{ fontSize: 16, color: '#6F7485', marginRight: 8 }} />{' '}
          <FormattedMessage id="个人中心设置" />
        </Link>
        <div className={styles.userCenterItem} onClick={onLogout}>
          <Logout style={{ marginRight: 8 }} /> <FormattedMessage id="登出" />
        </div>
      </div>
    </div>
  );

  return (
    <Layout.Header className={classnames(className, styles.header)}>
      <Link to={ROUTE_URLS.root} className={styles.logo} style={{ backgroundImage: `url(${LogoImage})` }} />
      <Flex gap={16} align="center">
        {!userInfo?.userOrderStatusResp?.officialUser && (
          <UpgradeMiniTip trialDaysLeft={userInfo?.userOrderStatusResp.trialDaysLeft} />
        )}
        <Popover
          rootClassName={styles.openHeaderInner}
          content={UserCenter}
          trigger="click"
          arrow={false}
          placement="bottomRight"
        >
          <div
            className={classNames(styles.userSite, {
              [styles.hasSite]: userData?.website,
            })}
          >
            <div className={styles.avatar} style={{ backgroundImage: `url(${DefaultAvatarImage})` }}></div>
            <div className={styles.site}>{userData?.website}</div>
          </div>
        </Popover>
      </Flex>
    </Layout.Header>
  );
}

export default AppHeader;
