import { useState, useCallback } from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { productPost } from '@api/system-global/__generate__/default/LeaveMessageService';
import { ReserveModal } from '../reservemodal';
import styles from './custom.module.scss';

const CustomSolution = () => {
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const submit = useCallback(async () => {
    try {
      setLoading(true);
      await productPost({ bookAt: dayjs().valueOf(), bizSource: 'TRIAL_CUSTOM' });
      setShowConfirm(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.desc}>
        <FormattedMessage id="自定义订单说明" />
      </div>
      <div>
        <Button loading={loading} className={styles.submitBtn} type="link" onClick={submit}>
          <FormattedMessage id="联系销售人员按钮" />
        </Button>
      </div>

      <ReserveModal open={showConfirm} onCancel={() => setShowConfirm(false)} />
    </div>
  );
};

export default CustomSolution;
