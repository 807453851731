import { safeJsonParse } from '@utils/common';

export const localLoginTokenHandler = {
  key: 'leads_navi_token',
  get() {
    return window.localStorage.getItem(localLoginTokenHandler.key) || '';
  },
  set(token: string) {
    window.localStorage.setItem(localLoginTokenHandler.key, token);
  },
  del() {
    window.localStorage.removeItem(localLoginTokenHandler.key);
  },
};

export const localMenuOpenHandler = {
  key: 'leads_navi_menu_open',
  connector: '@@',
  get() {
    const localStr = window.localStorage.getItem(localMenuOpenHandler.key);
    if (localStr) {
      return safeJsonParse<string[]>(localStr);
    }
  },
  set(openKeysStr: string[]) {
    window.localStorage.setItem(localMenuOpenHandler.key, JSON.stringify(openKeysStr));
  },
  del() {
    window.localStorage.removeItem(localMenuOpenHandler.key);
  },
};

export interface IRegisterInfo {
  email: string;
  sendTime: number;
}

export const localRegisterInfoHandler = {
  key: 'leads_navi_registering_info',
  get() {
    const localStr = window.localStorage.getItem(localRegisterInfoHandler.key);
    if (localStr) {
      return safeJsonParse<IRegisterInfo>(localStr);
    }
    return undefined;
  },
  set(info: IRegisterInfo) {
    const targetInfo: IRegisterInfo = {
      email: info.email,
      sendTime: info.sendTime,
    };
    window.localStorage.setItem(localRegisterInfoHandler.key, JSON.stringify(targetInfo));
  },
  del() {
    window.localStorage.removeItem(localRegisterInfoHandler.key);
  },
};
