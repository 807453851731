import { useCallback, useMemo } from 'react';
import { IApiSiteResp } from '@api/system-global/__generate__/default/models';
import { useStore } from '@system-global/store';

export const useTrackerInfo = () => {
  const _siteList = useStore(store => store.user_allBindSites);
  const getTrackerScript = useCallback((siteId: string): string => {
    return `<script>(function(siteId){window.__track_sdk__=window.__track_sdk__||{temp:[],report:function(){this.temp.push([].slice.call(arguments))},};(function(doc,tagName){var ele=doc.getElementsByTagName(tagName)[0];function onLoad(){if(window.__track_sdk__){window.__track_sdk__.setDefaultConfig({siteId:siteId,})}}function insert(){var s=document.createElement('script');s.type='text/javascript';s.async=true;s.src='${process.env.TRACK_SDK_SRC}';s.onload=onLoad;ele.parentNode.insertBefore(s,ele)}insert()})(document,'script')})('${siteId}');</script>`;
  }, []);

  const siteList = useMemo<Array<IApiSiteResp & { installCode: string }>>(() => {
    return _siteList.map((site: IApiSiteResp) => {
      return {
        ...site,
        installCode: getTrackerScript(site.siteId),
      };
    });
  }, [_siteList, getTrackerScript]);

  return { getTrackerScript, siteList };
};
