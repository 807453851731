import React from 'react';
import PasswordFormItem from './password-form-item';
import PasswordFormItemCheckerGroup from './password-form-item-checker-group';
import PasswordRepeatFormItem from '@system-global/components/login-and-register-form/pwd-form-item-package/password-repeat-form-item';

interface ICompProps {
  value: string;
  label?: string;
  showRepeat?: boolean;
  showChecker?: boolean;
  onPressEnter?: () => void;
}

const PwdFormItemPackage: React.FC<ICompProps> = ({ value, label, showRepeat, showChecker = true, onPressEnter }) => {
  return (
    <>
      <PasswordFormItem label={label} onPressEnter={onPressEnter} />
      {showRepeat && <PasswordRepeatFormItem lastPwd={value} onPressEnter={onPressEnter} />}
      {showChecker && <PasswordFormItemCheckerGroup value={value} />}
    </>
  );
};

export default PwdFormItemPackage;
