import { CSSProperties } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import { Layout } from 'antd';
import { IRouteConfig } from '@system-global/config/types';

export function BlankRoot() {
  const matches = useMatches();
  const customLayout = matches.some(v => (v.handle as IRouteConfig)?.customLayout);

  const contentStyle: CSSProperties = {
    height: '100%',
    minHeight: 800,
  };

  return (
    <Layout style={customLayout ? undefined : contentStyle}>
      <Outlet />
    </Layout>
  );
}

export default BlankRoot;
