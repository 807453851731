import { useMemo } from 'react';
import { useSessionStorageState } from 'ahooks';
import { Space, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import { formatNumber } from '@utils/common';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { IApiProductModelResp } from '@api/system-global/__generate__/default/models';
import SuccessIcon from '@system-global/assets/icons/res_success.svg?react';
import BorderBg from '@system-global/assets/icons/border.svg?react';
import styles from './styles.module.scss';

type PurchaseState = Record<string, IApiProductModelResp & { productName: string }>;
const PurchaseResult = () => {
  const [purchaseState] = useSessionStorageState<PurchaseState>('LeadsNaviPurchaseState');
  const params = useParams();
  const navigate = useNavigate();

  const orderInfo = useMemo(() => {
    if (!params.orderId) {
      return null;
    }
    return purchaseState?.[params?.orderId] || null;
  }, [purchaseState, params.orderId]);

  const price = useMemo(() => {
    const amount = orderInfo?.currentPriceTotal?.amount || 0;
    return formatNumber(+(amount / 100).toFixed(2));
  }, [orderInfo]);

  const orderDuration = useMemo(() => {
    if (orderInfo?.effectiveUnit === 'YEAR') {
      return '1 year';
    }

    if (orderInfo?.effectiveUnit === 'MONTH') {
      return '1 month';
    }

    return '--';
  }, [orderInfo]);

  if (!orderInfo) {
    return (
      <div className={styles.result}>
        <div className={styles.expired}>
          <FormattedMessage id="订单已过期" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.result}>
      <div className={styles.resultCard}>
        <div className={styles.icon}>
          <SuccessIcon />
        </div>
        <div className={styles.title}>
          <FormattedMessage id="支付成功" />
        </div>
        <div className={styles.orderInfo}>
          <div className={styles.row}>
            <div className={styles.label}>
              <FormattedMessage id="支付价格" />
            </div>
            <div className={styles.value}>${price}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              <FormattedMessage id="支付类型" />
            </div>
            <div className={styles.value}>{orderInfo?.productName}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              <FormattedMessage id="服务有效期" />
            </div>
            <div className={styles.value}>{orderDuration}</div>
          </div>
          <BorderBg className={styles.borderBg} />
        </div>

        <div className={styles.actions}>
          <Space size={12}>
            <Button className={styles.btn} onClick={() => navigate(ROUTE_URLS.billing)}>
              <FormattedMessage id="查看订单" />
            </Button>
            <Button className={styles.btn} type="primary" onClick={() => navigate(ROUTE_URLS.allVisitors)}>
              <FormattedMessage id="去体验" />
            </Button>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default PurchaseResult;
