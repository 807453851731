import React, { useCallback } from 'react';
import { IApiVisitorDetail } from '@api/system-global/__generate__/default/models';
import { secondsToTime } from '@utils/common';
import link from '@feature/assets/icons/link.svg';
import time from '@feature/assets/icons/time_line.svg';
import styles from './style.module.scss';

export interface Props {
  data: IApiVisitorDetail[];
}

export const AccessList: React.FC<Props> = props => {
  const { data = [] } = props;

  const parseUrl = useCallback((url: string) => {
    if (/(http|https):\/\//.test(url)) {
      return url;
    }
    return `https://${url}`;
  }, []);

  return (
    <div className={styles.accessList}>
      {data.map((item, index) => (
        <div className={styles.accessItem} key={index}>
          <div className={styles.accessLink} title={item.url}>
            <img src={link} alt="" />
            <div>
              <a href={parseUrl(item.url)} target="_blank" rel="noreferrer">
                {item.url}
              </a>
            </div>
          </div>
          <div className={styles.accessDuration}>
            <img src={time} alt="" />
            {secondsToTime(item.duration)}
          </div>
        </div>
      ))}
    </div>
  );
};
