import styles from './style.module.scss';
import { useIntl } from 'react-intl';
import { Button, Result } from 'antd';

const NotFoundPage = () => {
  const intl = useIntl();

  return (
    <div className={styles.pageContainer}>
      <Result
        className={styles.content}
        status="404"
        title="404"
        subTitle={intl.formatMessage({ id: '对不起，您访问的页面不存在' })}
        extra={
          <Button className={styles.btn} type="primary" onClick={() => window.location.assign('/')}>
            {intl.formatMessage({ id: '刷新' })}
          </Button>
        }
      />
    </div>
  );
};

export default NotFoundPage;
