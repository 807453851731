import React, { useState } from 'react';
import styles from './styles.module.scss';
import { useCountDown, useMount } from 'ahooks';
import LogoImage from '@system-global/assets/images/logo.png';
import { TongyongChenggongMian } from '@sirius/icons';
import { FormattedMessage } from 'react-intl';
import bg from '@system-global/assets/images/register-success.png';
import BendArrowIcon from '@system-global/assets/icons/bend-arrow.svg?react';
import { Button } from 'antd';
import BackToLoginBtn from '@system-global/components/login-and-register-form/back-to-login-btn';
import { emailSignUpCheckPost } from '@api/system-global/__generate__/default/UserSignupService';
import { RegisterStatus } from '@system-global/config/user';
import { useNavigate } from 'react-router-dom';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { useStore } from '@system-global/store';
import { localRegisterInfoHandler } from '@system-global/utils/common';

const MAX_TIME = 5 * 60 * 1000;

const VerifyEmailPage = () => {
  const nav = useNavigate();

  const [resendLoading, setResendLoading] = useState(false);

  const registerInfo = useStore(state => state.login_registerInfo);
  const doResendEmail = useStore(state => state.login_doResendEmail);

  const [countdown, formattedRes] = useCountDown({
    leftTime: Math.min(MAX_TIME, MAX_TIME - (Date.now() - registerInfo.sendTime)),
  });
  const { minutes, seconds } = formattedRes;
  const resendDisable = countdown > 0;

  const onResend = () => {
    setResendLoading(true);
    doResendEmail('signup', registerInfo.email).finally(() => {
      setResendLoading(false);
    });
  };

  useMount(() => {
    emailSignUpCheckPost({ email: registerInfo.email }, { noErrorHint: true }).then(({ data }) => {
      const status = data.data.emailSignUpCheckStatus;
      if (status === RegisterStatus.unregister) {
        nav(ROUTE_URLS.register);
        localRegisterInfoHandler.del();
        return;
      }
      if (status === RegisterStatus.verified) {
        nav(ROUTE_URLS.login);
        localRegisterInfoHandler.del();
      }
    });
  });

  return (
    <div className={styles.verifyEmailPage}>
      <div className={styles.logo} style={{ backgroundImage: `url(${LogoImage})` }} />
      <div className={styles.content}>
        <div className={styles.title}>
          <TongyongChenggongMian />
          <FormattedMessage id="注册成功" />
        </div>
        <p className={styles.tip}>
          <span>
            <FormattedMessage id={resendDisable ? '注册成功欢迎语1' : '注册成功欢迎语2'} />
          </span>
          {resendDisable && (
            <span className={styles.time}>
              <FormattedMessage id="分钟倒计时" values={{ minutes, seconds: `${seconds < 10 ? '0' : ''}${seconds}` }} />
            </span>
          )}
        </p>
        <div className={styles.bg} style={{ backgroundImage: `url(${bg})` }}></div>
        <div className={styles.desc}>
          <p className={styles.descTitle}>
            <FormattedMessage id="请验证邮件" values={{ email: registerInfo.email }} />
          </p>
          <p className={styles.descContent}>
            <FormattedMessage id={resendDisable ? '邮件已发送请验证1' : '邮件已发送请验证2'} />
          </p>
          <BendArrowIcon className={styles.icon} />
        </div>
        <Button
          type="primary"
          size="large"
          className={styles.resendBtn}
          onClick={onResend}
          disabled={resendDisable}
          loading={resendLoading}
        >
          <FormattedMessage id="再次发送" />
        </Button>
        <BackToLoginBtn />
      </div>
    </div>
  );
};

export default VerifyEmailPage;
