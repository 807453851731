export enum UserStatus {
  toBind = 0, // 未绑定网站
  toVerify = 10, // 已绑定网站待验证
  verified = 20, // 绑定验证成功
}

export enum RegisterStatus {
  unregister = 'UN_SIGN_UP', // 未注册
  toVerify = 'ALREADY_SIGN_UP', // 已注册未验证邮件
  verified = 'ALREADY_VERIFIED', // 已验证邮件（正式用户）
}

export enum LoginStatus {
  withLogin = 0, // 必须登录，否则跳转到登录页
  withLogout = 1, // 必须未登录，否则跳转到首页
  loginOptional = 2, // 登录或者不登录都可以
}
