import { PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';

interface Props extends PropsWithChildren {
  className?: string;
  mask?: boolean;
}

const MaskLoading = (props: Props) => {
  const { className, mask } = props;
  return (
    <div className={classnames(className, styles.wrapper)}>
      {mask && <div className={styles.loadingMask}></div>}
      <div className={styles.loadingContent}>
        <div className={styles.payLoading}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={styles.addition}>{props.children}</div>
      </div>
    </div>
  );
};

export default MaskLoading;
