import React from 'react';

interface InfiniteScrollProps extends React.HTMLAttributes<HTMLDivElement> {
  fetchNextPage: () => Promise<void> | void;
  hasNextPage: boolean;
  loadingMessage: React.ReactNode;
  endingMessage: React.ReactNode;
}

// eslint-disable-next-line react/display-name
export const InfiniteScroller = React.forwardRef<HTMLDivElement, InfiniteScrollProps>(
  ({ fetchNextPage, hasNextPage, endingMessage, loadingMessage, children, ...props }, ref) => {
    const observerTarget = React.useRef(null);
    const loader = React.useRef(fetchNextPage);
    const hasMore = React.useRef(hasNextPage);

    React.useEffect(() => {
      loader.current = fetchNextPage;
    }, [fetchNextPage]);

    React.useEffect(() => {
      hasMore.current = hasNextPage;
    }, [hasNextPage]);

    React.useEffect(() => {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0]?.isIntersecting && hasMore.current) {
            loader.current && loader.current();
          }
        },
        { threshold: 1 }
      );

      if (observerTarget.current) {
        observer.observe(observerTarget.current);
      }

      return () => observer.disconnect();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div ref={ref} {...props} style={{ overflowAnchor: 'none' }}>
        {children}
        <div ref={observerTarget} />
        {hasNextPage ? loadingMessage : endingMessage}
      </div>
    );
  }
);
