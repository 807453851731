import { useMemo } from 'react';
import styles from './index.module.scss';
import { IApiBusinessPackageResp } from '@api/system-global/__generate__/default/models';
import classNames from 'classnames';

const productTypeLit = { TRIAL: 'Free', BASIC: 'Basic', PRO: 'Pro' };

interface IProps {
  packageInfo: IApiBusinessPackageResp | undefined;
}
const PackageLogo = (props: IProps) => {
  const { packageInfo } = props;
  const packageType = useMemo(() => {
    if (!packageInfo?.productType) return 'Free';
    return productTypeLit[packageInfo?.productType];
  }, [packageInfo?.productType]);

  if (!packageInfo?.productType) return null;

  return (
    <div
      className={classNames(styles.packageType, {
        [styles.free]: packageType === 'Free',
        [styles.basic]: packageType === 'Basic',
        [styles.pro]: packageType === 'Pro',
      })}
    >
      {packageType}
    </div>
  );
};

export default PackageLogo;
