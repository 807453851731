import { useEffect, useCallback, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { trafficCalculationGet } from '@api/system-global/__generate__/default/ProductService';
import ChatDialog from './container/chatdialog';
import OrderCheck from './container/order';
import styles from './styles.module.scss';

const Purchase = () => {
  const [loading, setLoading] = useState(true);
  const [canBuy, setCanBuy] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);

  const fetchState = useCallback(async () => {
    setLoading(true);
    const res = await trafficCalculationGet();
    setCanBuy(res?.data?.data?.shouldRecommendProduct);
    setDaysLeft(res?.data?.data?.daysLeft);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchState();
  }, [fetchState]);

  if (loading) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.loading}>
          <Spin indicator={<LoadingOutlined spin color="" />} size="large" />
        </div>
      </div>
    );
  }

  if (!canBuy) {
    return (
      <div className={styles.wrapper}>
        <ChatDialog daysLeft={daysLeft} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <OrderCheck />
    </div>
  );
};

export default Purchase;
