import React, { useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Popover, Table, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  visitorPageViewStatGet,
  visitorLocationStatGet,
  visitorActivityStatGet,
} from '@api/system-global/__generate__/default/VisitorDetailInfoService';
import {
  IApiVisitorLocationStatResp,
  IApiVisitorPageViewStatResp,
  IApiVisitorActivityStatResp,
} from '@api/system-global/__generate__/default/models';
import { secondsToTime } from '@utils/common';
import { MediaIcon } from '@ui/components/media-icon';
import { CountryFlag } from '@ui/components/country-flag';
import UserIcon from '@feature/assets/icons/user.svg';
import PosIcon from '@feature/assets/icons/position.svg';
import PvIcon from '@feature/assets/icons/pv.svg';
import TimeIcon from '@feature/assets/icons/time.svg';
import SourceIcon from '@feature/assets/icons/source.svg';
import styles from './style.module.scss';

export interface Props {
  domain: string;
  siteId: string;
}
export const Overview: React.FC<Props> = props => {
  const { domain, siteId } = props;
  const [loading, setLoading] = useState(false);
  const [overviewData, setOverviewData] = useState<IApiVisitorActivityStatResp | null>(null);
  const [pvDetailList, setPvDetailList] = useState<IApiVisitorPageViewStatResp[]>([]);
  const [locationDetail, setLocationDetailList] = useState<IApiVisitorLocationStatResp[]>([]);

  const fetchOverviewData = useCallback(async () => {
    if (!domain || !siteId) return;
    setLoading(true);
    const res = await visitorActivityStatGet({ domain, siteId });
    setOverviewData(res.data.data);
    setLoading(false);
  }, [domain, siteId]);

  const fetchPvDetail = useCallback(async () => {
    if (!domain || !siteId) return;
    const res = await visitorPageViewStatGet({ domain, siteId, page: 0, pageSize: 10000 });
    setPvDetailList(res?.data?.data?.records || []);
  }, [domain, siteId]);

  const fetchLocations = useCallback(async () => {
    if (!domain || !siteId) return;
    const res = await visitorLocationStatGet({ domain, siteId });
    setLocationDetailList(res?.data?.data || []);
  }, [domain, siteId]);

  useEffect(() => {
    fetchOverviewData();
    fetchPvDetail();
    fetchLocations();
  }, [fetchOverviewData, fetchPvDetail, fetchLocations]);

  return (
    <div className={styles.overview}>
      <div className={styles.title}>
        <FormattedMessage id="访问统计" />
      </div>

      <div className={styles.cardRow}>
        {loading ? (
          <Spin indicator={<LoadingOutlined spin color="" />} size="default" />
        ) : (
          <>
            <div className={styles.infoCard}>
              <div className={styles.label}>
                <img src={UserIcon} alt="" />
                <span>
                  <FormattedMessage id="访客人数" />
                </span>
              </div>
              <div className={styles.value}>{overviewData?.visitors}</div>
            </div>

            <div className={styles.infoCard}>
              <div className={styles.label}>
                <img src={TimeIcon} alt="" />
                <span>
                  <FormattedMessage id="访问时长" />
                </span>
              </div>
              <div className={styles.value}>{secondsToTime(overviewData?.duration || 0)}</div>
            </div>

            <Popover
              placement="bottom"
              content={
                <div className={styles.popcontent}>
                  <div className={styles.popTitle}>
                    <FormattedMessage id="页面访问次数" />
                  </div>
                  <Table
                    className={styles.pvTable}
                    size="small"
                    dataSource={pvDetailList}
                    columns={[
                      {
                        title: <FormattedMessage id="页面地址" />,
                        dataIndex: 'url',
                        key: 'url',
                      },
                      {
                        title: <FormattedMessage id="访问次数" />,
                        dataIndex: 'count',
                        key: 'count',
                        width: 80,
                      },
                    ]}
                    scroll={{ y: 200 }}
                    pagination={false}
                  />
                </div>
              }
            >
              <div className={classnames(styles.infoCard, styles.canHover)}>
                <div className={styles.label}>
                  <img src={PvIcon} alt="" />
                  <span>
                    <FormattedMessage id="页面访问次数" />
                  </span>
                </div>
                <div className={classnames(styles.value)}>{overviewData?.pageViews}</div>
              </div>
            </Popover>

            <Popover
              placement="bottom"
              content={
                <div className={styles.popcontent}>
                  <div className={styles.popTitle}>
                    <FormattedMessage id="访问地区" />
                  </div>
                  <Table
                    className={styles.locTable}
                    showHeader={false}
                    size="middle"
                    dataSource={locationDetail}
                    columns={[
                      {
                        render: (_, record) => {
                          return (
                            <div className={styles.locationCell}>
                              <CountryFlag code={record.userCountry} size={14} />
                              <a
                                className={styles.location}
                                href={`https://maps.google.com?q=${encodeURIComponent(
                                  `${record.userCity} ${record.userCountry}`
                                )}&mrt=loc`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {record.userCity}, {record.userProvince}, {record.userCountry}
                              </a>
                            </div>
                          );
                        },
                      },
                    ]}
                    pagination={false}
                    scroll={{ y: 200 }}
                  />
                </div>
              }
            >
              <div className={classnames(styles.infoCard, styles.canHover)}>
                <div className={styles.label}>
                  <img src={PosIcon} alt="" />
                  <span>
                    <FormattedMessage id="访问地区" />
                  </span>
                </div>
                <div className={classnames(styles.value)}>{overviewData?.locations}</div>
              </div>
            </Popover>

            <div className={styles.infoCard}>
              <div className={styles.label}>
                <img src={SourceIcon} alt="" />
                <span>
                  <FormattedMessage id="访问来源" />
                </span>
              </div>
              <div className={styles.value}>
                {overviewData?.sourceTypes?.slice(0, 3).map((item, index) => (
                  <MediaIcon key={index} className={styles.mediaIcon} type={item} size={22} border />
                ))}
                {Number(overviewData?.sourceTypes?.length) > 3 && (
                  <Popover
                    placement="bottom"
                    content={
                      <div className={styles.popoverContent}>
                        {overviewData?.sourceTypes.slice(3).map((item: string) => {
                          return <MediaIcon key={item} type={item} />;
                        })}
                      </div>
                    }
                  >
                    <MediaIcon type="MORE" size={22} border />
                  </Popover>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
