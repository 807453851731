import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useMatches, useNavigate, useSearchParams } from 'react-router-dom';
import { App } from 'antd';
import { useIntl } from 'react-intl';
import { IRouteConfig } from '@system-global/config/types';
import { useStore } from '@system-global/store';
import { LoginStatus, UserStatus } from '@system-global/config/user';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { localRegisterInfoHandler } from '@system-global/utils/common';
import { IApiUserInfoResp } from '@api/system-global/__generate__/default/models';
import { IUserInfoRes } from '@system-global/store/slices/createUserSlice';

const EMAIL_REGISTER_TOKEN = 'token';

export function AppRoot() {
  const intl = useIntl();
  const nav = useNavigate();
  const { message } = App.useApp();
  const [init, setInit] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const emailRegisterToken = searchParams.get(EMAIL_REGISTER_TOKEN);

  const matches = useMatches();
  const match = matches[matches.length - 1];
  const handle = match.handle as IRouteConfig;
  const withLogout = handle?.loginStatus === LoginStatus.withLogout;
  const withLogin = !handle || !handle.loginStatus || (handle.loginStatus as LoginStatus) === LoginStatus.withLogin;

  const isLogin = useStore(state => !!state.login_token);
  const fetchUserInfo = useStore(state => state.user_fetchUserInfo);
  const fetchBindInfo = useStore(state => state.user_fetchBindInfo);
  const doLogout = useStore(state => state.global_doLogout);
  const fetchPackageInfo = useStore(state => state.user_fetchBusinessPackage);
  const doLoginSucceed = useStore(state => state.login_doLoginSucceed);

  const onIsLogout = useCallback(() => {
    setInit(true);
    if (withLogin) {
      nav(ROUTE_URLS.sign);
    }
  }, [nav, withLogin]);

  const getBindInfo = useCallback(async () => {
    const res = await fetchBindInfo();
    const { verifyStatus: verifyStatus_1 } = res?.[0] || {};
    // 验证成功后请求套餐信息
    if (verifyStatus_1 === 'VERIFIED') {
      return fetchPackageInfo();
    }
  }, [fetchBindInfo, fetchPackageInfo]);

  const handleUserInfo = useCallback(
    (userInfo: IUserInfoRes) => {
      const { data, code } = userInfo;
      if (code !== 0) {
        doLogout(true);
        return;
      }
      if (data?.userStatus !== UserStatus.verified) {
        nav(ROUTE_URLS.installTracker);
        return;
      }
      if (match.pathname === ROUTE_URLS.root || withLogout) {
        nav(ROUTE_URLS.default);
      }
    },
    [doLogout, match.pathname, nav, withLogout]
  );

  const getBaseInfo = useCallback(() => {
    fetchUserInfo()
      .then(res => {
        handleUserInfo(res);
      })
      .catch(e => {
        console.error('fetUserInfo error:', e);
        doLogout(true);
      })
      .finally(() => {
        setInit(true);
      });
    getBindInfo().catch(e => {
      console.error('fetchBindInfo error:', e);
    });
  }, [doLogout, fetchUserInfo, getBindInfo, handleUserInfo]);

  const onEmailRegisterSucceed = useCallback(
    (token: string, userInfo: IApiUserInfoResp) => {
      localRegisterInfoHandler.del();
      doLoginSucceed(token);
      message.success({
        content: intl.formatMessage({ id: '邮箱验证成功' }),
        duration: 1.5,
        onClose() {
          handleUserInfo({ code: 0, data: userInfo });
        },
      });
    },
    [doLoginSucceed, handleUserInfo, intl, message]
  );

  const onEmailRegister = useCallback(
    (token: string) => {
      fetchUserInfo({ token, noErrorHint: true, refresh: true })
        .then(({ code, data }) => {
          if (+code === 0 && data) {
            onEmailRegisterSucceed(token, data);
            return;
          }
          message.error(intl.formatMessage({ id: '邮箱验证失败' }));
        })
        .catch(e => {
          message.error(e && typeof e === 'string' ? e : intl.formatMessage({ id: '邮箱验证失败' }));
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.delete(EMAIL_REGISTER_TOKEN);
          setSearchParams(newSearchParams);
        });
    },
    [fetchUserInfo, intl, message, onEmailRegisterSucceed, searchParams, setSearchParams]
  );

  const onIsLogin = useCallback(() => {
    if (emailRegisterToken) {
      onEmailRegister(emailRegisterToken);
      return;
    }
    getBaseInfo();
  }, [emailRegisterToken, getBaseInfo, onEmailRegister]);

  useEffect(() => {
    if (isLogin) {
      onIsLogin();
    } else {
      onIsLogout();
    }
  }, [isLogin, onIsLogin, onIsLogout]);

  if (!init) {
    return null;
  }

  return <Outlet />;
}

export default AppRoot;
