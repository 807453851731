import React from 'react';
import { LoginStatus } from '@system-global/config/user';

export interface IRouteConfig {
  hideAside?: boolean;
  hideHeader?: boolean;
  customLayout?: boolean;
  loginStatus?: LoginStatus; // 如果不设置，默认按照 0 对待
}

export interface IRouteItem {
  path: string;
  element: React.ReactElement;
  errorElement?: React.ReactElement;
  children?: IRouteItem[];
  config?: IRouteConfig;
}

export interface IMenuItem {
  label: React.ReactElement;
  key: string;
  children?: IMenuItem[];
}

export enum QuotaType {
  'IP_IDENTIFY_QUOTA-IP' = 'IP_IDENTIFY_QUOTA-IP',
  'CONTACT_REVEAL_QUOTA' = 'CONTACT_REVEAL_QUOTA',
}
