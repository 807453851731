/** 访客详情 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 访客详情 获取访客详情列表 */
export async function visitorDetailListGet(
  payload: {
    domain?: string;
    page?: number;
    pageSize?: number;
    searchDate?: string;
    siteId?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultVisitorDetailListResp>({
    url: `/api/biz/visitor/detail/list`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 访客详情 获取访客详情访问地址统计 */
export async function visitorLocationStatGet(
  payload: {
    domain?: string;
    siteId?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultListVisitorLocationStatResp>({
    url: `/api/biz/visitor/detail/location/stat`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 访客详情 获取访客详情访问页面统计 */
export async function visitorPageViewStatGet(
  payload: {
    domain?: string;
    page?: number;
    pageSize?: number;
    siteId?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultPageVisitorPageViewStatResp>({
    url: `/api/biz/visitor/detail/pageView/stat`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 访客详情 获取访客详情行为统计 */
export async function visitorActivityStatGet(
  payload: {
    domain?: string;
    siteId?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultVisitorActivityStatResp>({
    url: `/api/biz/visitor/detail/stat`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 访客详情 获取访客得分 */
export async function visitorScoreGet(
  payload: {
    domain?: string;
    siteId?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultVisitorScoreResp>({
    url: `/api/biz/visitor/detail/visitorScore`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
