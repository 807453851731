import React, { useMemo, useState } from 'react';
import { Select as AntSelect, RefSelectProps, SelectProps } from 'antd';
import cn from 'classnames';
import { TongyongZhankaiXia } from '@sirius/icons';

import styles from './index.module.scss';
export const Select = React.forwardRef<RefSelectProps, SelectProps>((props, ref) => {
  return (
    <AntSelect
      suffixIcon={
        <span className={styles['select-suffix']}>
          <TongyongZhankaiXia />
        </span>
      }
      variant="filled"
      ref={ref}
      {...props}
      className={cn(styles['select'], props.className)}
      popupClassName={cn(styles['select-popup'], props.popupClassName)}
    />
  );
});

type TupleValue = [string | undefined, string | undefined] | undefined;
interface TupleSelectProps {
  value?: TupleValue;
  onChange?: (value: TupleValue) => void;
  prePlaceholder?: string;
  postPlaceholder?: string;
  options?: Array<{
    label: string;
    value: string;
    children: Array<{
      label: string;
      value: string;
    }>;
  }>;
}
export const TupleSelect: React.FC<TupleSelectProps> = props => {
  const { value: outValue, onChange: onOutChange, prePlaceholder, postPlaceholder, options } = props;
  const [firstVisible, setFirstVisible] = useState(false);
  const [secondVisible, setSecondVisible] = useState(false);
  const [value, setValue] = useState<TupleSelectProps['value']>(outValue);

  const curValue = outValue || value;
  const dropdownVisible = useMemo(() => firstVisible || secondVisible, [firstVisible, secondVisible]);
  const postOptions = useMemo(() => {
    if (curValue) {
      const curOption = options?.find(o => o.value === curValue[0]);
      return curOption?.children;
    }
    return [];
  }, [curValue, options]);
  return (
    <div className={cn(styles['double-select'], dropdownVisible ? styles['dropdown-visible'] : '')}>
      <Select
        size="large"
        open={firstVisible}
        placeholder={prePlaceholder}
        value={curValue ? curValue[0] : undefined}
        options={options}
        onChange={v => {
          setFirstVisible(false);

          if (v === curValue?.[0]) {
            return;
          }
          const newValue: TupleValue = [v, undefined];
          setValue(newValue);
          onOutChange?.(newValue);
        }}
        onDropdownVisibleChange={open => {
          setFirstVisible(open);
          setSecondVisible(false);
        }}
      />
      <Select
        size="large"
        placeholder={postPlaceholder}
        value={curValue ? curValue[1] : undefined}
        open={secondVisible}
        options={postOptions}
        onChange={v => {
          setSecondVisible(false);

          if (v === curValue?.[1]) {
            return;
          }
          const newValue: TupleValue = curValue ? [curValue[0], v] : [undefined, v];
          setValue(newValue);
          onOutChange?.(newValue);
        }}
        onDropdownVisibleChange={open => {
          setSecondVisible(open);
          setFirstVisible(false);
        }}
      />
    </div>
  );
};
export const TupleSelectTest = () => {
  return (
    <TupleSelect
      prePlaceholder="请选择"
      postPlaceholder="请选择"
      options={[
        {
          label: 'A',
          value: 'a',
          children: [
            {
              label: 'A-1',
              value: 'a-1',
            },
            {
              label: 'A-2',
              value: 'a-2',
            },
          ],
        },
        {
          label: 'B',
          value: 'b',
          children: [
            {
              label: 'B-1',
              value: 'b-1',
            },
            {
              label: 'B-2',
              value: 'b-2',
            },
          ],
        },
      ]}
    />
  );
};
