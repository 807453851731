import React, { memo, useMemo } from 'react';
import cn from 'classnames';
import { Tooltip } from 'antd';
import { TongyongJiantou1Xia, TongyongShuomingXian } from '@sirius/icons';
import { FormattedMessage } from 'react-intl';
import { formatNumber } from '@utils/common';
import { IApiCompanyStatResp } from '@api/system-global/__generate__/default/models';
import styles from './index.module.scss';
interface VisitorDashboardProps {
  data: IApiCompanyStatResp | undefined;
}

interface ProgressBarProps {
  percentage: number;
  /**
   * 默认为 blue
   */
  theme?: 'blue' | 'red';
}
const themeClassNames: Record<Required<ProgressBarProps>['theme'], string> = {
  blue: styles['blue'],
  red: styles['red'],
};
/**
 * 默认展示的数量
 * @param props
 * @returns
 */
const defaultShowCount = 6;
const ProgressBar = (props: ProgressBarProps) => {
  const { percentage, theme = 'blue' } = props;
  const themeClassName = themeClassNames[theme];

  const limitPercentage = useMemo(() => {
    return Math.max(0, Math.min(100, percentage));
  }, [percentage]);
  const stylesProps = useMemo(() => {
    return {
      width: `${limitPercentage}%`,
      display: limitPercentage === 0 ? 'none' : 'block',
    };
  }, [limitPercentage]);
  return (
    <div className={cn(styles['percentage-bar'], themeClassName)}>
      <div style={stylesProps}></div>
    </div>
  );
};

interface TopListProps {
  list: {
    name: string;
    percentage: number;
  }[];
  title: string | JSX.Element;
  theme?: ProgressBarProps['theme'];
}

const TopList = (props: TopListProps) => {
  const { list, title, theme } = props;
  return (
    <div className={styles['top-list-container']}>
      <div className={styles['top-subject']}>{title}</div>

      <div className={styles['top-list-wrap']}>
        {list.map((item, index) => {
          return (
            <div key={index}>
              <div className={styles['top-item']}>
                <div>
                  <span>{item.name || '-'}</span>
                  <span>{item.percentage}%</span>
                </div>
                <ProgressBar percentage={item.percentage} theme={theme} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const splitList = (list: TopListProps['list'], showCount: number) => {
  if (list.length === 0) {
    return [
      {
        name: '-',
        percentage: 0,
      },
    ];
  }
  return list.slice(0, showCount);
};
export const VisitorDashboardUi: React.FC<VisitorDashboardProps> = memo(props => {
  const { data } = props;
  /**
   * false 展示默认数量
   * true 展示全部
   */
  const [moreStatus, setMoreStatus] = React.useState(false);
  const { countries, total, average } = useMemo(() => {
    if (data) {
      return data;
    }
    return {
      countries: [],
      average: 0,
      total: 0,
    } as IApiCompanyStatResp;
  }, [data]);

  const moreBtnVisible = countries.length > defaultShowCount;

  const showCount = moreStatus ? countries.length : defaultShowCount;

  const topCountriesList = useMemo<TopListProps['list']>(() => {
    const list = countries.map(item => {
      return {
        name: item.country,
        percentage: item.percent,
      };
    });
    return splitList(list, showCount);
  }, [showCount, countries]);
  return (
    <div className={styles['container']}>
      <div className={styles['title']}>
        <FormattedMessage id="访客统计-洞察" />
      </div>
      {/* 总计 */}
      <ol className={styles['stat']}>
        <li>
          <div className={styles['stat-item-wrapper']}>
            <FormattedMessage id="访客统计-公司总数量" />
            <Tooltip
              title={
                <FormattedMessage
                  id="访客统计-总计#个公司访问了站点"
                  values={{ totalCompanies: formatNumber(total) }}
                />
              }
            >
              <TongyongShuomingXian />
            </Tooltip>
          </div>
          <div>{formatNumber(total)}</div>
        </li>
        <li>
          <div className={styles['stat-item-wrapper']}>
            <FormattedMessage id="访客统计-平均每日访问公司数" />
            <Tooltip title={<FormattedMessage id="访客统计-平均每日访问公司数描述" />}>
              <TongyongShuomingXian />
            </Tooltip>
          </div>
          <div>{average}</div>
        </li>
      </ol>
      {/* 排行 */}
      <div className={styles['top-container']}>
        <TopList title={<FormattedMessage id="访客统计-国家排名" />} list={topCountriesList} />
        {/* <TopList title={<FormattedMessage id="访客统计-行业排名" />} theme="red" list={topIndustriesList} /> */}
      </div>
      {moreBtnVisible ? (
        <div className={cn(styles['more'], moreStatus ? styles['collapse'] : undefined)}>
          <div
            onClick={() => {
              setMoreStatus(!moreStatus);
            }}
          >
            {!moreStatus ? <FormattedMessage id="访客统计-展开更多" /> : <FormattedMessage id="访客统计-收起" />}
            <TongyongJiantou1Xia />
          </div>
        </div>
      ) : null}
    </div>
  );
});
