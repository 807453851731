import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import ErrorIcon from '@system-global/assets/icons/res_error.svg?react';
import styles from './styles.module.scss';

const PurchaseError = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.error}>
      <div className={styles.errorCard}>
        <div className={styles.icon}>
          <ErrorIcon />
        </div>
        <div className={styles.desc}>
          <FormattedMessage id="支付失败" />
        </div>

        <div className={styles.actions}>
          <Button
            className={styles.btn}
            type="primary"
            onClick={() => navigate(ROUTE_URLS.purchase, { replace: true })}
          >
            <FormattedMessage id="返回到支付页面" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseError;
