import { createIntl, createIntlCache } from 'react-intl';
import zh from './template/zh';
import en from './template/en';

export enum LANGUAGES {
  chinese = 'zh',
  english = 'en',
}

export const LANG_MAP: Record<LANGUAGES, Record<string, string>> = {
  [LANGUAGES.chinese]: zh,
  [LANGUAGES.english]: en,
};

const cache = createIntlCache();

// 用于非 React 组件中的国际化语言
export const intl = createIntl(
  {
    locale: LANGUAGES.english,
    messages: LANG_MAP[LANGUAGES.english],
  },
  cache
);
