const TEXT_MAP: Record<string, string> = {
  基本: 'Basic',
  登录: 'Log in',
  注册: 'Sign up',
  首页: 'Home',
  注销: 'Logout',
  中文: 'Chinese',
  英文: 'English',
  刷新: 'refresh',
  '对不起，您访问的页面不存在': 'Sorry, the page does not exist.',
  访问统计: 'Total activity',
  访问记录: 'Visit details',
  访客人数: 'Visitor',
  访问时长: 'Duration',
  页面访问次数: 'Page View',
  访问地区: 'Location',
  访问来源: 'Source',
  进入时间: 'Start at',
  退出时间: 'Out at',
  页面地址: 'Location',
  访问次数: 'View',
  地区: 'location',
  '访客#访问了#个页面': 'Visitor {name} visited {count} pages',
  '访客#访问了#个页面单数': 'Visitor {name} visited {count} page',
  _登录介绍:
    "Website visitor treasure will not only identify the visitor, but also analyze the visitor's company type, through AI and related algorithms, find similar companies and recommend to users.",
  设置: 'Personal',
  个人中心设置: 'Setting',
  支付: 'Billing',
  登出: 'Sign out',
  全部公司: 'All companies',
  新公司: 'New companies',
  访问历史: 'History',
  访问概要: 'Overview',
  联系人: 'Contacts',
  相似公司: 'Similar Companies',
  '您尚未登录，请先登录': 'You are not logged in. Please log in first.',
  还没账号吗: 'Don‘t have an account yet?',
  已经有账号了: 'Have an account already?',
  字段不能为空: '{field} can’t be blank',
  请输入正确的邮箱: 'Please provide a valid email address',
  邮箱: 'Email',
  密码: 'Password',
  用户隐私协议: 'Customer Privacy Notice',
  平台隐私协议: 'Platform Privacy Notice',
  登录时同意用户协议: 'By continuing with the log in you agree to our ',
  注册时同意用户协议: 'By continuing with the sign up you agree to our ',
  请阅读并同意隐私协议: 'Please read and agree to our privacy notice',
  邮箱已注册: 'An account with this email already exists.',
  邮箱未注册: 'User not found',
  邮箱待验证: 'Verifying your email to activate your account',
  重发验证邮件: 'Send verification email',
  正在发送邮件: 'Sending email',
  邮箱验证失败: 'Email verification failed, please log in again',
  邮箱验证成功: 'Email verification Successful',
  '密码8-20个字符': 'Between 8 to 20 characters',
  密码至少包含一个字母和一个数字: 'At least 1 number and 1 letter',
  下一步: 'Next',
  你有: 'You have',
  '14天': '14-day',
  免费试用: 'free trial.',
  请同意用户协议: 'Please agree to the Terms & Conditions',
  创造你的账户: 'Create your LeadsNavi account',
  距离获得线索只有一步之遥: 'You are only seconds away from getting a clue!',
  姓: 'First name',
  名: 'Last name',
  公司名称: 'Company name',
  网址: 'Website',
  创建账号: 'Create account',
  后退: 'Back',
  后退到: 'Back to {page}',
  登录成功: 'Log in Successful',
  登录过期: 'Login expired, please log in again',
  网络超时: 'Network timeout, please try again later',
  网络错误: 'Network error, please try again later',
  网站地址: 'Website address',
  请输入公司名称: 'Please enter the company name',
  请输入网站地址: 'Please enter the website address',
  网站地址格式错误: 'Please enter a valid website address,e.g https://example.com',
  绑定网站标题: 'Welcome to LeadsNavi!',
  绑定网站描述1: 'You are seconds away from your leads!',
  绑定网站描述2: 'Finish your profile to get started. Please enter your company details.',
  绑定网站公司名称: 'Company Name',
  绑定网站网站地址: 'Website address',
  绑定网站下一步: 'Continue',
  绑定网站返回: 'Back',
  安装SDK标题: 'Install the LeadsNavi tracker on your website',
  安装SDK描述:
    'The tracker is essential for LeadsNavi to identify the companies visiting your website. it is GDPR compliant and the setup takes only 2 minutes.',
  安装SDK网站域名: 'Your website',
  安装SDK方式: 'Recommended installation methods',
  安装SDK方式描述: 'For your website, these would work best',
  安装SDK方式1: 'Google Tag Manager',
  安装SDK方式2: 'HTML code',
  安装SDK方式下一步: 'Start',
  安装SDK状态: 'Tracker status',
  安装SDK状态未成功: 'Script not installed',
  安装SDK状态成功: 'Script installed successfully',
  安装步骤1: 'Copy the Website Tracker script',
  安装步骤2: 'Log in to your Google Tag Manager account. Add a new tag and giveit a name, e.g:"Website Tracker"',
  安装步骤3: 'Click "Tag Configuration", select "Custom HTML" and then paste the script',
  安装步骤4: 'Click "Triggering", select "All pages" from the list and then save the Tag',
  安装步骤5: 'Click on the Submit button, and then publish the version to make the new Tag work.',
  安装步骤5描述:
    'If you don’t see the Submit, Publish buttons, ask a colleague who has the access rights to Publish these changes. The newTag won’t work unless the changes are published.',
  安装步骤6: 'Finished all the steps?',
  安装步骤6描述:
    'To verify we’ll open your website to create a visit. lf the tracker is correctly installed, we will redirect you to LeadsNavi in few seconds.',
  复制安装代码: 'Copy script',
  复制ID: 'Copy ID',
  复制成功: 'Successful replication',
  验证安装: 'Verify installation',
  验证中: 'Verifying...',
  SDK验证失败标题: ' Verification failed',
  SDK验证失败描述:
    'We did not detect the Website Tracker on {site}.Please make sure it is installed correctly and verify again.',
  HTML安装步骤2: 'Paste it into the header or footer of your website: {domain}',
  HTML安装步骤3: 'Finished all the steps?',
  HTML安装步骤3描述:
    'To verify we’ll open your website to create a visit. lf the tracker is correctly installed, we will redirect you to LeadsNavi in few seconds.',
  '姓名/职位': 'Contact name / Title',
  联系方式: 'Email',
  更新时间: 'Update time',
  忘记密码: 'Forgot password',
  忘记密码吗: 'Forgot password?',
  重置邮件已发送: 'We have e-mailed you the password reset link.',
  发送重置链接: 'Send reset link',
  忘记密码会发邮件: 'Enter your email,we will send you a link to reset your password.',
  重置密码: 'Reset password',
  重置密码不可用提示: 'You are logged in with a {type} account and cannot reset your password.',
  新密码: 'New password',
  当前密码: 'Current password',
  再次: 'Again',
  取消: 'Cancel',
  再次输入密码: 'Please enter your password again.',
  两次密码不一致: 'The passwords do not match.',
  密码修改完成请重新登录: 'Password has been changed, please log in again',
  注册成功: 'Registration successful',
  注册成功欢迎语1:
    'Welcome to LeadsNavi！Your free trial account has been activated.The confirmation been sent to your email ,valid for ',
  注册成功欢迎语2: 'Welcome to LeadsNavi！Your free trial account has been activated.',
  操作: 'Operation',
  '总计#个公司访问了站点': '{totalCompanies} Total companies that have been visited by the system',
  平均每天访问公司数: 'This is the amount of companies per day in the selected time rate for this feed.',
  全部联系人: 'All contacts',
  联系人姓名职位大写: 'CONTACT NAME/TITLE',
  邮箱大写: 'EMAIL',
  公司大写: 'COMPANY',
  联系人解锁时间大写: 'QUERY TIME',
  操作大写: 'OPERATION',
  '总计#公司': '{total} {append}',
  '高级套餐可访问#个公司': 'The higher-tier plan grants you monthly access to a range of {range} companies.',
  服务已到期: 'The maximum limit has been reached.',
  额度已用完: 'The maximum monthly limit of identified companies has been reached.',
  免费体验期已结束: 'Your free experience has expired',
  增加配额: 'Increase Quota',
  '从#服务到#': 'From {serveStartTime} To {serveEndTime}',
  '包含#和#公司，其中#个公司来自#国家#国家':
    'Including {companyA} and {companyB}, there are {companyCount} companies from the {countryA} and {countryB}.',
  '包含#和#公司，其中#个公司来自#国家':
    'Including {companyA} and {companyB}, there are {companyCount} companies from the {countryA}.',
  '包含#和#公司，公司数为#': 'Including {companyA} and {companyB}, there are {companyCount} companies.',
  '还可以免费试用#天': 'You have {days} days left in your trial.',
  体验期已结束: 'Experience  expired.',
  '#天之前': '{days} days ago',
  升级套餐: 'Upgrade',
  今天: 'today',
  昨天: 'yesterday',
  分钟倒计时: '0{minutes}:{seconds}s',
  登出成功: 'Logout successful',
  请验证邮件: 'Verify your email address: {email}',
  邮件已发送请验证1:
    'An email verification link has been sent to your mailbox. Please follow the link to verify your email.',
  邮件已发送请验证2: 'Send verification to your mailbox. Follow the instructions and experience immediately.',
  再次发送: 'Resend',
  验证邮件已失效请重新发送: 'Verification email has expired, please resend.',
  重置密码失败: 'Password reset failed, please try again later.',
  // 访客列表页文案
  '访客列表-名称': 'Name',
  '访客列表-网址': 'Website',
  '访客列表-介绍': 'Introduction',
  '访客列表-来源': 'Sources',
  '访客列表-相似公司': 'Similar',
  '访客列表-停留时长': 'Duration',
  '访客列表-最后访问时间': 'Last visit',
  '访客列表-访问地区': 'Visiting area',
  '访客列表-标题-访问的公司': 'Visit companies',
  '访客列表-筛选-今天': 'Today',
  '访客列表-筛选-本周': 'This week',
  '访客列表-筛选-最近30天': 'Last 30 days',
  '访客列表-筛选-最近90天': 'Last 90 days',
  '访客列表-来源-#家公司': '{count} companies',
  '访客列表-搜索的内容为空': 'No results for your search',
  '访客列表-搜索的内容为空-描述': "Not seeing what you're looking for? Check which search tab you're in",
  '访客列表-按照最后访问时间排序': 'Sort by last visit',
  '访客列表-按照重要性排序': 'Sort by importance',
  '访客列表-最后访问时间排序': 'Last visit',
  '访客列表-重要性排序': 'importance',
  // 访客统计卡片文案
  '访客统计-洞察': 'Insights',
  '访客统计-公司总数量': 'Total Companies',
  '访客统计-总计#个公司访问了站点': '{totalCompanies} Total companies that have been visited by the system',
  '访客统计-平均每日访问公司数描述': 'This is the amount of companies per day in the selected timerame for this feed.',
  '访客统计-平均每日访问公司数': 'Average companies per day',
  '访客统计-公司排名': 'TOP Companies',
  '访客统计-行业排名': 'TOP INDUSTRIES',
  '访客统计-国家排名': 'TOP COUNTRIES',
  '访客统计-展开更多': 'Show more',
  '访客统计-收起': 'Show less',
  今日: 'Today',
  本周: 'This week',
  近30天: 'Last 30 Days',
  近90天: 'Last 90 Days',
  '共#个公司': '{total} companies',
  '共#个联系人': '{total} {append}',
  // 相关性文案
  '相关性-相关': 'Relevant',
  '相关性-很相关': 'More relevant',
  '相关性-最相关': 'Most relevant',
  // 数据导出弹层相关文案
  '数据导出-介绍': "Please specify the data you want to export. We'l send you a download link once it's ready.",
  '数据导出-标题': 'Export',
  '数据导出-导出访客按钮提示': 'Export Companies',
  '数据导出-导出联系人按钮提示': 'Export Contacts',
  '数据导出-标题-格式': 'FORMAT',
  '数据导出-标题-邮箱': 'EMAIL TO',
  '数据导出-确定': 'Generate Export',
  '数据导出-取消': 'Cancel',
  '数据导出-成功提示#': 'We will send a download link to {email} as soon as it is ready.',
  复制: 'Copy',
  去首页: 'Go to Home Page',
  // 购买套餐tooltip
  联系客户经理: 'Contact account manager',
  '给我们的客户经理发邮件，我们将迅速响应您的需求':
    'Email our account manager and we will respond quickly to your needs.',
  个人信息: 'Personal Details',
  订阅: 'Subscriptions',
  上述详情仅对您可见: 'The following details are visible only to you.',
  个人头像: 'Profile Image',
  解锁失败: '解锁失败',
  国家: 'Country',
  公司官网: 'Website',
  公司地址: 'Location',
  所属行业: 'Industry',
  公司电话: 'Phone',
  成立年份: 'Established',
  公司收入: 'Revenue',
  公司员工: 'Employees',
  通用错误页面提示:
    'It looks like something went wrong. Our website encountered an error at this location. Please try again later. We apologize for any inconvenience.',
  支付数据不足标题: 'We need to collect data for {days} days to provide an accurate price.',
  支付数据不足描述1:
    'The pricing model for Leadsnavi is based on your average traffic.To ensure we calculate the most fair and accurate monthly price for you,we need to collect data for at least 7days.',
  支付数据不足描述2:
    'Altematively,speak with a product expert about a personalized quote and learn how Leadfeeder can create value for your business.Please select a convenient time slot from the following.',
  支付联系产品客服: 'Chat with a product expert',
  支付留资提交成功: 'Reservation successful',
  支付留资提交成功标题: 'Reservation successful',
  支付留资提交成功描述: 'Thank you for your interest in Leadsnavi.',
  支付留资提交成功确认: 'Go Back',
  订单标题: 'Purchase Subscription',
  订单描述:
    'Based on your monthly traffic, Based on your traffic, we estimate that you will get access to: {amountPerMonth} Companies per month,we recommend the {recommendTypeName} plan.',
  订单选项: 'Product solution',
  订单价格信息: 'Subscription information',
  订单年付: 'Annual',
  订单月付: 'Monthly',
  订单期限: 'You are purchasing an annual plan ( {start} - {end} ).',
  订单价格: 'Total cost',
  折扣XX: 'Save {discount}%',
  购买: 'Purchase',
  订单协议: 'By subscribing to the service, you have accepted the terms and conditions of Leadsnavi.',
  IP识别额度: 'Companies Identified / mo',
  客户识别额度: 'Contact / mo',
  公司数量: 'Company User',
  浏览数据存储量: 'Visit Data Storage',
  等待支付结果: 'Waiting for payment result',
  订单已过期: 'Payment results expired',
  支付成功: 'Your payment sucessfully processed',
  支付失败: 'Sorry, your payment was not successful. Please check your payment information and try again',
  返回到支付页面: 'Return payment',
  返回到系统: 'Back to Leadsnavi',
  自定义订单说明:
    ' If you need greater capacity or customized use cases, feel free to discuss personalized pricing with our product experts.',
  联系销售人员按钮: 'Chat with a product expert',
  无限制: 'Unlimited',
  公司设置: 'Company',
  SDK信息菜单: 'Tracker',
  SDK信息标题: 'Tracker',
  网站状态: 'Website Statuses',
  接入代码: 'Tracking Code',
  手动接入: 'Manual',
  接入步骤1:
    'Go to your workspace in Google Tag Manager and click new tag. Click Tag configuration and choose custom HTML from the list.Copy the code below and paste it into the HTML field.',
  接入步骤1提示:
    'Select a Trigger that has trigger type page view and set it to trigger on all page views.And save the tag.',
  脚本ID: 'Your script ID',
  接入步骤2: 'Simply paste the tracking code at the bottom of the head tag on all pages.',
  接入步骤2提示: 'If not managing the website, pass it to the right person.',
  最近7天事件数: 'Event last 7 days',
  最后一条事件: 'Last event',
  支付价格: 'Price',
  支付类型: 'Type',
  服务有效期: 'Validity',
  查看订单: 'View order',
  去体验: 'To experience',
  体验过期标题: 'Your trial period has expired',
  体验过期描述1: 'We hope you enjoyed your trial. ',
  体验过期描述2: 'We’d love to show you how to get the most out of Leadsnavi and give access to all product features.',
  继续体验: 'Continue',
  过去30天的公司: 'Companies In Last 30 Days',
  旗舰价格: 'Estimated Price',
  订购状态: 'Status',
  访问过的公司: 'Visit Companies',
  开始时间: 'Start Date',
  结束时间: 'End Date',
  // 邮件通知
  邮件通知: 'Email Notifications',
  邮件通知描述:
    'Companies that visit your site will send you an email, and you can customize the email notification rules.',
  新建邮件通知: 'Create a new feed',
  通知邮件上限提示: 'The number of feeds reached the maximum of 5',
  创建邮件的标题: 'Create a new feed',
  编辑邮件的标题: 'Edit the feed',
  feed名称: 'FEED NAME',
  feed过滤: 'FILTER',
  feed过滤说明: 'Filters allow you to set criteria to receive only relevant companies for a certain Custom Feed.',
  feed名称提示文案: 'Please enter a feed name',
  feed创建成功: 'Feed creation successful',
  feed更新成功: 'Feed update successful',
  保存Feed: 'Save feed',
  feed发送频率: 'NOTIFICATION INTERVAL',
  feed日: 'Daily',
  feed周: 'Weekly',
  feed邮件接收: 'Please enter the email address you want to send',
  feed接受者: 'MAIL TO',
  创建一个过滤: 'Create a new filter',
  过滤条件的上限: 'The number of filters reached the maximum of 5',
  邮件通知编辑: 'Edit',
  邮件通知删除: 'Delete',
  feed删除成功: 'Delete successful',
  通知频繁修改成功: 'Modification successful',
  创建feed: 'Create a Feed',
  Google登录注册: 'Sign up with Google',
  Google登录: 'Log in with Google',
  GoogleAuthStateError: 'The current URL has been tampered with. Please try again.',
  返回登录页: 'Back to login',
  邮箱账号已被注册: 'This email is already in use. ',
  使用密码登录提示: 'If you already have an account, please Log in.',
  使用密码去登录: 'Use password to login',
};

export default TEXT_MAP;
