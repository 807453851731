import React, { useMemo } from 'react';
import { Input, DatePicker, Radio, Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { useMemoizedFn } from 'ahooks';
import { FormattedMessage } from 'react-intl';
import { IApiCompanyPageReq } from '@api/system-global/__generate__/default/models';
import { getLastDays30TimeRange, getLastDays90TimeRange, getThisWeekTimeRange, getTodayTimeRange } from './util';

import SearchIcon from './svg/search.svg?react';

import styles from './index.module.scss';

export type TimePanelValue = 'today' | 'lastWeek' | 'lastDays30' | 'lastDays90';
/**
 * 搜索参数，后端使用的结构
 */
export type SearchParams = Pick<IApiCompanyPageReq, 'beginDate' | 'endDate' | 'keyword'>;
/**
 * 搜索参数，表单组件使用的结构
 */
export interface SearchFormState {
  keyword?: string;
  timePanel?: TimePanelValue;
  dateRange?: [Dayjs, Dayjs];
}
/**
 * 获取快捷时间面板的时间范围
 * @returns
 */
export const getDatePanelTimeRange = (): Record<TimePanelValue, [number, number]> => {
  return {
    today: getTodayTimeRange(),
    lastWeek: getThisWeekTimeRange(),
    lastDays30: getLastDays30TimeRange(),
    lastDays90: getLastDays90TimeRange(),
  };
};

const searchParamsToFormValues = (params: SearchParams): SearchFormState => {
  const { beginDate, endDate, keyword } = params;
  if (beginDate && endDate) {
    const timePanel = timeRangeToDatePaneId([dayjs(beginDate), dayjs(endDate)]);
    return {
      keyword,
      timePanel,
      dateRange: [dayjs(beginDate), dayjs(endDate)],
    };
  }
  return {
    keyword,
    timePanel: undefined,
    dateRange: undefined,
  };
};
/**
 * 筛选项组件值转换为请求参数
 * @param value
 * @returns
 */
const formValuesToSearchParams = (value: SearchFormState): SearchParams => {
  if (!value) {
    return {};
  }
  let timeParams: SearchParams = {};
  if (value.dateRange) {
    const [beginDate, endDate] = value.dateRange;
    timeParams = {
      beginDate: beginDate.valueOf(),
      // 时间范围结束时间为当天的23:59:59
      endDate: endDate.endOf('day').valueOf(),
    };
  } else if (value.timePanel) {
    const timeRange = getDatePanelTimeRange()[value.timePanel];
    if (timeRange) {
      const [beginDate, endDate] = timeRange;
      timeParams = {
        beginDate: beginDate,
        endDate: endDate,
      };
    }
  }
  return {
    keyword: value.keyword,
    beginDate: timeParams?.beginDate,
    endDate: timeParams?.endDate,
  };
};
/**
 * 时间范围转换为时间面板id
 * @param timeRange
 * @returns
 */
const timeRangeToDatePaneId = (timeRange: [Dayjs, Dayjs]): TimePanelValue | undefined => {
  const beginNumber = timeRange[0].startOf('day').valueOf();
  const endNumber = timeRange[1].endOf('day').valueOf();
  const todayTimeRange = getDatePanelTimeRange();
  let timePanel: TimePanelValue | undefined;
  Object.entries(todayTimeRange).forEach(([key, value]) => {
    if (value[0] === beginNumber && value[1] === endNumber) {
      timePanel = key as TimePanelValue;
    }
  });
  return timePanel;
};

export interface SearchFilterProps {
  /**
   * 右侧插槽
   */
  rightSlot?: React.ReactNode;
  /**
   * 右侧右侧插槽
   */
  rightRightSlot?: React.ReactNode;
  initialValues?: SearchParams;
  onFilterChange: (value: SearchParams) => void;
}
export const SearchFilter: React.FC<SearchFilterProps> = props => {
  const { initialValues, onFilterChange } = props;
  const initialFormState = useMemo<SearchFormState>(() => {
    return searchParamsToFormValues(initialValues || {});
  }, [initialValues]);

  const [form] = Form.useForm<SearchFormState>();
  /**
   * 表单changed后的回调
   */
  const handleSearchFormChange = useMemoizedFn((changedValues: SearchFormState) => {
    onFilterChange(formValuesToSearchParams(changedValues));
  });

  /**
   * antd form组件的onValuesChange事件
   */
  const onValuesChange = useMemoizedFn((changedValues: SearchFormState) => {
    const key = Object.keys(changedValues)[0] as unknown as keyof SearchFormState;
    if (key === 'dateRange' || key === 'timePanel') {
      const searchParams = formValuesToSearchParams(changedValues);
      const nextFormState = searchParamsToFormValues(searchParams);
      form.setFieldsValue({
        dateRange: nextFormState.dateRange,
        timePanel: nextFormState.timePanel,
      });
    }
    const values = form.getFieldsValue();
    handleSearchFormChange(values);
  });
  return (
    <Form
      className={styles['search-form']}
      form={form}
      layout="inline"
      initialValues={initialFormState}
      onValuesChange={onValuesChange}
    >
      {/* 左侧 */}
      <div>
        <Form.Item<SearchFormState> name="keyword" noStyle>
          <Input className={styles['search-input']} placeholder="Search" allowClear prefix={<SearchIcon />} />
        </Form.Item>
      </div>
      {/* 右侧 */}
      <div className={styles['right']}>
        {props.rightSlot}
        <Form.Item<SearchFormState> name="timePanel" noStyle>
          <Radio.Group className={styles['radio-group-wrapper']}>
            <Radio.Button value="today" type="primary">
              <FormattedMessage id="访客列表-筛选-今天" />
            </Radio.Button>
            <Radio.Button value="lastWeek">
              <FormattedMessage id="访客列表-筛选-本周" />
            </Radio.Button>
            <Radio.Button value="lastDays30">
              <FormattedMessage id="访客列表-筛选-最近30天" />
            </Radio.Button>
            <Radio.Button value="lastDays90">
              <FormattedMessage id="访客列表-筛选-最近90天" />
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item<SearchFormState> name="dateRange" noStyle>
          <DatePicker.RangePicker className={styles['date-range']} style={{ marginLeft: 10 }} />
        </Form.Item>
        {props.rightRightSlot}
      </div>
    </Form>
  );
};
