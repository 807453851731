/** 三方登录相关 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 三方登录相关 OAuth获取Token */
export async function getUserInfoPost(payload: model.IApiOAuthUserInfoReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultOAuthLoginUser>({
    url: `/api/pub/oauth/getUserInfo`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
