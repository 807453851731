/** 开放接口（无鉴权） */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 开放接口（无鉴权） 忘记密码 */
export async function forgetPasswordPost(payload: model.IApiResetPasswordReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultstring>({
    url: `/api/pub/user/email/forgetPassword`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 开放接口（无鉴权） 发送手机验证码 */
export async function sendPhoneCodeGet(
  payload: {
    areaCode?: string;
    bizType?: string;
    phoneNumber?: string;
    validate?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultstring>({
    url: `/api/pub/user/sendPhoneCode`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 开放接口（无鉴权） 发送验证邮件 */
export async function sendVerifyEmailPost(
  payload: {
    bizType?: string;
    validate?: string;
    verifyEmail?: string;
    verifyEmailName?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/pub/user/sendVerifyEmail`,
    method: 'post',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 开放接口（无鉴权） 验证邮件 */
export async function verifyEmailGet(
  payload: {
    cs?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/pub/user/verify/email`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
