/**
 * 对 AntDesign 主题进行改写，会覆盖 libs/ui 中的主题
 * https://ant.design/docs/react/customize-theme-cn
 * */
// import variables from './variables.module.scss';
import { AliasToken } from 'antd/es/theme/interface';

const themeToken: Partial<AliasToken> = {};

export const appThemeConfig = {
  themeToken,
};
