import { StateCreator } from 'zustand';
import {
  IApiNotificationCompanyTypeResp,
  IApiNotificationIndustryResp,
} from '@api/system-global/__generate__/default/models';

import {
  getCompanyTypeListGet,
  getIndustryListGet,
} from '@api/system-global/__generate__/default/EmailNotificationService';

type MapsProps = Record<string, { en: string; ch: string }>;

export interface IEmailBDSlice {
  company_options: IApiNotificationCompanyTypeResp[];
  company_maps: MapsProps;
  industry_options: IApiNotificationIndustryResp[];
  industry_maps: MapsProps;
  email_setCompanyInfo(company_type: IApiNotificationCompanyTypeResp[]): void;
  email_setIndustryInfo(industry: IApiNotificationIndustryResp[]): void;
  email_fetchCompanyType(): void;
  email_fetchIndustry(): void;
}

export const createEmailBaseDataSlice: StateCreator<IEmailBDSlice> = (set, get) => {
  return {
    company_options: [],
    company_maps: {},
    industry_options: [],
    industry_maps: {},
    /* action */
    email_setCompanyInfo(options) {
      const company_maps = options.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.companyTypeCode]: {
            en: curr.companyTypeEnglishName,
            ch: curr.companyTypeChineseName,
          },
        };
      }, {});
      set(() => ({ company_options: options, company_maps }));
    },

    email_setIndustryInfo(options) {
      const industry_maps = options.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.industryCode]: {
            en: curr.industryEnglishName,
            ch: curr.industryChineseName,
          },
        };
      }, {});
      set(() => ({ industry_options: options, industry_maps }));
    },

    email_fetchCompanyType() {
      const current = get().company_maps;
      if (!Object.keys(current).length) {
        getCompanyTypeListGet().then(res => {
          if (res.data?.success) {
            get().email_setCompanyInfo(res?.data?.data);
            return { data: res.data.data };
          }
        });
      }
      return Promise.resolve({ data: current });
    },
    email_fetchIndustry() {
      const current = get().company_maps;
      if (!Object.keys(current).length) {
        getIndustryListGet().then(res => {
          if (res.data?.success) {
            get().email_setIndustryInfo(res?.data?.data);
            return { data: res.data.data };
          }
        });
      }
      return Promise.resolve({ data: current });
    },
  };
};
