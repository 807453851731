import { IAxiosCustomParams } from '@api/request';
import { axiosInstance as axios } from '../request';
import type * as model from '../__generate__/default/models';

export interface GlobalSearchCompanyDetail {
  alias: string;
  companyType: string;
  contact: string;
  contactCount: number;
  contactList: Array<GlobalSearchContactItem>;
  domain: string;
  facebook: string;
  foundedDate: string;
  id: string;
  customerStatus?: string;
  orgCustomerStatus?: string;
  contactStatus?: string;
  industries: string;
  instagram: string;
  linkedin: string;
  location: string;
  name: string;
  operatingStatus: string;
  overviewDescription: string;
  overviewDescriptionHighLight?: string;
  phone: string;
  shortDesc: string;
  staff: string;
  twitter: string;
  youtube: string;
  logo?: string;
  country: string;
  domainCountry?: string;
  grubStatus: GrubStatus;
  productList?: GloablSearchProductIntro[];
  /**
   * 收藏Id
   */
  collectId: string | number;
  companyId: string | number;
  mergeCompanys: Array<MergeCompany> | null;
  productCategoryList?: string[];
  productCategoryString?: string;
  relateComapny?: string;
  domainStatus?: number;
  companyNameId?: string;
  countryId?: string;
  origin?: string;
  newsList?: GlobalSearchCompanyDetailNewsList[];
  revenue?: string;
  sic?: string[];
  naics?: string[];
  showCompanyName?: string[];
  recProductList?: string[];
  recIndustryList?: string[];
}

export interface GlobalSearchContactItem {
  contact: string;
  phone: string;
  region: string;
  country: string;
  isNew: boolean;
  checkStatus: number;
  grubStatus: number;
  origin: string;
  type: string;
  name: string;
  linkedinUrl: string;
  facebookUrl: string;
  twitterUrl: string;
  instegtramUrl: string;
  youtubeUrl: string;
  updateTime: string;
  jobTitle: string;
  contactId: string;
  // 邮箱是否可隐藏（公共邮箱）
  isHidden: boolean;
  // 多邮箱
  emails?: Array<{
    email: string;
    origin: string;
    emailStatus: string;
  }>;
  id?: string;
  guessStatus?: 'success' | 'fail';
  guess?: boolean;
  emailGuessStatus?: GrubStatus;
}

export type GrubStatus = 'NOT_GRUBBING' | 'GRUBBING' | 'GRUBBED' | 'OFFLINE_GRUBBING' | 'OFFLINE_GRUBBED';

export interface GloablSearchProductIntro {
  name: string;
  price: string;
  imgUrl: string;
  url?: string;
}
export interface MergeCompany {
  name: string;
  country: string;
  location?: string;
  companyId: string;
  lastExportTime?: string;
  lastImportTime?: string;
  originCompanyName?: string;
  collectId: null | number | string;
  recordType?: 'import' | 'export';
}

export interface GlobalSearchCompanyDetailNewsList {
  url?: string;
  title?: string;
  content?: string;
  date?: string;
  domain?: string;
}

/** 联系人 */
export interface DetailContactPorpReq {
  id: string;
  sort?: string;
  offset?: string;
  page: number;
  size: number;
  type?: ContactType;
  filterItem?: number[];
  timeOrder?: number;
}

export type ContactType = 'ALL' | 'SALE' | 'LEADERS' | 'MANAGER' | 'COMMON';

export interface ContactFilterParamRes {
  content: GlobalSearchContactItem[];
  totalElements: number;
  totalPages: number;
  number: number;
  size: number;
}

export enum GlobalSearchType {
  RELATED_COMPANY = 'RELATED_COMPANY',
  SIMILAR_COMPANY = 'SIMILAR_COMPANY',
}

export enum CompanyDetailTabsType {
  OVERVIEW = 'OVERVIEW',
  CONTACT = 'CONTACT',
  SIMILARCOMPANY = 'SIMILARCOMPANY',
}

interface IApiCompanyPageReq {
  siteId: string;
  domain: string;
  source: number;
  // 搜索类型，RELATED_COMPANY-列表搜索，SIMILAR_COMPANY-相似公司搜索
  globalSearchType: keyof typeof GlobalSearchType;
}

/** 全球搜接口 IP地址库详情页 */
export async function ipDetailGet(customParams?: IAxiosCustomParams & IApiCompanyPageReq) {
  const result = await axios.request<model.IApiJsonResultobject>({
    url: `/api/auth/global/ipDetail`,
    method: 'get',
    params: customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

interface IApiContactPageReq {
  siteId: string;
  id: string;
  page?: number;
  size?: number;
}

/** 全球搜接口 */
export async function getContactPagePost(customParams?: IAxiosCustomParams & IApiContactPageReq) {
  const result = await axios.request<model.IApiGlobalSearchContactReq>({
    url: `/api/auth/global/getContactPage`,
    method: 'post',
    data: customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

interface similarCompanyPageReq {
  siteId: string;
  domain: string;
}

export async function similarCompanyListPost(payload: similarCompanyPageReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultListSimilarCompanyItemResp>({
    url: `/api/biz/company/similarCompanyList`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
