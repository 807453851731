import React, { useRef, useState } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import { useMemoizedFn } from 'ahooks';
import cn from 'classnames';
import styles from './index.module.scss';

interface TooltipTextProps {
  title: string;
  className?: string;
  /**tooltip 的props */
  overlayClassName?: string;
  placement?: TooltipProps['placement'];
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  children?: React.ReactNode;
}

export const TooltipText = (props: TooltipTextProps) => {
  const { title, className, overlayClassName, placement, children, onClick } = props;
  const [open, setOpen] = useState(false);
  const spanRef = useRef<HTMLSpanElement>(null);
  const onOpenChange = useMemoizedFn((open: boolean) => {
    const clientWidth = spanRef.current?.clientWidth || 0;
    const scrollWidth = spanRef.current?.scrollWidth || 0;
    if (clientWidth < scrollWidth && open) {
      setOpen(open);
    } else {
      setOpen(false);
    }
  });
  return (
    <Tooltip
      overlayClassName={overlayClassName}
      title={title}
      open={open}
      onOpenChange={onOpenChange}
      placement={placement}
    >
      <span ref={spanRef} className={cn(styles['span'], className)} onClick={onClick}>
        {children ?? title}
      </span>
    </Tooltip>
  );
};
