import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Layout } from 'antd';
import { settingMenus } from '@system-global/config/menus';
import styles from './style.module.scss';
import { IMenuItem } from '@system-global/config/types';

export function SettingSidebar() {
  const location = useLocation();

  const genLinkItem = (item: IMenuItem, isActive = false, level = 1) => (
    <Link
      key={item.key}
      to={item.key}
      className={classnames([styles.menuItem, styles[`menuItem${level}`], isActive ? styles.isActive : ''])}
    >
      <span className={styles.menuItemLabel}>{item.label}</span>
    </Link>
  );

  return (
    <Layout.Sider width={220} className={styles.settingAside}>
      <div className={styles.menu}>
        {settingMenus.map(v => {
          if (!v.children?.length) {
            return genLinkItem(v);
          }
          return (
            <div className={styles.subMenuGroup} key={v.key}>
              <div className={styles.subMenuItem}>
                <span className={styles.subMenuItemLabel}>{v.label}</span>
              </div>
              {v.children.map(sub => {
                const isActive = location.pathname === sub.key;
                return genLinkItem(sub, isActive, 2);
              })}
            </div>
          );
        })}
      </div>
    </Layout.Sider>
  );
}

export default SettingSidebar;
