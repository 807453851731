import { FormattedMessage } from 'react-intl';
import { ModalProps, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@ui/components/modal';
import styles from './style.module.scss';

export const ReserveModal = (props: ModalProps) => {
  const navigate = useNavigate();

  return (
    <Modal
      maskClosable={false}
      // closable={false}
      {...props}
      title={<FormattedMessage id="支付留资提交成功标题" />}
      okText={<FormattedMessage id="支付留资提交成功确认" />}
      footer={
        <div className={styles.footer}>
          <Button
            className={styles.btn}
            type="primary"
            onClick={() => {
              navigate('/', { replace: true });
            }}
          >
            <FormattedMessage id="支付留资提交成功确认" />
          </Button>
        </div>
      }
    >
      <div className={styles.confirmText}>
        <FormattedMessage id="支付留资提交成功描述" />
      </div>
    </Modal>
  );
};
