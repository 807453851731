import MaskLoading from '@ui/components/mask-loading';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import ErrorIcon from '@system-global/assets/icons/res_error.svg?react';
import RegisterForm from './regForm';
import LoginTip from './loginTip';
import { useLoginState, LoginState } from './hooks/useLoginState';
import styles from './styles.module.scss';

const GoogleAuthHandle = () => {
  const { state, googleEmail, accessCode } = useLoginState();
  const nav = useNavigate();

  return (
    <div className={styles.wrapper}>
      {state === LoginState.Loading && <MaskLoading />}
      {state === LoginState.Register && <RegisterForm defaultEmail={googleEmail} accessCode={accessCode} />}
      {state === LoginState.NeedPasswordLogin && <LoginTip email={googleEmail} />}
      {state === LoginState.StateError && (
        <div className={styles.error}>
          <ErrorIcon />
          <h1>
            <FormattedMessage id="GoogleAuthStateError" />
          </h1>
          <Button type="primary" size="large" onClick={() => nav(ROUTE_URLS.login, { replace: true })}>
            <FormattedMessage id="返回登录页" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default GoogleAuthHandle;
