import { StateCreator } from 'zustand';

export interface IBindSiteInfo {
  name: string;
  siteId: string;
  website: string;
  verifyStatus: string;
}

export interface IBindSlice {
  bind_step: number;
  bind_siteInfo: IBindSiteInfo;
  bind_setStep(step: number): void;
  bind_setSiteInfo(siteInfo: IBindSiteInfo): void;
}

export const createBindSlice: StateCreator<IBindSlice> = set => {
  return {
    /* state */
    bind_step: 1,
    bind_siteInfo: {
      name: '',
      siteId: '',
      website: '',
      verifyStatus: '',
    },
    /* action */
    bind_setStep(step: number) {
      set(() => ({ bind_step: step }));
    },
    bind_setSiteInfo(bind_siteInfo: IBindSiteInfo) {
      set(() => ({ bind_siteInfo }));
    },
  };
};
