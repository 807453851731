/** 通知相关 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 通知相关 添加通知 */
export async function addNotificationPost(payload: model.IApiAddNotificationReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/biz/email/notification/add`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 通知相关 获取通知目标客户类型 */
export async function getCompanyTypeListGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultListNotificationCompanyTypeResp>({
    url: `/api/biz/email/notification/companyType/list`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 通知相关 删除通知 */
export async function delNotificationPost(
  payload: {
    id?: number;
    siteId?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/biz/email/notification/del`,
    method: 'post',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 通知相关 获取通知条件行业列表 */
export async function getIndustryListGet(customParams?: IAxiosCustomParams) {
  const result = await axios.request<model.IApiJsonResultListNotificationIndustryResp>({
    url: `/api/biz/email/notification/industry/list`,
    method: 'get',
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 通知相关 获取通知列表 */
export async function getNotificationListGet(
  payload: {
    siteId?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultListNotificationResp>({
    url: `/api/biz/email/notification/list`,
    method: 'get',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 通知相关 修改通知，全量修改，将通知内容全部传过来，过滤条件会全量替换 */
export async function updateNotificationPost(
  payload: model.IApiUpdateNotificationReq,
  customParams?: IAxiosCustomParams
) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/biz/email/notification/update`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 通知相关 修改执行频率 */
export async function updateNotificationExecuteTypePost(
  payload: {
    executeType?: string;
    id?: number;
    siteId?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/biz/email/notification/update/executeType`,
    method: 'post',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 通知相关 修改通知状态 */
export async function updateNotificationStatusPost(
  payload: {
    id?: number;
    siteId?: string;
    status?: string;
  },
  customParams?: IAxiosCustomParams
) {
  const params = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/biz/email/notification/update/status`,
    method: 'post',
    params,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
