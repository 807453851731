const chineseSymbolList = [
  '。',
  '；',
  '，',
  '：',
  '“',
  '”（',
  '）',
  '、',
  '？',
  '《',
  '》',
  '【',
  '】',
  '〈',
  '〉',
  '『',
  '』',
];
const replaceChiniseSymbol = (str: string) => {
  return chineseSymbolList.reduce((acc, symbol) => {
    while (acc.indexOf(symbol) !== -1) {
      acc = acc.replace(symbol, '');
    }
    return acc;
  }, str);
};

export const getCompanyText = (name: string) => {
  if (typeof name !== 'string' || !name || !name.trim()) {
    return name;
  }

  let resChar;

  // /(?<name>.{2})(?:(\u7ec4|\u90e8|\u59d4\u5458\u4f1a|\u56e2\u961f))$/;
  try {
    name = replaceChiniseSymbol(name.trim());
    const charList = [...name];
    resChar = charList[0];
    const hasCharacter = /[\u4e00-\u9fa5]/.test(name);
    // 如果包含中文
    if (hasCharacter) {
      const defaultName = name.slice(0, 1);
      resChar = defaultName;
    } else if (name.length >= 1) {
      resChar = name.slice(0, 1).toLocaleUpperCase();
    }
  } catch (e) {
    console.warn(e);
  }
  return resChar;
};
