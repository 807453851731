import React from 'react';
import { Popover, Flex, message } from 'antd';
import clipboard from 'clipboard';
import EmailIcon from './svg/email.svg?react';
import CopyIcon from './svg/copy.svg?react';

import styles from './index.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
interface UpgradeTooltipProps {
  email?: string;
  children?: React.ReactNode;
}
/**
 * 默认购买套餐客户经理邮箱
 */
export const defaultEmail = 'raphael@leadsnavi.com';
export const sendMailHref = `mailto:${defaultEmail}`;
export const UpgradeTooltip: React.FC<UpgradeTooltipProps> = (props: UpgradeTooltipProps) => {
  const { email = defaultEmail, children } = props;
  const intl = useIntl();

  return (
    <Popover
      placement="bottomRight"
      overlayClassName={styles['container']}
      content={
        <Flex className={styles['content']}>
          <EmailIcon />
          <div>
            <h3>
              <FormattedMessage id="联系客户经理" />
            </h3>
            <p>
              <FormattedMessage id="给我们的客户经理发邮件，我们将迅速响应您的需求" />
            </p>
            <div className={styles['email-wrapper']}>
              <span>{email}</span>
              <CopyIcon
                onClick={async () => {
                  await clipboard.copy(email);
                  message.success(intl.formatMessage({ id: '复制成功' }));
                }}
              />
            </div>
          </div>
        </Flex>
      }
    >
      {children}
    </Popover>
  );
};
