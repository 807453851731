/// <reference types="vite-plugin-svgr/client" />
import * as ReactDOM from 'react-dom/client';
import App from '@system-global/app';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import '@system-global/utils/request';
import '@ui/styles/reset.css';
import '@ui/styles/global.scss';
import './styles/global.scss';
import './styles/antd-override.scss';

window.__COMMIT_INFO__ = process.env.commitInfo;
window.__VERSION__ = process.env.version;

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
