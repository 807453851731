import React from 'react';
import { Form, Tooltip, FormInstance } from 'antd';
import { useIntl } from 'react-intl';
import { TongyongTianjia } from '@sirius/icons';
import classnames from 'classnames';
import CompactSelect from './compact-item';
import style from './index.module.scss';

interface ICompProps {
  label?: string;
  lastPwd?: string;
  onPressEnter?: () => void;
  form: FormInstance;
}
const MAX_COUNT = 5;

const FilterFormItem: React.FC<ICompProps> = ({ form }) => {
  const intl = useIntl();
  return (
    <Form.List name="filterList">
      {(fields, { add, remove }) => (
        <>
          <div className={style['from-item-title']}>
            <h3 className={style.title}>{intl.formatMessage({ id: 'feed过滤' })}</h3>
            <div className={style.content}>{intl.formatMessage({ id: 'feed过滤说明' })}</div>
          </div>
          {fields.map(field => (
            <Form.Item required={false} key={field.key} className={style.compactFormItem}>
              <Form.Item
                {...field}
                key={field.key}
                validateTrigger={['onSubmit']}
                rules={[
                  () => ({
                    message: 'Please set a criterion',
                    validator(_, value) {
                      if (value?.filterType && value?.filterContent) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Please set a criterion');
                    },
                  }),
                ]}
                noStyle
              >
                <CompactSelect
                  onChangeContent={() => {
                    form.validateFields([['filterList', field.name]]);
                  }}
                  onRemove={() => remove(field.name)}
                  canDelete={fields.length !== 1}
                />
              </Form.Item>
            </Form.Item>
          ))}
          <Form.Item noStyle>
            <Tooltip
              rootClassName={style.feedTooltip}
              style={{ maxWidth: 400 }}
              title={fields.length >= MAX_COUNT ? intl.formatMessage({ id: '过滤条件的上限' }) : ''}
            >
              <div
                className={classnames(style['add-btn'], { [style['add-btn-disabled']]: fields.length >= MAX_COUNT })}
                onClick={() => fields.length < MAX_COUNT && add()}
              >
                <TongyongTianjia />
                <span>{intl.formatMessage({ id: '创建一个过滤' })}</span>
              </div>
            </Tooltip>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default FilterFormItem;
