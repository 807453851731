import React, { useCallback } from 'react';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import { App } from 'antd';
import { useStore } from '@system-global/store';
import LoginContainer from '@system-global/components/login-and-register-form/login-container';
import LoginForm from '@system-global/pages/login/components/login-form';
import { localRegisterInfoHandler } from '@system-global/utils/common';

const LoginPage = () => {
  const intl = useIntl();
  const { message } = App.useApp();

  const doLoginSucceed = useStore(state => state.login_doLoginSucceed);

  const onLoginSucceed = useCallback(
    (token: string) => {
      localRegisterInfoHandler.del();
      message.success({
        content: intl.formatMessage({ id: '登录成功' }),
        duration: 1,
        onClose() {
          token && doLoginSucceed(token);
        },
      });
    },
    [doLoginSucceed, intl, message]
  );

  return (
    <div className={styles.loginPage}>
      <LoginContainer>
        <LoginForm onLoginSucceed={onLoginSucceed} />
      </LoginContainer>
    </div>
  );
};

export default LoginPage;
