import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

type TimeRange = [number, number];
/**
 * 获取今天的时间范围
 * @returns
 * 开始时间为今天的 00:00:00
 * 结束时间为今天的 23:59:59
 */
export const getTodayTimeRange = (): TimeRange => {
  const today = dayjs();
  return [today.startOf('day').valueOf(), today.endOf('day').valueOf()];
};
/**
 * 获取这个星期的时间范围
 * @returns 今天所在的星期的时间范围（周日 - 周六）
 * 开始时间为周日的 00:00:00
 * 结束时间为周六的 23:59:59
 */
export const getThisWeekTimeRange = (): TimeRange => {
  const today = dayjs();
  return [today.startOf('week').valueOf(), today.endOf('week').valueOf()];
};
/**
 * 获取最近30天的时间范围
 * @returns
 * 开始时间为30天前的 00:00:00
 * 结束时间为今天的 23:59:59
 */
export const getLastDays30TimeRange = (): TimeRange => {
  const today = dayjs();
  return [today.subtract(30, 'day').startOf('day').valueOf(), today.endOf('day').valueOf()];
};
/**
 * 获取最近90天的时间范围
 * @returns
 * 开始时间为90天前的 00:00:00
 * 结束时间为今天的 23:59:59
 */
export const getLastDays90TimeRange = (): TimeRange => {
  const today = dayjs();
  return [today.subtract(90, 'day').startOf('day').valueOf(), today.endOf('day').valueOf()];
};

/**
 * 根据一个毫秒值，转成访问时长
 */
export const formatDuration = (duration: number, defaultVal = '<1s') => {
  if (!duration) {
    return defaultVal;
  }

  const suffix = {
    hour: 'h',
    minute: 'min',
    second: 's',
  };
  const seconds = Math.floor(duration / 1000);
  if (seconds < 60) {
    return `${seconds}${suffix.second}`;
  }
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes}${suffix.minute}`;
  }
  const hours = Math.floor(minutes / 60);
  return `${hours}${suffix.hour}`;
};
