import { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import { Tabs, Button, Input, message } from 'antd';
import clipboard from 'clipboard';
import { TongyongWangye, TongyongFuzhi } from '@sirius/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTrackerInfo } from '@system-global/hooks/tracker';
import { lastVisitTimeDesc } from '@utils/common';
import styles from './styles.module.scss';

const { TextArea } = Input;
const Tracker = () => {
  const intl = useIntl();
  const { siteList } = useTrackerInfo();

  const siteInfo = useMemo(() => {
    return siteList?.[0] || null;
  }, [siteList]);

  const verifySuccess = siteInfo?.verifyStatus === 'VERIFIED';

  const copyCode = useCallback(async () => {
    await clipboard.copy(siteInfo.installCode);
    message.success(intl.formatMessage({ id: '复制成功' }));
  }, [siteInfo, intl]);

  const copyID = useCallback(async () => {
    await clipboard.copy(siteInfo.siteId);
    message.success(intl.formatMessage({ id: '复制成功' }));
  }, [siteInfo, intl]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <FormattedMessage id="SDK信息标题" />
      </div>
      <div className={styles.panel}>
        <div className={styles.panelTitle}>
          <FormattedMessage id="网站状态" />
        </div>
        <div className={styles.content}>
          <div className={styles.line}>
            <div className={styles.domain}>
              <TongyongWangye style={{ width: 20, height: 20, marginRight: 12 }} />
              <a href={`https://${siteInfo?.website}`} target="_blank" rel="noreferrer">
                https://{siteInfo?.website}
              </a>
            </div>
            <div className={styles.status}>
              <div className={classnames(styles.statusVal, verifySuccess ? styles.success : '')}>
                <FormattedMessage id={verifySuccess ? '安装SDK状态成功' : '安装SDK状态未成功'} />
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.label}>
                <FormattedMessage id="最近7天事件数" />
              </div>
              <div className={styles.value}>{siteInfo?.recentCompanyCnt || 0}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.label}>
                <FormattedMessage id="最后一条事件" />
              </div>
              <div className={styles.value}>
                {siteInfo?.lastReportDate ? lastVisitTimeDesc(String(siteInfo.lastReportDate)) : '--'}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.panel}>
        <div className={styles.panelTitle}>
          <FormattedMessage id="接入代码" />
        </div>

        <Tabs className={styles.tabs}>
          <Tabs.TabPane tab={<FormattedMessage id="安装SDK方式1" />} key="1">
            <div className={styles.stepList}>
              <div className={styles.step}>
                <div className={styles.stepTitle}>
                  <div className={styles.stepIndex}>1</div>
                  <FormattedMessage id="接入步骤1" />
                </div>
                <div className={styles.stepContent}>
                  <div className={styles.code}>
                    <TextArea
                      className={styles.codeText}
                      autoSize
                      readOnly
                      variant="borderless"
                      value={siteInfo?.installCode || ''}
                    />
                  </div>
                  <div className={styles.stepAction}>
                    <Button onClick={copyCode}>
                      <TongyongFuzhi />
                      <FormattedMessage id="复制" />
                    </Button>
                  </div>
                </div>
                <div className={styles.stepTip}>
                  <FormattedMessage id="接入步骤1提示" />
                </div>
              </div>

              <div className={styles.step}>
                <div className={styles.stepTitle}>
                  <div className={styles.stepIndex}>2</div>
                  <FormattedMessage id="脚本ID" />
                </div>
                <div className={styles.stepContent}>
                  <div className={styles.code}>
                    <TextArea
                      className={styles.codeText}
                      autoSize
                      readOnly
                      variant="borderless"
                      value={siteInfo?.siteId || ''}
                    />
                  </div>
                  <div className={styles.stepAction}>
                    <Button onClick={copyID}>
                      <TongyongFuzhi />
                      <FormattedMessage id="复制" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab={<FormattedMessage id="手动接入" />} key="2">
            <div className={styles.stepList}>
              <div className={styles.step}>
                <div className={styles.stepTitle}>
                  <FormattedMessage id="接入步骤2" />
                </div>
                <div className={styles.stepContent}>
                  <div className={styles.code}>
                    <TextArea
                      className={styles.codeText}
                      autoSize
                      readOnly
                      variant="borderless"
                      value={siteInfo?.installCode || ''}
                    />
                  </div>
                  <div className={styles.stepAction}>
                    <Button onClick={copyCode}>
                      <TongyongFuzhi />
                      <FormattedMessage id="复制" />
                    </Button>
                  </div>
                </div>
                <div className={styles.stepTip}>
                  <FormattedMessage id="接入步骤2提示" />
                </div>
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Tracker;
