/**
 * 对 AntDesign 主题进行改写
 * https://ant.design/docs/react/customize-theme-cn
 * */
import { ComponentTokenMap } from 'antd/es/theme/interface/components';
import variables from './variables.module.scss';
import { AliasToken } from 'antd/es/theme/interface';

const themeToken: Partial<AliasToken> = {
  colorText: variables.Text5,
  colorPrimary: '#000',
  colorPrimaryHover: 'rgba(29, 32, 31, 0.8)',
  colorPrimaryActive: 'rgba(29, 32, 31, 0.8)',
  boxShadowSecondary: '0px 4px 10px 0px rgba(47, 83, 134, 0.12)',
  borderRadiusLG: 4,
  // 用于表示操作失败的 Token 序列，如失败按钮、错误状态提示（Result）组件等。
  colorError: '#fe5b4c',
  // 错误色的文本默认态
  colorErrorText: variables.Error6,
  // 错误色的描边色悬浮态
  colorErrorBorderHover: variables.Error6,
  colorBorder: variables.Line3,
  // colorTextPlaceholder: variables.Text2,
  // colorBorder: variables.Line3,
  // colorError: ' #fe5b4c', // variables.Error6,
  // colorError: variables.Error6,
  // 控制容器在禁用状态下的背景色。
  // colorBgContainerDisabled: '#EBEDF2',
  // 控制禁用状态下的字体颜色。
  colorTextDisabled: variables.Text1,
  // controlOutline: variables.Line3,
  // 浮层的背景蒙层颜色，用于遮罩浮层下面的内容，Modal、Drawer 等组件的蒙层使用的是该 token
  colorBgMask: 'rgba(237, 237, 237, 0.50)',
};

const menuTheme: Partial<ComponentTokenMap['Menu']> = {
  // // 菜单项背景色
  // itemBg: variables.Fill2,
  // // 子菜单项背景色
  // subMenuItemBg: variables.Fill2,
  // // 菜单项激活态背景色
  // itemActiveBg: variables.Brand2,
  // // 菜单项文字颜色
  // itemColor: variables.Text5,
  // // 菜单项悬浮态背景色
  // itemHoverBg: variables.Brand2,
  // // 菜单项文字悬浮颜色
  // itemHoverColor: variables.Text5,
  // // 菜单项选中态背景色
  // itemSelectedBg: variables.Brand2,
  // // 菜单项文字选中颜色
  // itemSelectedColor: variables.Brand6,
  // // 菜单项高度
  // itemHeight: 42,
  // // 图标与文字间距
  // iconMarginInlineEnd: 8,
};

const buttonTheme: Partial<ComponentTokenMap['Button']> = {
  // defaultBg: '#000',
  // defaultColor: '#fff',
};

const tabTheme: Partial<ComponentTokenMap['Tabs']> = {
  // 横向标签页外间距
  horizontalMargin: '0 0 8px 0',
  // 横向标签页标签间距
  horizontalItemGutter: 0,
  // 横向标签页标签内间距
  horizontalItemPadding: '10px 8px',
  //标签文本颜色
  itemColor: variables.Text3,
  // 指示条颜色
  inkBarColor: '#272E47',
  // 标签激活态文本颜色
  itemActiveColor: variables.Text5,
  // 标签悬浮态文本颜色
  itemHoverColor: variables.Text5,
  // 标签选中态文本颜色
  itemSelectedColor: variables.Text5,
};

const paginationTheme: Partial<ComponentTokenMap['Pagination']> = {
  // itemActiveBg: variables.Brand6,
  // itemSize: 30,
};

const modalTheme: Partial<ComponentTokenMap['Modal']> = {
  // // 顶部背景色
  // headerBg: '#9B59B6',
  // // 标题字体颜色
  // titleColor: '#fff',
  // // 	标题字体大小
  // titleFontSize: 20,
};

const layoutTheme: Partial<ComponentTokenMap['Layout']> = {
  bodyBg: '#fff',
  headerBg: '#fff',
  siderBg: '#fff',
};

const formTheme: Partial<ComponentTokenMap['Form']> = {
  // 表单项间距
  itemMarginBottom: 28,
  // 标签颜色
  labelColor: variables.Text5,
};

const inputTheme: Partial<ComponentTokenMap['Input']> = {
  // 大号输入框纵向内边距
  paddingBlockLG: 11,
  // 大号输入框横向内边距
  paddingInlineLG: 16,
  // 错误状态时激活态阴影
  errorActiveShadow: 'none',
};

export const projectThemeConfig = {
  themeToken,
  menuTheme,
  buttonTheme,
  tabTheme,
  modalTheme,
  paginationTheme,
  layoutTheme,
  formTheme,
  inputTheme,
};
