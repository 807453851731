import { useCallback, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { editSitePost, bindSitePost } from '@api/system-global/__generate__/default/SiteBizService';
import { Button, Form, Input } from 'antd';
import { useStore } from '@system-global/store';
import styles from './index.module.scss';

const BindSite = () => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [form] = Form.useForm();
  const siteInfo = useStore(store => store.bind_siteInfo);
  const setSiteInfo = useStore(store => store.bind_setSiteInfo);
  const setStep = useStore(store => store.bind_setStep);
  const disabled = useMemo(() => siteInfo?.verifyStatus === 'VERIFIED', [siteInfo]);

  const onSubmit = useCallback(async () => {
    const params = await form.validateFields();
    try {
      setLoading(true);
      if (siteInfo.siteId) {
        // 修改逻辑
        params.siteId = siteInfo.siteId;
        const res = await editSitePost(params);
        // 下一步
        if (res?.data?.code === 0) {
          setSiteInfo({
            ...siteInfo,
            ...params,
          });
          setStep(2);
        }
        return;
      }

      // 无id 新增逻辑
      const res = await bindSitePost(params);
      if (res?.data?.code === 0) {
        setSiteInfo(res?.data.data || {});
        setStep(2);
      }
    } finally {
      setLoading(false);
    }
  }, [form, setStep, setSiteInfo, siteInfo]);

  return (
    <div className={styles.bindSite}>
      <div className={styles.bindTitle}>
        <FormattedMessage id="绑定网站标题" />
      </div>
      <div className={styles.bindDesc1}>
        <FormattedMessage id="绑定网站描述1" />
      </div>
      <div className={styles.bindDesc2}>
        <FormattedMessage id="绑定网站描述2" />
      </div>
      <div className={styles.bindForm}>
        <Form
          form={form}
          layout="vertical"
          size="large"
          disabled={disabled}
          initialValues={{
            name: siteInfo.name,
            website: siteInfo.website,
          }}
        >
          <Form.Item
            label={<FormattedMessage id="公司名称" />}
            name="name"
            rules={[{ required: true, message: intl.formatMessage({ id: '请输入公司名称' }) }]}
          >
            <Input placeholder={intl.formatMessage({ id: '请输入公司名称' })} />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id="网站地址" />}
            name="website"
            rules={[
              { required: true, message: intl.formatMessage({ id: '请输入网站地址' }) },
              {
                pattern: /^([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/,
                message: intl.formatMessage({ id: '网站地址格式错误' }),
              },
            ]}
          >
            <Input addonBefore="https://" placeholder="example.com" />
          </Form.Item>
        </Form>
        <div className={styles.action}>
          <Button type="primary" size="large" onClick={onSubmit} loading={loading} disabled={loading || disabled}>
            <FormattedMessage id="绑定网站下一步" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BindSite;
