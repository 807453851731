import { memo, useState } from 'react';
import styles from './index.module.scss';
import { Drawer } from 'antd';
import { TongyongGuanbiXian } from '@sirius/icons';
import VisitorDetail from '../visitor-detail';
import { GlobalSearchType } from '@api/system-global/override/GlobalSearch';
import { IApiSimilarCompanyItemResp } from '@api/system-global/__generate__/default/models';
import CompanyAvatar from '@ui/components/company-avatar';
import { CountryFlag } from '@ui/components/country-flag';

const SimilarItem = ({ item, onClick }: { item: IApiSimilarCompanyItemResp; onClick: () => void }) => {
  return (
    <div className={styles.similarItem} onClick={onClick}>
      <CompanyAvatar
        size={52}
        mode="light"
        name={item.companyName}
        textStyle={{ lineHeight: 'normal', fontSize: 54, marginTop: 12, marginLeft: 12, fontWeight: 800 }}
      />
      <div className={styles.middle}>
        <div className={styles.companyInfo}>
          <span className={styles.title}>{item.companyName}</span>
          <CountryFlag className={styles.flag} code={item.country || ''} size={12} />
          <span className={styles.country}>{item.country}</span>
        </div>
        <div className={styles.website}>{item.website}</div>
        {item.staff && <div className={styles.field}>Employees：{item.staff}</div>}
        {item.industries && <div className={styles.field}>Industry：{item.industries}</div>}
        {item.introduction && <div className={styles.field}>Introduction：{item.introduction}</div>}
      </div>
      <div className={styles.contactNumber}>
        <span>{item.contactCount}</span>
        <span>Contacts</span>
      </div>
    </div>
  );
};

const SimilarCompany = ({
  similarData = [],
  siteId = '',
  globalSearchType = 'SIMILAR_COMPANY',
  ...rest
}: {
  siteId: string;
  globalSearchType?: keyof typeof GlobalSearchType;
  similarData: IApiSimilarCompanyItemResp[];
}) => {
  const [simiVisible, setSimiVisible] = useState(false);
  const [similarDomain, setDomain] = useState('');

  const handleClick = (item: IApiSimilarCompanyItemResp) => {
    console.log('SimilarCompany-item: ', item);
    setDomain(item.domain);
    setSimiVisible(true);
  };

  return (
    <div className={styles.similarContainer}>
      {similarData.map(item => (
        <SimilarItem item={item} onClick={() => handleClick(item)} />
      ))}
      <Drawer
        width={880}
        zIndex={10001}
        placement="right"
        closable={false}
        onClose={() => setSimiVisible(false)}
        open={simiVisible}
        push={false}
        styles={{ body: { padding: 0 } }}
        extra={<TongyongGuanbiXian onClick={() => setSimiVisible(false)} style={{ fontSize: 20, color: '#6F7485' }} />}
      >
        <VisitorDetail
          domain={similarDomain}
          siteId={siteId}
          globalSearchType={globalSearchType}
          onClose={() => setSimiVisible(false)}
          {...rest}
        />
      </Drawer>
    </div>
  );
};

export default memo(SimilarCompany);
