import React from 'react';
import { useNavigate } from 'react-router-dom';
import feedImg from '@system-global/assets/icons/new-feed.svg';
import { useIntl } from 'react-intl';
import style from './index.module.scss';

const NewFeedBtn = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <div className={style.wrap} onClick={() => navigate('/settings/personal-setting/emial-notifications?feedType=new')}>
      <img src={feedImg} alt="new" />
      <span>{intl.formatMessage({ id: '创建feed' })}</span>
    </div>
  );
};

export default NewFeedBtn;
