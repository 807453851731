import React, { useMemo } from 'react';
import classnames from 'classnames';
import { FlagMap } from './map';
import styles from './style.module.scss';

interface Props {
  code: string;
  style?: React.CSSProperties;
  size?: number;
  border?: boolean;
  className?: string;
  onClick?: () => void;
}
export const CountryFlag: React.FC<Props> = props => {
  const { code, className, size = 20, style = {}, border = false, ...rest } = props;

  const fileName = useMemo(() => {
    if (!code) {
      return '';
    }
    const key = String(code).replace(/\s/gi, '_');
    return FlagMap[key as keyof typeof FlagMap] || '';
  }, [code]);

  if (!fileName) {
    return null;
  }

  return (
    <img
      className={classnames(styles.icon, className, border ? styles.hasBorder : '')}
      style={{
        height: size,
        ...style,
      }}
      src={`/icons/flags/${fileName}`}
      alt={code}
      {...rest}
    />
  );
};
