import React, { useMemo } from 'react';
import { Form, Tooltip } from 'antd';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { TooltipText } from '@ui/components/tooltip-text';
import styles from './index.module.scss';
import { IApiCompanyPageItemResp } from '@api/system-global/__generate__/default/models';

interface CorrelationDotProps {
  scoreLevel: IApiCompanyPageItemResp['scoreLevel'];
  /**
   * large 8px
   * normal 6px
   */
  size?: 'normal' | 'large';
  /**
   * 相关性介绍
   */
  desc?: string;
  className?: string;
}

const relevant: Record<
  IApiCompanyPageItemResp['scoreLevel'],
  {
    title: React.ReactNode;
    className: string;
    dotClassName: string;
  }
> = {
  // 占位，没啥用
  EMPTY: {
    title: <FormattedMessage id="相关性-相关" />,
    className: 'normal-color',
    dotClassName: 'normal',
  },
  LOW: {
    title: <FormattedMessage id="相关性-相关" />,
    className: 'normal-color',
    dotClassName: 'normal',
  },
  MEDIUM: {
    title: <FormattedMessage id="相关性-很相关" />,
    className: 'high-color',
    dotClassName: 'high',
  },
  HIGH: {
    title: <FormattedMessage id="相关性-最相关" />,
    className: 'highest-color',
    dotClassName: 'highest',
  },
};
/**
 * 列表页用到的相关性小圆点
 * @param props
 * @returns
 */
export const CorrelationDot: React.FC<CorrelationDotProps> = props => {
  const { desc, size = 'normal', scoreLevel, className } = props;
  const conf = relevant[scoreLevel];
  return (
    <Tooltip title={desc}>
      <div className={cn(styles['wrap'], className)}>
        <span className={cn(styles[conf.dotClassName], size === 'large' ? styles['large'] : undefined)}></span>
      </div>
    </Tooltip>
  );
};

export const CorrelationTag: React.FC<CorrelationDotProps> = props => {
  const { desc } = props;
  const levelConf = relevant[props.scoreLevel];
  return (
    <div className={styles['bar']}>
      <span className={styles[levelConf.dotClassName]}></span>
      {desc ? <TooltipText title={desc}></TooltipText> : null}
    </div>
  );
};
