import React, { memo, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRequest, useMemoizedFn } from 'ahooks';
import dayjs from 'dayjs';
import { TableProps, ConfigProvider, TablePaginationConfig, Popover, Button, message, Tooltip } from 'antd';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { TongyongShijianMianxing } from '@sirius/icons';
import { formatNumber } from '@utils/common';
import {
  IApiCompanyPageItemResp,
  IApiCompanyPageReq,
  IApiExportCompanyReq,
  IApiPageRespCompanyPageItemResp,
} from '@api/system-global/__generate__/default/models';
import { exportPost } from '@api/system-global/__generate__/default/VisitorPageService';
import { CompanyDetailTabsType } from '@api/system-global/override/GlobalSearch';
import { MediaIcon } from '@ui/components/media-icon';
import { TooltipText } from '@ui/components/tooltip-text';
import { Table } from '@ui/components/table';
import { CountryFlag } from '@ui/components/country-flag';
import { lastVisitTimeDesc } from '@utils/common';
import { formatDuration } from '../search-filter/util';
import { DataExportModalProps, ExportBtn, ExportModal } from '../data-export-modal';
import { SearchFilter, SearchParams, TimePanelValue, getDatePanelTimeRange } from '../search-filter';
import { CorrelationDot } from './correlation';
import emptyImage from './svg/empty.png';
import LocationIcon from './svg/location.svg?react';
import SourceMoreIcon from './svg/source-more.svg?react';
import SortIcon from './svg/sort.svg?react';
// 样式
import styles from './index.module.scss';

interface VisitorListProps {
  title?: string | JSX.Element;
  /**
   * 站点id
   */
  siteId: string;
  /**
   * 顶部间距
   */
  marginTop?: number;
  initialSearchParamsFlag?: TimePanelValue;
  /**
   * 是否是新公司列表，目前仅导出场景需要区分是否新公司列表
   */
  isNewVisitorList: boolean;
  /**
   * 获取访客列表的api
   * @param params
   * @returns
   */
  getVisitorList: (params: IApiCompanyPageReq) => Promise<IApiPageRespCompanyPageItemResp>;
  onCompanyClick?: (companyData: IApiCompanyPageItemResp, activeTab?: CompanyDetailTabsType) => void;
}

const defaultPageSize = 20;

const renderTableEmpty = () => {
  return (
    <div className={styles['empty']}>
      <img src={emptyImage} alt="empty" />
      <div className={styles['empty-desc']}>
        <h3>
          <FormattedMessage id="访客列表-搜索的内容为空" />
        </h3>
        <p>
          <FormattedMessage id="访客列表-搜索的内容为空-描述" />
        </p>
      </div>
    </div>
  );
};

export type VisitorListCompProps = VisitorListProps & Pick<DataExportModalProps, 'initialEmail'>;
/**
 *
 * @description
 * api文档 https://leads-navi-vr.cowork.netease.com/doc/doc.html#/default/%E5%85%AC%E5%8F%B8%E7%9B%B8%E5%85%B3/pageAllUsingPOST
 */
export const VisitorList: React.FC<VisitorListCompProps> = memo(props => {
  const {
    title,
    marginTop,
    initialSearchParamsFlag,
    getVisitorList,
    onCompanyClick: _onCompanyClick,
    siteId,
    isNewVisitorList,
  } = props;
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [orderType, setOrderType] = useState<IApiExportCompanyReq['orderType']>('LAST_VISIT_AT');
  const [searchParams] = useSearchParams();

  const initialValues = useMemo(() => {
    /**
     * 组件初始化时，如果url参数有开始和结束时间，则获取url的开始和结束时间，
     * 后续变更，不会再同步到url上。
     */
    const beginDateStr = searchParams.get('beginDate');
    const endDateStr = searchParams.get('endDate');
    if (beginDateStr && endDateStr) {
      return {
        beginDate: dayjs(beginDateStr).startOf('day').valueOf(),
        endDate: dayjs(endDateStr).endOf('day').valueOf(),
      };
    }

    if (initialSearchParamsFlag) {
      const timeRange = getDatePanelTimeRange()[initialSearchParamsFlag];
      return {
        beginDate: timeRange[0],
        endDate: timeRange[1],
      };
    }
  }, [initialSearchParamsFlag, searchParams]);
  const curSearchParamsRef = useRef<SearchParams | null>({
    ...initialValues,
    keyword: '',
  });

  const {
    data,
    loading,
    params: [lastestParams],
    run,
  } = useRequest(
    (parmas: IApiCompanyPageReq) => {
      if (!props.siteId) {
        return Promise.resolve({
          content: [],
          pageNo: 0,
          pageSize: 0,
          totalSize: 0,
          totalPage: 0,
        });
      }
      return getVisitorList({
        ...parmas,
        siteId: props.siteId,
        orderType: orderType,
      });
    },
    {
      // loadingDelay: 300,
      debounceWait: 600,
      defaultParams: [
        {
          pageNo: 0,
          pageSize: defaultPageSize,
          ...initialValues,
        },
      ],
      refreshDeps: [props.siteId, orderType],
    }
  );
  const onCompanyClick = useMemoizedFn<Required<VisitorListProps>['onCompanyClick']>(
    (companyData: IApiCompanyPageItemResp, activeTab) => {
      _onCompanyClick?.(companyData, activeTab);
    }
  );
  const fetchListWithLatestParams = useMemoizedFn((params: IApiCompanyPageReq) => {
    run({
      ...lastestParams,
      ...params,
    });
  });
  // 搜索条件变化
  const onSearchFilterChange = useMemoizedFn((value: SearchParams) => {
    curSearchParamsRef.current = value;
    fetchListWithLatestParams({
      pageNo: 0,
      pageSize: defaultPageSize,
      ...value,
    });
  });

  const columns = useMemo<TableProps<IApiCompanyPageItemResp>['columns']>(() => {
    return [
      {
        dataIndex: 'companyName',
        title: <FormattedMessage id="访客列表-名称" />,
        width: '18%',
        render(value, record) {
          return (
            <div className={styles['name-wrapper']}>
              <CountryFlag code={record.country} className={styles['flag']} />
              <TooltipText
                title={value}
                className={styles['normal-text']}
                onClick={e => {
                  onCompanyClick(record);
                  e.stopPropagation();
                }}
              />
              {record.scoreLevel === 'EMPTY' ? null : (
                <CorrelationDot scoreLevel={record.scoreLevel} desc={record.recommendReason} />
              )}
            </div>
          );
        },
      },
      {
        dataIndex: 'website',
        title: <FormattedMessage id="访客列表-网址" />,
        render(value) {
          return (
            <TooltipText className={styles['normal-text']} title={value}>
              <a
                href={value}
                target="_blank"
                rel="noreferrer"
                className={styles.link}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {value}
              </a>
            </TooltipText>
          );
        },
      },
      {
        dataIndex: 'introduction',
        title: <FormattedMessage id="访客列表-介绍" />,
        render(value) {
          return (
            <TooltipText overlayClassName={styles['intro-overlay']} className={styles['normal-text']} title={value} />
          );
        },
      },
      {
        dataIndex: 'similarCompanyCnt',
        width: 140,
        title: <FormattedMessage id="访客列表-相似公司" />,
        render(value: IApiCompanyPageItemResp['similarCompanyCnt'], record) {
          const val = value ?? 0;
          const str = (
            <FormattedMessage
              id="访客列表-来源-#家公司"
              values={{
                count: value ?? 0,
              }}
            />
          );
          if (val === 0) {
            return <span className={styles['no-similar']}>-</span>;
          }
          return (
            <TooltipText
              title={str as unknown as string}
              className={cn(styles['sources-wrapper'], val ? styles['pointer'] : undefined)}
              onClick={
                val
                  ? e => {
                      e.stopPropagation();
                      onCompanyClick(record, CompanyDetailTabsType.SIMILARCOMPANY);
                    }
                  : undefined
              }
            ></TooltipText>
          );
        },
      },
      {
        dataIndex: 'trackSource',
        width: 140,
        title: <FormattedMessage id="访客列表-来源" />,
        render(value) {
          const shorMore = value?.length > 4;
          const firstList = shorMore ? value?.slice(0, 3) : value;
          const moreList = shorMore ? value?.slice(3) : undefined;

          return (
            <div className={styles['similar-wrapper']}>
              {firstList?.map((item: string) => {
                return <MediaIcon type={item} key={item} />;
              })}
              {moreList && moreList.length > 0 ? (
                <Popover
                  placement="bottom"
                  content={
                    <div className={styles['popover-content']}>
                      {moreList?.map((item: string) => {
                        return <MediaIcon key={item} type={item} />;
                      })}
                    </div>
                  }
                >
                  <SourceMoreIcon className={styles['source-more-icon']} />
                </Popover>
              ) : null}
            </div>
          );
        },
      },
      {
        dataIndex: 'stayTime',
        title: <FormattedMessage id="访客列表-停留时长" />,
        width: 120,
        render(value) {
          return (
            <div className={styles['stay-time-wrapper']}>
              <TongyongShijianMianxing />
              <span className={styles['normal-text']}>{formatDuration(value * 1000)}</span>
            </div>
          );
        },
      },
      {
        dataIndex: 'lastVisitAt',
        title: <FormattedMessage id="访客列表-最后访问时间" />,
        width: 146,
        render(value) {
          // return <span className={styles['normal-text']}>{dayjs(value).format('YY-MM-DD HH:mm')}</span>;
          return <span className={styles['normal-text']}>{lastVisitTimeDesc(value)}</span>;
        },
      },
      {
        dataIndex: 'lastVisitLocation',
        title: <FormattedMessage id="访客列表-访问地区" />,
        width: '12%',
        render(value) {
          return (
            <div className={styles['visiting-area-wrapper']}>
              <LocationIcon />
              <TooltipText title={value} className={styles['normal-text']} />
            </div>
          );
        },
      },
    ];
  }, [onCompanyClick]);

  const titleStyle = useMemo(() => {
    if (marginTop !== undefined) {
      return {
        marginTop: `${marginTop}px`,
      };
    }
    return undefined;
  }, [marginTop]);

  const pagination = useMemo<TablePaginationConfig>(() => {
    const { pageNo, pageSize, totalSize } = data || {};
    return {
      showSizeChanger: false,
      total: totalSize,
      current: pageNo !== undefined ? pageNo + 1 : 1,
      pageSize: pageSize,
      onChange(page, pageSize) {
        // 分页变更
        fetchListWithLatestParams({
          pageNo: page - 1,
          pageSize,
        });
      },
      showTotal(total) {
        return (
          <div>
            <FormattedMessage
              id="总计#公司"
              values={{
                total: formatNumber(total),
                append: total > 1 ? 'companies' : 'company',
              }}
            />
          </div>
        );
      },
    };
  }, [data, fetchListWithLatestParams]);
  const onRowProps = useMemo(() => {
    return (record: IApiCompanyPageItemResp) => {
      return {
        onClick: () => {
          onCompanyClick(record);
        },
      };
    };
  }, [onCompanyClick]);

  const intl = useIntl();
  /**
   * 点击导出执行的函数
   */
  const exportOkHandler = useMemoizedFn(async formValues => {
    const filterParams = curSearchParamsRef.current;
    if (!siteId) {
      console.error('没有 siteId');
      return;
    }
    const res = await exportPost({
      beginDate: filterParams?.beginDate,
      keyword: filterParams?.keyword,
      endDate: filterParams?.endDate,
      siteId: siteId,
      emailTo: formValues.receiverEmail,
      format: formValues.format,
      newCompany: isNewVisitorList,
      orderType: orderType,
    });

    // 成功的逻辑
    if (res.data.success) {
      message.success(
        intl.formatMessage(
          { id: '数据导出-成功提示#' },
          {
            email: formValues.receiverEmail,
          }
        )
      );
      setExportModalVisible(false);
    } else {
      // message.error(res.data.message);
    }
  });

  const rightRightSlot = (
    <>
      <Tooltip
        title={
          orderType === 'LAST_VISIT_AT' ? (
            <FormattedMessage id="访客列表-按照最后访问时间排序" />
          ) : (
            <FormattedMessage id="访客列表-按照重要性排序" />
          )
        }
        placement="topLeft"
      >
        <Button
          className={styles['sort-icon']}
          icon={<SortIcon />}
          onClick={() => {
            setOrderType(orderType === 'LAST_VISIT_AT' ? 'SCORE' : 'LAST_VISIT_AT');
          }}
        >
          {orderType !== 'SCORE' ? (
            <FormattedMessage id="访客列表-重要性排序" />
          ) : (
            <FormattedMessage id="访客列表-最后访问时间排序" />
          )}
        </Button>
      </Tooltip>

      <ExportBtn
        tooltipTitle={<FormattedMessage id="数据导出-导出访客按钮提示" />}
        disabled={data?.totalSize === 0}
        left={10}
        onClick={() => {
          setExportModalVisible(true);
        }}
      />
    </>
  );
  return (
    <div>
      <div className={styles['section-title']} style={titleStyle}>
        {title}
      </div>
      <div>
        <SearchFilter
          rightRightSlot={rightRightSlot}
          initialValues={initialValues}
          onFilterChange={onSearchFilterChange}
        />
      </div>
      <ConfigProvider renderEmpty={renderTableEmpty}>
        <Table<IApiCompanyPageItemResp>
          columns={columns}
          rowClassName={styles['table-row']}
          className={styles['table']}
          dataSource={data?.content ?? []}
          rowKey={'id'}
          tableLayout="fixed"
          // scroll={{ x: 'max-content' }}
          onRow={onRowProps}
          loading={loading}
          pagination={pagination}
        />
      </ConfigProvider>
      <ExportModal
        visible={exportModalVisible}
        initialEmail={props.initialEmail}
        onOk={exportOkHandler}
        onCancel={() => {
          setExportModalVisible(false);
        }}
      />
    </div>
  );
});
