import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Form, Button, App } from 'antd';
import UserAgreementChecker from '@system-global/components/login-and-register-form/user-agreement-checker';
import EmailFormItem from '@system-global/components/login-and-register-form/email-form-item';
import { IApiEmailSignupReq } from '@api/system-global/__generate__/default/models';
import PwdFormItemPackage from '@system-global/components/login-and-register-form/pwd-form-item-package';

interface ICompProps {
  registerInfo: IApiEmailSignupReq;
  onSubmit: (params: IEmailAndPwdFieldType) => void;
}

export interface IEmailAndPwdFieldType {
  email: string;
  pwd: string;
}

const EmailAndPwdForm: React.FC<ICompProps> = ({ onSubmit, registerInfo }) => {
  const nav = useNavigate();
  const intl = useIntl();
  const { message } = App.useApp();
  const [form] = Form.useForm<IEmailAndPwdFieldType>();
  const passwordInForm = Form.useWatch('pwd', form);

  const [agreementChecked, setAgreementChecked] = useState(false);

  const onLogin = () => {
    nav(ROUTE_URLS.login);
  };

  const onNext = () => {
    form.validateFields().then(() => {
      if (!agreementChecked) {
        message.error(intl.formatMessage({ id: '请阅读并同意隐私协议' }));
        return;
      }
      const { email, pwd } = form.getFieldsValue();
      onSubmit({ email, pwd });
    });
  };

  useEffect(() => {
    form.setFieldsValue({ email: registerInfo.email, pwd: registerInfo.pwd });
  }, [form, registerInfo.email, registerInfo.pwd]);

  return (
    <div className={styles.container}>
      <p className={styles.tip}>
        <span className={styles.tipItem}>
          <FormattedMessage id="你有" />
        </span>
        <span className={styles.tipItemLink} onClick={onLogin}>
          <FormattedMessage id="14天" />
        </span>
        <span className={styles.tipItem}>
          <FormattedMessage id="免费试用" />
        </span>
      </p>
      <p className={styles.title}>
        <FormattedMessage id="注册" />
      </p>
      <p className={styles.subTitle}>
        <span>
          <FormattedMessage id="已经有账号了" />
        </span>
        <span className={styles.link} onClick={onLogin}>
          <FormattedMessage id="登录" />
        </span>
      </p>
      <Form name="passwordLogin" form={form} layout="vertical" className={styles.loginForm}>
        <EmailFormItem scene="register" onPressEnter={onNext} showResend />
        <PwdFormItemPackage value={passwordInForm} onPressEnter={onNext} />
      </Form>
      <Button type="primary" size="large" className={styles.submitBtn} onClick={onNext}>
        <FormattedMessage id="下一步" />
      </Button>
      <UserAgreementChecker checked={agreementChecked} setChecked={setAgreementChecked} scene="register" />
    </div>
  );
};

export default EmailAndPwdForm;
