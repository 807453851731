import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, Row, Col } from 'antd';
import classnames from 'classnames';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { useNavigate } from 'react-router-dom';
import { TongyongJiantou2Zuo } from '@sirius/icons';
import EmailFormItem from '@system-global/components/login-and-register-form/email-form-item';
import RequiredFormItem from '@system-global/components/login-and-register-form/pwd-form-item-package/required-form-item';
import LoginContainer from '@system-global/components/login-and-register-form/login-container';
import LoginStyle from '@system-global/layout/sub-layouts/login-root/styles.module.scss';
import { useRegisterForm } from './hooks/useRegisterForm';
import styles from './styles.module.scss';

interface Props {
  defaultEmail: string;
  accessCode: string;
}
const RegisterForm: React.FC<Props> = props => {
  const { form, submit, loading, initalValue } = useRegisterForm({
    defaultEmail: props.defaultEmail,
    accessCode: props.accessCode,
  });
  const intl = useIntl();
  const nav = useNavigate();

  const onGoBack = useCallback(() => {
    nav(ROUTE_URLS.login, { replace: true });
  }, [nav]);

  return (
    <div className={classnames(styles.regForm, LoginStyle.loginRoot)}>
      <LoginContainer>
        <div className={styles.formContent}>
          <div className={styles.title}>
            <FormattedMessage id="创造你的账户" />
          </div>
          <div className={styles.subTitle}>
            <FormattedMessage id="距离获得线索只有一步之遥" />
          </div>
          <Form form={form} layout="vertical" initialValues={initalValue}>
            <Row gutter={20}>
              <Col span={12}>
                <RequiredFormItem name="firstName" field={intl.formatMessage({ id: '姓' })} />
              </Col>
              <Col span={12}>
                <RequiredFormItem name="lastName" field={intl.formatMessage({ id: '名' })} />
              </Col>
            </Row>
            <EmailFormItem scene="register" showResend={false} disabled />
          </Form>
          <Button type="primary" size="large" className={styles.confirmBtn} onClick={submit} loading={loading}>
            <FormattedMessage id="创建账号" />
          </Button>
          <div className={styles.goBack} onClick={onGoBack}>
            <span>
              <TongyongJiantou2Zuo style={{ fontSize: 16, marginRight: 8 }} />
              <FormattedMessage id="后退" />
            </span>
          </div>
        </div>
      </LoginContainer>
    </div>
  );
};

export default RegisterForm;
