import React, { useMemo } from 'react';
import { Cascader, Space } from 'antd';
import type { CascaderProps, GetProp } from 'antd';
import { TongyongGuanbiXian } from '@sirius/icons';
import { Select } from '@ui/components/select';
import { CountryCodeList } from '@ui/components/country-flag/map';
import { TongyongZhankaiXia } from '@sirius/icons';
import { FieldType } from '@system-global/pages/email-notifications';
import { useStore } from '@system-global/store';
import style from './compact-item.module.scss';

type DefaultOptionType = GetProp<CascaderProps, 'options'>[number];

interface CustomerProps {
  filterContent: string; // 多个用逗号分割
  filterType: string;
  filterRange: string;
}
type FilterContentProps = (string | number | null)[][] | undefined;
interface Props {
  onRemove: () => void;
  canDelete: boolean;
  value?: CustomerProps;
  onChange?: (value: CustomerProps) => void;
  onChangeContent?: () => void;
}

const OPTIONS = [
  { value: 'INDUSTRY', label: 'Industry' },
  { value: 'COUNTRY', label: 'Country' },
  { value: 'COMPANY_TYPE', label: 'Company type' },
];

const CONDITION_OPTIONS = [
  { value: 'INCLUDE', label: 'Include' },
  { value: 'EXCLUDE', label: 'Exclude' },
];

enum CONDITION {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
}
const PLACEHOLDER_MAP = {
  INDUSTRY: 'Please select the industries from',
  COUNTRY: 'Please select the countries from',
  COMPANY_TYPE: 'Please select the types of companies included',
};

const CompactSelect: React.FC<Props> = ({ value, onChange, onRemove, canDelete, onChangeContent }) => {
  const compantyTypeOptions = useStore(state => state.company_options);
  const industryOptions = useStore(state => state.industry_options);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const type = useMemo(() => {
    return value?.filterType ?? 'INDUSTRY';
  }, [value]);

  const condition = useMemo(() => {
    return value?.filterRange ?? 'INCLUDE';
  }, [value]);

  const filterContent = useMemo(() => {
    return (
      (value?.filterContent
        ?.split(',')
        .filter(value => value)
        .map(item => [item]) as FilterContentProps) ?? []
    );
  }, [value]);

  const filterOptions = useMemo(() => {
    switch (type) {
      case FieldType.INDUSTRY:
        return industryOptions.map(item => ({ label: item.industryEnglishName, value: item.industryCode + '' }));
      case FieldType.COMPANY_TYPE:
        return compantyTypeOptions.map(item => ({
          label: item.companyTypeEnglishName,
          value: item.companyTypeCode + '',
        }));
      default:
        return CountryCodeList();
    }
  }, [type, compantyTypeOptions, industryOptions]);

  return (
    <Space.Compact block>
      <Select
        variant="filled"
        popupMatchSelectWidth={false}
        style={{ width: type === 'COMPANY_TYPE' ? 150 : 100, height: 40 }}
        defaultValue="INDUSTRY"
        value={type}
        onChange={type => {
          onChange &&
            onChange({
              filterType: type,
              filterRange: CONDITION.INCLUDE,
              filterContent: '',
            });
        }}
        options={OPTIONS}
      ></Select>
      {type === FieldType.COMPANY_TYPE ? (
        <Select
          variant="filled"
          style={{ width: 100, height: 40 }}
          value={condition}
          onChange={condition => {
            onChange &&
              onChange({
                filterType: type,
                filterRange: condition,
                filterContent: '',
              });
          }}
          options={CONDITION_OPTIONS}
        ></Select>
      ) : null}
      <Cascader
        suffixIcon={
          <span className={style['select-suffix']}>
            <TongyongZhankaiXia />
          </span>
        }
        rootClassName={style.feedCascader}
        variant="filled"
        style={{
          flex: 1,
          width: '100%',
          height: 40,
        }}
        getPopupContainer={node => node.parentElement}
        placeholder={PLACEHOLDER_MAP[type as keyof typeof PLACEHOLDER_MAP]}
        options={filterOptions}
        showSearch={{ filter }}
        value={filterContent}
        onChange={value => {
          onChange &&
            onChange({
              filterType: type,
              filterRange: condition,
              filterContent: value.flat()?.join(',') ?? '',
            });
          onChangeContent && onChangeContent();
        }}
        multiple
        maxTagCount="responsive"
      />
      {canDelete ? (
        <TongyongGuanbiXian onClick={() => onRemove()} style={{ paddingLeft: 12, fontSize: 20, color: '#8d92a1' }} />
      ) : (
        <span className={style.space}></span>
      )}
    </Space.Compact>
  );
};

export default CompactSelect;
