import { useState, useCallback, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { Button, Input, Spin, message, notification } from 'antd';
import clipboard from 'clipboard';
import { LoadingOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { checkVerifyStatusGet } from '@api/system-global/__generate__/default/SiteBizService';
import { TongyongJiantou1Zuo, TongyongFuzhi } from '@sirius/icons';
import { useStore } from '@system-global/store';
import { useTrackerInfo } from '@system-global/hooks/tracker';
import styles from './index.module.scss';

const { TextArea } = Input;

interface Props {
  onBack?: () => void;
}
const Verify = (props: Props) => {
  const { onBack } = props;
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const setSiteInfo = useStore(store => store.bind_setSiteInfo);
  const siteInfo = useStore(store => store.bind_siteInfo);
  const step = useStore(store => store.bind_step);
  const intervalRef = useRef<number>();
  const MaxVerifyCheck = 10;
  const verifyCheckCount = useRef<number>(0);
  const { getTrackerScript } = useTrackerInfo();

  const verifySuccess = useMemo(() => {
    return siteInfo.verifyStatus === 'VERIFIED';
  }, [siteInfo]);

  const installCode = useMemo(() => {
    if (!siteInfo.siteId) {
      return '--';
    }
    return getTrackerScript(siteInfo.siteId);
  }, [siteInfo.siteId, getTrackerScript]);

  const copyCode = useCallback(async () => {
    await clipboard.copy(installCode);
    message.success(intl.formatMessage({ id: '复制成功' }));
  }, [installCode, intl]);

  const checkVerifyState = useCallback(
    async (windowRef: WindowProxy | null) => {
      try {
        notification.destroy('VerifyFailed');
        const res = await checkVerifyStatusGet(
          { siteId: siteInfo.siteId, website: siteInfo.website },
          { noErrorHint: true }
        );
        if (res?.data?.data?.verified) {
          // 验证成功
          setSiteInfo({ ...siteInfo, verifyStatus: 'VERIFIED' });
          setLoading(false);
          clearTimeout(intervalRef.current);
          if (windowRef) {
            windowRef.close();
          }
          return;
        }

        // 不成功，继续轮询
        verifyCheckCount.current++;
        if (verifyCheckCount.current >= MaxVerifyCheck) {
          // 超过最大轮询次数
          notification.error({
            key: 'VerifyFailed',
            duration: null,
            message: intl.formatMessage({ id: 'SDK验证失败标题' }),
            description: intl.formatMessage({ id: 'SDK验证失败描述' }, { site: siteInfo.website }),
          });
          verifyCheckCount.current = 0;
          setSiteInfo({ ...siteInfo, verifyStatus: 'PRE_VERIFY' });
          setLoading(false);
          clearTimeout(intervalRef.current);
          if (windowRef) {
            windowRef.close();
          }
          return;
        }
        intervalRef.current = window.setTimeout(() => checkVerifyState(windowRef), 3000);
      } catch {
        // 接口失败，继续轮询
        intervalRef.current = window.setTimeout(() => checkVerifyState(windowRef), 3000);
      }
    },
    [siteInfo, setSiteInfo, intl]
  );

  const verify = useCallback(async () => {
    if (!siteInfo?.website) {
      return;
    }
    const url = `https://${siteInfo.website}${
      String(siteInfo.website).includes('?') ? '&' : '?'
    }utm_source=IDENTIFY_VERIFY&t=${Date.now()}`;
    const windowWidth = 2;
    const windowHieth = 1;
    // const screenWith = window?.screen?.width || 0;
    const screenHeight = window?.screen?.height || 0;
    const left = 0; // (screenWith - windowWidth) / 2;
    const top = screenHeight - windowHieth;
    const windowName = '_blank';
    const windowFeatures = `width=${windowWidth},height=${windowHieth},left=${left},top=${top}`;
    const windowRef = window.open(url, windowName, windowFeatures);
    setLoading(true);
    checkVerifyState(windowRef);
  }, [checkVerifyState, siteInfo.website]);

  return (
    <div className={styles.verify}>
      <div className={styles.action}>
        {!verifySuccess && (
          <div className={styles.goBack} onClick={onBack}>
            <TongyongJiantou1Zuo width={16} height={16} />
            <span className={styles.text}>
              <FormattedMessage id="绑定网站返回" />
            </span>
          </div>
        )}
      </div>
      <div className={styles.verifyTitle}>
        <FormattedMessage id="安装SDK标题" />
      </div>
      <div className={styles.verifyDesc}>
        <FormattedMessage id="安装SDK描述" />
      </div>

      <div className={styles.status}>
        <div className={styles.statusLabel}>
          <FormattedMessage id="安装SDK状态" />：
        </div>
        {loading ? (
          <Spin indicator={<LoadingOutlined spin color="" />} size="small" />
        ) : (
          <div className={classnames(styles.statusVal, verifySuccess ? styles.success : '')}>
            <FormattedMessage id={verifySuccess ? '安装SDK状态成功' : '安装SDK状态未成功'} />
          </div>
        )}
      </div>

      <div className={styles.stepList}>
        <div className={styles.step}>
          <div className={styles.stepTitle}>
            <div className={styles.stepIndex}>1</div>
            <FormattedMessage id="安装步骤1" />
          </div>
          <div className={styles.stepContent}>
            <div className={styles.code}>
              <TextArea className={styles.codeText} autoSize readOnly variant="borderless" value={installCode} />
            </div>
            <div className={styles.stepAction}>
              <Button onClick={copyCode}>
                <TongyongFuzhi />
                <FormattedMessage id="复制安装代码" />
              </Button>
            </div>
          </div>
        </div>

        {step === 3 && (
          <>
            <div className={styles.step}>
              <div className={styles.stepTitle}>
                <div className={styles.stepIndex}>2</div>
                <FormattedMessage id="安装步骤2" />
              </div>
            </div>

            <div className={styles.step}>
              <div className={styles.stepTitle}>
                <div className={styles.stepIndex}>3</div>
                <FormattedMessage id="安装步骤3" />
              </div>
            </div>

            <div className={styles.step}>
              <div className={styles.stepTitle}>
                <div className={styles.stepIndex}>4</div>
                <FormattedMessage id="安装步骤4" />
              </div>
            </div>

            <div className={styles.step}>
              <div className={styles.stepTitle}>
                <div className={styles.stepIndex}>5</div>
                <FormattedMessage id="安装步骤5" />
              </div>
              <div className={styles.stepDesc}>
                <FormattedMessage id="安装步骤5描述" />
              </div>
            </div>

            <div className={styles.step}>
              <div className={styles.stepTitle}>
                <div className={styles.stepIndex}>6</div>
                <FormattedMessage id="安装步骤6" />
              </div>
              <div className={styles.stepDesc}>
                <FormattedMessage id="安装步骤6描述" />
              </div>
            </div>
          </>
        )}

        {step === 4 && (
          <>
            <div className={styles.step}>
              <div className={styles.stepTitle}>
                <div className={styles.stepIndex}>2</div>
                <FormattedMessage
                  id="HTML安装步骤2"
                  values={{ domain: <span className={styles.website}>{siteInfo.website || ''}</span> }}
                />
              </div>
            </div>

            <div className={styles.step}>
              <div className={styles.stepTitle}>
                <div className={styles.stepIndex}>3</div>
                <FormattedMessage id="HTML安装步骤3" />
              </div>
              <div className={styles.stepDesc}>
                <FormattedMessage id="HTML安装步骤3描述" />
              </div>
            </div>
          </>
        )}
      </div>

      <div className={styles.verifyAction}>
        {verifySuccess ? (
          <Button
            className={styles.btn}
            type="primary"
            onClick={() => {
              window.location.href = '/';
            }}
            size="middle"
          >
            <FormattedMessage id="去首页" />
            <ArrowRightOutlined />
          </Button>
        ) : (
          <Button
            className={styles.btn}
            type="primary"
            onClick={verify}
            loading={loading}
            disabled={loading}
            size="middle"
          >
            <FormattedMessage id={loading ? '验证中' : '验证安装'} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Verify;
