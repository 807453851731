import React, { memo, useCallback, useMemo, useState } from 'react';
import { Button, message, TablePaginationConfig, TableProps, Tooltip } from 'antd';
import clipboard from 'clipboard';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { TongyongFuzhi, TongyongYouxiang } from '@sirius/icons';
import { GlobalSearchType } from '@api/system-global/override/GlobalSearch';
import { revealContactPost } from '@api/system-global/__generate__/default/ContactBizService';
import { IApiContactResp, IApiPageContactResp } from '@api/system-global/__generate__/default/models';
import OptionSeparator from '@ui/components/option-separator';
import { Table } from '@ui/components/table';
import { formatNumber } from '@utils/common';

import styles from './styles.module.scss';

export interface ContactListProps {
  globalSearchType?: keyof typeof GlobalSearchType;
  contactData: IApiPageContactResp | undefined;
  fetchData?: ({ page, size }: { page: number; size: number }) => void;
  pageParams?: { page: number; size: number };
  siteId: string;
  expired?: boolean;
  domain: string;
  contactLoading?: boolean;
  refreshStm?: () => void;
}

type GlobalSearchContactItemFields = Pick<
  IApiContactResp,
  'name' | 'contact' | 'updateTime' | 'jobTitle' | 'encryptEmail' | 'revealStatus'
>;

export const ContactList: React.FC<ContactListProps> = memo(props => {
  const {
    contactData,
    fetchData,
    pageParams,
    domain,
    siteId = '',
    contactLoading,
    globalSearchType,
    expired,
    refreshStm,
  } = props;
  const [loading, setLoading] = useState(false);
  const [activeBtn, setActiveBtn] = useState('');
  const intl = useIntl();

  const handleClick = useCallback(
    async (record: GlobalSearchContactItemFields) => {
      setActiveBtn(record.encryptEmail);
      try {
        setLoading(true);
        const { jobTitle = '', name = '', encryptContact = '' } = { ...record };

        const res = await revealContactPost({
          domain,
          contactJobTitle: jobTitle,
          contactName: name,
          encryptContactEmail: encryptContact,
          siteId,
          globalSearchType,
        });
        const { data } = res || {};
        if (data.code === 500) throw new Error(data.message || 'An error occurred.');
        refreshStm && refreshStm();
        const { size, page } = pageParams || { size: 10, page: 1 };
        fetchData &&
          fetchData({
            page: page,
            size: size,
          });
      } catch (error) {
        if (error instanceof Error) {
          message.error({
            content: error.message,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [domain, fetchData, globalSearchType, pageParams, refreshStm, siteId]
  );

  const copyContact = useCallback(
    async (record: GlobalSearchContactItemFields) => {
      await clipboard.copy(`${record?.contact || ''}`);
      message.success(intl.formatMessage({ id: '复制成功' }));
    },
    [intl]
  );

  const columns = useMemo<TableProps<GlobalSearchContactItemFields>['columns']>(() => {
    return [
      {
        dataIndex: 'name',
        title: <FormattedMessage id="姓名/职位" />,
        render(value, record) {
          return (
            <div className={styles.name}>
              <div className={styles.mainName}>{value || '--'}</div>
              {record.jobTitle && <div className={styles.subName}>{record.jobTitle}</div>}
            </div>
          );
        },
      },
      {
        dataIndex: 'contact',
        title: <FormattedMessage id="联系方式" />,
        render(value, record) {
          return (
            <div className={styles.contact}>
              {value ? (
                <>
                  <TongyongYouxiang />
                  <span className={styles.emailText}>{value}</span>
                  {record.revealStatus === 10 && (
                    <Tooltip title={<FormattedMessage id="复制" />}>
                      <TongyongFuzhi
                        style={{ marginLeft: -4 }}
                        onClick={e => {
                          e.stopPropagation();
                          copyContact(record);
                        }}
                      />
                    </Tooltip>
                  )}
                </>
              ) : (
                '--'
              )}
            </div>
          );
        },
      },
      {
        dataIndex: 'updateTime',
        title: <FormattedMessage id="更新时间" />,
        width: 140,
        render(value) {
          return <div className={styles.updateTime}>{value || '--'}</div>;
        },
      },
      {
        dataIndex: 'operation',
        title: <FormattedMessage id="操作" />,
        width: 115,
        render: (_, record: GlobalSearchContactItemFields) => {
          const options = [
            <Button
              type="text"
              loading={record.encryptEmail === activeBtn && loading}
              disabled={record.revealStatus === 10 || expired}
              className={classNames(styles.reveal, {
                [styles.disabled]: record.revealStatus === 10 || expired,
              })}
              onClick={() => handleClick(record)}
            >
              Reveal
            </Button>,
          ];
          return <OptionSeparator>{options}</OptionSeparator>;
        },
      },
    ];
  }, [activeBtn, copyContact, expired, handleClick, loading]);

  const pagination = useMemo<TablePaginationConfig>(() => {
    const { totalElements } = contactData || {};
    const { size, page } = pageParams || {};
    return {
      showSizeChanger: false,
      total: totalElements,
      current: page !== undefined ? page + 1 : 0,
      pageSize: size,
      onChange(page, pageSize) {
        // 分页变更
        fetchData &&
          fetchData({
            page: page - 1,
            size: pageSize,
          });
      },
      showTotal(total) {
        return (
          <div>
            <FormattedMessage
              id="共#个联系人"
              values={{
                total: formatNumber(total),
                append: total > 1 ? 'contacts' : 'contact',
              }}
            />
          </div>
        );
      },
    };
  }, [contactData, fetchData, pageParams]);

  return (
    <div>
      <Table<GlobalSearchContactItemFields>
        className={styles.contactTable}
        columns={columns}
        loading={contactLoading}
        dataSource={contactData?.content || []}
        tableLayout="fixed"
        pagination={pagination}
      />
    </div>
  );
});
