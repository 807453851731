import { StateCreator } from 'zustand';
import {
  IApiBusinessPackageResp,
  IApiSiteResp,
  IApiUserInfoResp,
} from '@api/system-global/__generate__/default/models';
import { userInfoGet } from '@api/system-global/__generate__/default/UserBizService';
import { getPreVerifySitesGet } from '@api/system-global/__generate__/default/SiteBizService';
import { currentBusinessPackageGet } from '@api/system-global/__generate__/default/ProductService';

export interface IUserInfoRes {
  data: IApiUserInfoResp | undefined;
  code: number;
}

interface IPackageInfoRes {
  data: IApiBusinessPackageResp | undefined;
  code: number;
}

interface IFetchUserInfoProps {
  token?: string;
  noErrorHint?: boolean;
  refresh?: boolean;
}

export interface IUserSlice {
  user_userInfo: IApiUserInfoResp | undefined;
  user_currentBindSite: IApiSiteResp | undefined;
  user_allBindSites: IApiSiteResp[];
  user_packageInfo: IApiBusinessPackageResp | undefined;
  user_setUserInfo(user_userInfo?: IApiUserInfoResp): void;
  user_fetchUserInfo(params?: IFetchUserInfoProps): Promise<IUserInfoRes>;
  user_setCurrentBindSite(site: IApiSiteResp): void;
  user_setAllBindSites(sites: IApiSiteResp[]): void;
  user_fetchBindInfo(verifyStatus?: string): Promise<IApiSiteResp[]>;
  user_fetchBusinessPackage(params?: IFetchUserInfoProps): Promise<IPackageInfoRes>;
  user_setPackageInfo(user_packageInfo?: IApiBusinessPackageResp): void;
}

export const createUserSlice: StateCreator<IUserSlice> = (set, get) => {
  return {
    /* state */
    user_userInfo: undefined,
    user_currentBindSite: undefined,
    user_allBindSites: [],
    user_packageInfo: undefined,
    /* action */
    user_setUserInfo(userInfo) {
      set(() => ({ user_userInfo: userInfo }));
    },
    user_setPackageInfo(packageInfo) {
      set(() => ({ user_packageInfo: packageInfo }));
    },
    user_fetchUserInfo(params) {
      const { token, noErrorHint, refresh } = params || {};
      const current = get().user_userInfo;
      if (!current || refresh) {
        return userInfoGet({ token, noErrorHint }).then(v => {
          if (v.data && +v.data.code === 0 && v.data.data) {
            get().user_setUserInfo(v.data.data);
            return { data: v.data.data, code: +v.data.code };
          }
          return { data: undefined, code: +v.data.code };
        });
      }
      return Promise.resolve({ data: current, code: 0 });
    },
    user_setCurrentBindSite(site) {
      set({ user_currentBindSite: site });
    },
    user_setAllBindSites(sites) {
      set({ user_allBindSites: sites });
    },
    async user_fetchBindInfo(verifyStatus) {
      const res = await getPreVerifySitesGet({ verifyStatus });
      if (res.data.data && res.data.data.sites) {
        const sites = res.data.data.sites;
        get().user_setAllBindSites(sites);
        get().user_setCurrentBindSite(sites[0]);
        return sites;
      }
      return [];
    },
    user_fetchBusinessPackage(params) {
      const { token, noErrorHint, refresh } = params || {};
      const current = get().user_packageInfo;
      if (!current || refresh) {
        return currentBusinessPackageGet({ token, noErrorHint }).then(v => {
          if (v.data && +v.data.code === 0 && v.data.data) {
            get().user_setPackageInfo(v.data.data);
            return { data: v.data.data, code: +v.data.code };
          }
          return { data: undefined, code: +v.data.code };
        });
      }
      return Promise.resolve({ data: current, code: 0 });
    },
  };
};
