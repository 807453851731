import React from 'react';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { onValidatePwdRepeat } from '@system-global/utils/validator';
import PasswordInput from '../password-input';

interface IFieldType {
  pwd: string;
  repeatPwd: string;
}

interface ICompProps {
  label?: string;
  lastPwd: string;
  onPressEnter?: () => void;
}

const PasswordRepeatFormItem: React.FC<ICompProps> = ({ label, lastPwd, onPressEnter }) => {
  const intl = useIntl();

  return (
    <Form.Item<IFieldType>
      dependencies={['pwd']}
      name="repeatPwd"
      label={label ? label.toUpperCase() : intl.formatMessage({ id: '再次' }).toUpperCase()}
      rules={[
        {
          validator(_, value) {
            return onValidatePwdRepeat(value, lastPwd);
          },
        },
      ]}
    >
      <PasswordInput onPressEnter={onPressEnter} />
    </Form.Item>
  );
};

export default PasswordRepeatFormItem;
