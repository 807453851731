export const FlagMap = {
  Mexico: 'Mexico.png',
  United_States: 'United_States.png',
  Canada: 'Canada.png',
  Jamaica: 'Jamaica.png',
  Greenland: 'Greenland.png',
  Argentina: 'Argentina.png',
  Peru: 'Peru.png',
  Costa_Rica: 'Costa_Rica.png',
  Ecuador: 'Ecuador.png',
  Chile: 'Chile.png',
  Colombia: 'Colombia.png',
  Panama: 'Panama.png',
  Uruguay: 'Uruguay.png',
  Bolivia: 'Bolivia.png',
  Paraguay: 'Paraguay.png',
  Brazil: 'Brazil.png',
  Venezuela: 'Venezuela.png',
  Guatemala: 'Guatemala.png',
  Honduras: 'Honduras.png',
  Dominican_Republic: 'Dominican.png',
  El_Salvador: 'El_Salvador.png',
  Nicaragua: 'Nicaragua.png',
  Anguilla: 'Anguilla.png',
  Bermuda: 'Bermuda.png',
  Belize: 'Belize.png',
  Dominica: 'Dominica.png',
  Guyana: 'Guyana.png',
  'Virgin_Islands_(British)': 'British_Virgin_Islands.png',
  Cayman_Islands: 'Cayman_Islands.png',
  Aruba: 'Aruba.png',
  Cuba: 'Cuba.png',
  French_Guiana: 'French_Guiana.png',
  Grenada: 'Grenada.png',
  Antigua_And_Barbuda: 'Antigua_and_Barbuda.png',
  Puerto_Rico: 'Puerto_Rico.png',
  Suriname: 'Surinam.png',
  Trinidad_and_Tobago: 'Trinidad_and_Tobago.png',
  'Virgin_Islands_(U.S.)': 'Virgin_Islands.png',
  Martinique: 'Martinique.png',
  Saint_Kitts_and_Nevis: 'Saint_Kitts_and_Nevis.png',
  Saint_Lucia: 'Saint_Lucia.png',
  'St._Pierre_and_Miquelon': 'Saint_Pierre_and_Miquelon.png',
  Netherlands_Antilles: 'Netherlands_Antilles.png',
  Saint_Vincent_and_the_Grenadines: 'Saint_Vincent_And_The_Grenadines.png',
  Haiti: 'Haiti.png',
  Turks_and_Caicos_Islands: 'Turks_and_Caicos_Islands.png',
  Barbados: 'Barbados.png',
  Bahamas: 'Bahamas.png',
  Guadeloupe: 'Guadeloupe.png',
  Montserrat: 'Montserrat.png',
  India: 'India.png',
  Vietnam: 'Vietnam.png',
  Indonesia: 'Indonesia.png',
  Turkey: 'Turkey.png',
  Philippines: 'Philippines.png',
  Kazakhstan: 'Kazakhstan.png',
  Sri_Lanka: 'Sri_Lanka.png',
  Pakistan: 'Pakistan.png',
  Bangladesh: 'Bengal.png',
  Uzbekistan: 'Uzbekistan.png',
  China: 'China.png',
  Thailand: 'Thailand.png',
  South_Korea: 'K.O.Korea.png',
  Japan: 'Japan.png',
  Armenia: 'Armenia.png',
  United_Arab_Emirates: 'United_Arab_Emirates.png',
  'Taiwan(China)': 'China.png',
  Malaysia: 'Malaysia.png',
  Singapore: 'Singapore.png',
  Saudi_Arabia: 'Saudi_Arabia.png',
  Kyrgyzstan: 'Kyrgyzstan.png',
  Tajikistan: 'Tajikistan.png',
  Iraq: 'Iraq.png',
  Mongolia: 'Mongolia.png',
  Kuwait: 'Kuwait.png',
  Iran: 'Iran.png',
  Azerbaijan: 'Azerbaijan.png',
  Qatar: 'Qatar.png',
  Georgia: 'Georgia.png',
  Turkmenistan: 'Turkmenistan.png',
  Oman: 'Oman.png',
  Bahrain: 'Bahrain.png',
  Israel: 'Israel.png',
  Cambodia: 'Cambodia.png',
  Myanmar: 'Burma.png',
  Afghanistan: 'Afghanistan.png',
  'Macau(China)': 'Macao.png',
  Macau: 'Macao.png',
  Jordan: 'Jordan.png',
  Lebanon: 'Lebanon.png',
  'Hong_Kong(China)': 'Hong_Kong.png',
  Hong_Kong: 'Hong_Kong.png',
  Syria: 'Syria.png',
  Yemen: 'Yemen.png',
  Laos: 'Laos.png',
  Palestine: 'Palestine.png',
  Nepal: 'Nepal.png',
  Bhutan: 'Bhutan.png',
  Korea: 'D.P.R.Korea.png',
  East_Timor: 'East_Timor.png',
  Brunei_Darussalam: 'Brunei.png',
  Maldives: 'Maldives.png',
  Russia: 'Russia.png',
  Ukraine: 'Ukraine.png',
  United_Kingdom: 'United_Kingdom.png',
  The_Republic_of_Moldova: 'Moldova.png',
  Belarus: 'Belarus.png',
  Poland: 'Poland.png',
  Germany: 'Germany.png',
  Italy: 'Italy.png',
  Netherlands: 'Netherlands.png',
  Spain: 'Spain.png',
  Belgium: 'Belgium.png',
  France: 'France.png',
  Hungary: 'Hungary.png',
  Czech_Republic: 'Czech_Republic.png',
  Greece: 'Greece.png',
  Norway: 'Norway.png',
  Finland: 'Finland.png',
  Portugal: 'Portugal.png',
  Lithuania: 'Lithuania.png',
  Sweden: 'Sweden.png',
  Denmark: 'Denmark.png',
  Slovenia: 'Slovene.png',
  Latvia: 'Latvia.png',
  Austria: 'Austria.png',
  Slovakia: 'Slovakia.png',
  Estonia: 'Estonia.png',
  Malta: 'Malta.png',
  Romania: 'Romania.png',
  Switzerland: 'Switzerland.png',
  Serbia: 'Serbia.png',
  Ireland: 'Ireland.png',
  Bulgaria: 'Bulgaria.png',
  Bosnia_and_Herzegovina: 'Bosnia_and_Herzegovina.png',
  Luxembourg: 'Luxembourg.png',
  Croatia: 'Croatia.png',
  Macedonia: 'Macedonia.png',
  Liechtenstein: 'Liechtenstein.png',
  Cyprus: 'Cyprus.png',
  Montenegro: 'Republic_of_Montenegro.png',
  Albania: 'Albania.png',
  Iceland: 'Iceland.png',
  Guam: 'Guam.png',
  Andorra: 'Andorra.png',
  Gibraltar: 'Gibraltar.png',
  Marshall_Islands: 'Marshall_Islands.png',
  Monaco: 'Monaco.png',
  San_Marino: 'San_Marino.png',
  'VaticanCity_State_(Holy_See)': 'Vatican_City.png',
  Pitcairn: 'Pitcairn_Islands.png',
  Republic_of_Kosovo: 'Kosovo.png',
  Svalbard_and_Jan_Mayen_Islands: 'Svalbard.png',
  Guernsey: 'Guernsey.png',
  Namibia: 'Namibia.png',
  Uganda: 'Uganda.png',
  Nigeria: 'Nigeria.png',
  Botswana: 'Botswana.png',
  Ethiopia: 'Ethiopia.png',
  Kenya: 'Kenya.png',
  Ghana: 'Ghana.png',
  Lesotho: 'Lesotho.png',
  "Cote_d'Ivoire": 'Cote_DIvoire.png',
  Zimbabwe: 'Zimbabwe.png',
  Cameroon: 'Cameroon.png',
  Egypt: 'Egypt.png',
  Liberia: 'Liberia.png',
  Chad: 'Chad.png',
  Algeria: 'Algeria.png',
  Djibouti: 'Djibouti.png',
  Central_African_Republic: 'Central_African.png',
  Morocco: 'Morocco.png',
  South_Africa: 'South_Africa.png',
  Sudan: 'Sudan.png',
  Tunisia: 'Tunisia.png',
  Tanzania: 'Tanzania.png',
  Senegal: 'Senegal.png',
  Benin: 'Benin.png',
  Togo: 'Togo.png',
  Libya: 'Libya.png',
  Seychelles: 'Seychelles.png',
  Angola: 'Angola.png',
  Mali: 'Mali.png',
  Rwanda: 'Rwanda.png',
  Western_Sahara: 'Western_Sahara.png',
  Reunion: 'Reunion.png',
  Sierra_Leone: 'Sierra_Leone.png',
  Burundi: 'Burundi.png',
  Burkina_Faso: 'BurkinaFaso.png',
  Cape_Verde: 'Cape_Verde_Is..png',
  Comoros: 'Comoros.png',
  'Congo,_the_Democratic_Republic_of_the': 'The_Democratic_Republic_of_the_Congo.png',
  Equatorial_Guinea: 'Equatorial_Guinea.png',
  Gabon: 'Gabon.png',
  Guinea: 'Guinea.png',
  Gambia: 'Gambia.png',
  Kiribati: 'Kiribati.png',
  Madagascar: 'Madagascar.png',
  Malawi: 'Malawi.png',
  Mauritania: 'Mauritania.png',
  Mauritius: 'Mauritius.png',
  Mozambique: 'Mozambique.png',
  Niger: 'Niger.png',
  Solomon_Islands: 'Solomon_Islands.png',
  Somalia: 'Somalia.png',
  Swaziland: 'Swaziland.png',
  Zambia: 'Zambia.png',
  Mayotte: 'Mayotte.png',
  Bouvet_Island: 'Bouvet_Island.png',
  Eritrea: 'Eritrea.png',
  Sao_Tome_and_Principe: 'Sao_Tome_and_Principe.png',
  Congo: 'The_Republic_of_Congo.png',
  Saint_Helena: 'Saint_Helena.png',
  'Guinea-Bissau': 'Guinea-Bissau.png',
  Australia: 'Australia.png',
  Fiji: 'Fiji.png',
  New_Zealand: 'New_Zealand.png',
  American_Samoa: 'American_Samoa.png',
  French_Polynesia: 'French_Polynesia.png',
  Christmas_Island: 'Christmas_Island.png',
  Cook_Islands: 'Cook_Islands.png',
  Nauru: 'Nauru.png',
  'Micronesia,_Federated_States_of': 'Micronesia.png',
  New_Caledonia: 'New_Caledonia.png',
  Niue: 'Niue.png',
  Palau: 'Palau.png',
  Papua_New_Guinea: 'Papua_New_Guinea.png',
  Tokelau: 'Tokelau.png',
  Tonga: 'Tonga.png',
  Tuvalu: 'Tuvalu.png',
  Vanuatu: 'Vanuatu.png',
  Northern_Mariana_Islands: 'Northern_Mariana_Islands.png',
  Wallis_and_Futuna_Islands: 'Wallis_And_Futuna_Islands.png',
  Norfolk_Island: 'Norfolk_Island.png',
  South_Georgia_and_the_South_Sandwich_Islands: 'South_Georgia_and_the_South_Sandwich_Islands.png',
  Aland_Islands: 'Aland_Islands.png',
  Antarctica: 'Antarctica.png',
  Brunei: 'Brunei.png',
  Antigua_and_Barbuda: 'Antigua_and_Barbuda.png',
  Nouth_Korea: 'D.P.R.Korea.png',
  'Timor-Leste': 'East_Timor.png',
  Taiwan: 'China.png',
  Samoa: 'American_Samoa.png',
  Wallis_and_Futuna: 'Wallis_And_Futuna_Islands.png',
  Svalbard_and_Jan_Mayen: 'Svalbard.png',
  European_Union: 'European_Union.png',
  'Moldova,_Republic_of': 'Moldova.png',
  Northern_Ireland: 'Ireland.png',
  Wales: 'Wales.png',
  KosovoCzech: 'Kosovo.png',
  Czech: 'Czech_Republic.png',
  Eswatini: 'Swaziland.png',
  "Cote_D'Ivoire": 'Cote_DIvoire.png',
  Saint_Barthélemy: 'Saint_Barthelemy.png',
  Saint_Pierre_and_Miquelon: 'Saint_Pierre_and_Miquelon.png',
  United_States_Virgin_Islands: 'Virgin_Islands.png',
  French_Southern_Territories: 'French_Southern_Territories.png',
  'Cocos_(Keeling)_Islands': 'Cocos_Islands.png',
  Curaçao: 'Curacao.png',
  'Falkland_Islands_(Malvinas)': 'South_Georgia_and_the_South_Sandwich_Islands.png',
  Caribbean_Netherlands: 'Caribbean_Netherlands.png',
  'Sint_Maarten_(Dutch_part)': 'Sint_Maarten.png',
  Heard_Island_and_McDonald_Islands: 'Heard_Island_and_McDonald_Islands.png',
  Kosovo: 'Kosovo.png',
  Isle_of_Man: 'Isle_of_Man.png',
  'Saint_Martin_(French_part)': 'Saint_Martin.png',
  Czechoslovakia: 'Czechoslovakia.png',
  Scotland: 'Scotland.png',
  British_Indian_Ocean_Territory: 'British_Indian_Ocean_Territory.png',
  Jersey: 'Jersey.png',
  Vatican_City: 'Vatican_City.png',
  Free_Trade_Area: '',
  Gaza_Strip: '',
  South_Ossetia: '',
  United_States_Minor_Outlying_Islands: '',
  Tierra_del_Fuego_Special_Customs_Area: '',
  Faroe_Islands: '',
  Africa: '',
  Unknown_Country: '',
  'Bosnia_&_Hercegovina': 'Bosnia_and_Herzegovina.png',
  British_Virgin_Islands: 'British_Virgin_Islands.png',
  'Antigua_&_Barbuda': 'Antigua_and_Barbuda.png',
  Nederlandse_Antillen: 'Nederlandse_Antillen.png',
  'Trinidad_&_Tobago': 'Trinidad_and_Tobago.png',
  South_Sudan: 'South_Sudan.png',
  Macedonia_Rep: 'Macedonia.png',
  Korea_DPR: 'D.P.R.Korea.png',
  Korea_Rep: 'K.O.Korea.png',
  Laos_PDR: 'Laos.png',
  Croatia_Rep: 'Croatia.png',
  EU: 'EU.png',
  Moldova: 'Moldova.png',
  Slovak_Rep: 'Slovakia.png',
  Slovenia_Rep: 'Slovene.png',
  Srbija: 'Serbia.png',
  Cook_Is: 'Cook_Islands.png',
};
export const CountryCodeList = () => {
  return Object.keys(FlagMap).map(item => {
    const code = item.replace(/_/g, ' ');
    return {
      label: code,
      value: code,
    };
  });
};
