import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { TongyongCuowutishiXian } from '@sirius/icons';
import styles from './style.module.scss';

export const ExpiredCard = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <TongyongCuowutishiXian style={{ marginRight: 8 }} />
        <FormattedMessage id="体验过期标题" />
      </div>
      <div className={styles.desc}>
        <p>
          <FormattedMessage id="体验过期描述1" />
        </p>
        <p>
          <FormattedMessage id="体验过期描述2" />
        </p>
      </div>
      <div className={styles.action}>
        <Button type="primary" className={styles.btn} onClick={() => navigate('/purchase')}>
          <FormattedMessage id="继续体验" />
        </Button>
      </div>
    </div>
  );
};
