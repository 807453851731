import { Tabs, TabsProps } from 'antd';
import classNames from 'classnames';
import styles from './index.module.scss';
import { TabsType } from 'antd/es/tabs';
const { TabPane } = Tabs;

interface Props extends Omit<TabsProps, 'type'> {
  type?: TabsType | 'capsule';
}

const SysTabs = (props: Props) => {
  const { type, className, ...rest } = props;

  return (
    <Tabs
      {...rest}
      className={classNames(
        styles.sysTabs,
        {
          [styles.sysCapsule]: type === 'capsule',
        },
        className
      )}
    />
  );
};

SysTabs.TabPane = TabPane;

export default SysTabs;
