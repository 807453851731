import { useIntl } from 'react-intl';
import { Button, Result } from 'antd';
import styles from './style.module.scss';

const ErrorPage = () => {
  const intl = useIntl();

  return (
    <div className={styles.pageContainer}>
      <Result
        className={styles.content}
        status="500"
        title="500"
        subTitle={intl.formatMessage({ id: '通用错误页面提示' })}
        extra={
          <Button className={styles.btn} type="primary" onClick={() => (window.location.href = '/')}>
            {intl.formatMessage({ id: '刷新' })}
          </Button>
        }
      />
    </div>
  );
};

export default ErrorPage;
