import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { App, Radio, Switch, ConfigProvider, Tooltip } from 'antd';
import { TongyongBianji, TongyongShanchu } from '@sirius/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  getNotificationListGet,
  delNotificationPost,
  updateNotificationExecuteTypePost,
  updateNotificationStatusPost,
} from '@api/system-global/__generate__/default/EmailNotificationService';
import { IApiNotificationResp, IApiNotificationFilter } from '@api/system-global/__generate__/default/models';
import { useStore } from '@system-global/store';
import NewFeedModal from './components/feed-modal';
import Button from './components/button';
import styles from './styles.module.scss';

export enum FieldType {
  COUNTRY = 'COUNTRY',
  INDUSTRY = 'INDUSTRY',
  COMPANY_TYPE = 'COMPANY_TYPE',
}
export enum Status {
  OPEN = 'OPEN',
  CLOSED = 'CLOSE',
}

export enum CONDITION {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
}

export interface RenderProps extends IApiNotificationResp {
  renderShow?: IApiNotificationFilter[];
  renderContent?: string[];
}

const EmailNotificatons = () => {
  const intl = useIntl();
  const [searchParams, _] = useSearchParams();
  const { message } = App.useApp();
  const userData = useStore(state => state.user_currentBindSite);
  const userInfo = useStore(state => state.user_userInfo);
  const [modalVisible, setModalVisible] = useState(false);
  const fetchCompanyType = useStore(state => state.email_fetchCompanyType);
  const fetchIndustry = useStore(state => state.email_fetchIndustry);
  const company_maps = useStore(state => state.company_maps);
  const industry_maps = useStore(state => state.industry_maps);
  const [list, setList] = useState<IApiNotificationResp[]>([]);
  const [fetchFlag, setFlag] = useState<boolean>(false);
  const [renderList, setRenderList] = useState<RenderProps[]>([]);
  const [editData, setEditData] = useState<RenderProps>();

  const onChange = (executeType: string, data: RenderProps) => {
    updateNotificationExecuteTypePost({
      executeType,
      id: data.id,
      siteId: data.siteId,
    }).then(res => {
      if (res.data.success) {
        message.success(intl.formatMessage({ id: '通知频繁修改成功' }));
        getEmailsNotice();
      }
    });
  };

  const onChangeSwitch = (checked: boolean, data: RenderProps) => {
    updateNotificationStatusPost({
      status: checked ? Status.OPEN : Status.CLOSED,
      id: data.id,
      siteId: data.siteId,
    }).then(res => {
      if (res.data.success) {
        message.success(intl.formatMessage({ id: '通知频繁修改成功' }));
        getEmailsNotice();
      }
    });
  };

  const newFeed = () => {
    setModalVisible(true);
  };

  const delNotification = (id: number, siteId: string) => {
    const params = {
      id,
      siteId,
    };
    delNotificationPost(params).then(res => {
      if (res.data.success) {
        message.success(intl.formatMessage({ id: 'feed删除成功' }));
        getEmailsNotice();
      }
    });
  };

  const getEmailsNotice = useCallback(() => {
    getNotificationListGet({ siteId: userData?.siteId }).then(res => {
      if (res.data.success) {
        setList(res.data.data);
        setFlag(true);
      }
    });
  }, [userData?.siteId]);

  useEffect(() => {
    getEmailsNotice();
  }, [getEmailsNotice]);

  const canCreated = useMemo(() => {
    return list.length ? list.filter(item => item.type === 'CUSTOM').length < 5 : true;
  }, [list]);

  useEffect(() => {
    if (fetchFlag && searchParams.get('feedType') === 'new') {
      if (canCreated) {
        newFeed();
      }
      _({ feedType: '' });
    }
  }, [searchParams, canCreated, fetchFlag]);

  useEffect(() => {
    if (Object.keys(company_maps).length && Object.keys(industry_maps).length && list.length) {
      const newList = list.map(rootItem => {
        const renderShow = rootItem.filterList.map(childItem => {
          switch (childItem.filterType) {
            case FieldType.COMPANY_TYPE:
            case FieldType.INDUSTRY:
              return {
                ...childItem,
                filterContent: childItem.filterContent
                  .split(',')
                  .map(key =>
                    childItem.filterType === FieldType.COMPANY_TYPE ? company_maps[key]?.en : industry_maps[key]?.en
                  )
                  .join(','),
              };
            default:
              return childItem;
          }
        });
        const renderContent = renderShow.map(item => {
          if (rootItem.type === 'SYSTEM') return 'No filter conditions';
          switch (item.filterType) {
            case FieldType.COMPANY_TYPE:
              if (item.filterRange === CONDITION.INCLUDE) {
                return `The types of company include ${item.filterContent}`;
              }
              return `The types of company exclude ${item.filterContent}`;
            case FieldType.COUNTRY:
              return `Countries from ${item.filterContent}`;
            case FieldType.INDUSTRY:
              return `Industries from ${item.filterContent}`;
            default:
              return '';
          }
        });
        return {
          renderContent,
          renderShow,
          ...rootItem,
        };
      });
      setRenderList(newList);
    } else {
      setRenderList([]);
    }
  }, [company_maps, industry_maps, list]);

  useEffect(() => {
    getNotificationListGet({ siteId: userData?.siteId }).then(res => {
      if (res.data.success) {
        setList(res.data.data);
      }
    });
  }, [userData?.siteId]);

  useEffect(() => {
    fetchCompanyType();
    fetchIndustry();
  }, [fetchCompanyType, fetchIndustry]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#9B59B6',
          colorPrimaryHover: '#9B59B6',
          colorPrimaryActive: '#9B59B6',
        },
      }}
    >
      <div className={styles.emailsNotifications}>
        <div className={styles.titleWrapper}>
          <p className={styles.title}>
            <FormattedMessage id="邮件通知" />
          </p>
          <p className={styles.subTitle}>
            <FormattedMessage id="邮件通知描述" />
          </p>
        </div>
        <div className={styles.btnWrapper}>
          <Button
            onClick={() => newFeed()}
            disabled={!canCreated}
            disabledText={intl.formatMessage({ id: '通知邮件上限提示' })}
          >
            <FormattedMessage id="新建邮件通知" />
          </Button>
        </div>
        <div className={styles.emailsContent}>
          {renderList.map(item => (
            <div className={styles.item} key={item.id}>
              <div className={styles.itemRow1}>
                <div className={styles.col1}>
                  <Tooltip title={item.name}>
                    <span className={styles.name}>{item.name}</span>
                  </Tooltip>
                  {item.type === 'SYSTEM' ? <span className={styles.tag}>acquiescent</span> : null}
                </div>
                <div className={styles.col2}>
                  <Radio.Group onChange={e => onChange(e.target.value, item)} value={item.executeType}>
                    <Radio style={{ paddingRight: 64 }} value={'DAILY'}>
                      Daily
                    </Radio>
                    <Radio value={'WEEKLY'}>Weekly</Radio>
                  </Radio.Group>
                </div>
                <div className={styles.col3}>
                  <Switch value={item.status === Status.OPEN} onChange={checked => onChangeSwitch(checked, item)} />
                </div>
              </div>
              <div className={styles.itemRow2}>
                <span> Website:</span>
                <span> {item.website || '-'} </span>
              </div>
              {item.type === 'CUSTOM' ? (
                <div className={styles.itemRow3}>
                  <Tooltip
                    title={
                      <div className={styles.itemFilter}>
                        <span>Filter: </span>
                        {(item?.renderContent || []).map((content, contentIndex) => (
                          <span key={contentIndex} className={styles.content}>
                            {content}
                          </span>
                        ))}
                      </div>
                    }
                  >
                    <div className={styles.itemFilter}>
                      <span>Filter: </span>
                      {(item?.renderContent || []).map((content, contentIndex) => (
                        <span key={contentIndex} className={styles.content}>
                          {content}
                        </span>
                      ))}
                    </div>
                  </Tooltip>

                  <div className={styles.btns}>
                    <Tooltip title={intl.formatMessage({ id: '邮件通知编辑' })}>
                      <TongyongBianji
                        onClick={() => {
                          setModalVisible(true);
                          setEditData(item);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title={intl.formatMessage({ id: '邮件通知删除' })}>
                      <TongyongShanchu onClick={() => delNotification(item.id, item.siteId)} />
                    </Tooltip>
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
        <NewFeedModal
          visible={modalVisible}
          editData={editData}
          defualtName={`${userInfo?.firstName}${userInfo?.lastName}' feed${list.length + 1}`}
          setVisible={(visible, update) => {
            setModalVisible(visible);
            setEditData(undefined);
            update && getEmailsNotice();
          }}
        />
      </div>
    </ConfigProvider>
  );
};

export default EmailNotificatons;
