import { useStore } from '@system-global/store';
import styles from './index.module.scss';
import { useMemo } from 'react';
import { intl } from '@system-global/lang';
import { IApiBusinessPackageResp } from '@api/system-global/__generate__/default/models';
import classNames from 'classnames';
import { QuotaType } from '@system-global/config/types';
import PackageLogo from './component/package-logo';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface DataType {
  key: string;
  label: string;
  render?: (value: string, record?: IApiBusinessPackageResp) => React.ReactNode;
}

const FieldItem = ({ value, info }: { value: string; info: DataType }) => {
  const { label } = info || {};

  const renderField = useMemo(() => {
    return <div className={classNames(styles.textVal)}>{info.render ? info.render(value) : value}</div>;
  }, [info, value]);

  return (
    <div className={styles.filedItem}>
      <div className={styles.textKey}>{label}</div>
      {renderField}
    </div>
  );
};
const Subscriptions = () => {
  const packageInfo = useStore(state => state.user_packageInfo);
  const userInfo = useStore(state => state.user_userInfo);
  const navigate = useNavigate();

  const filedInfo: DataType[] = useMemo(() => {
    return [
      {
        label: intl.formatMessage({ id: '开始时间' }),
        key: 'orderEffectiveDate',
        render: text => {
          const formattedDate = dayjs(text).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MMM D, YYYY');
          return <span>{formattedDate}</span>;
        },
      },
      {
        label: intl.formatMessage({ id: '结束时间' }),
        key: 'orderExpireDate',
        render: text => {
          const formattedDate = dayjs(text).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MMM D, YYYY');
          return <span>{formattedDate}</span>;
        },
      },
      {
        label: intl.formatMessage({ id: '访问过的公司' }),
        key: 'companyCntInOrder',
        render: text => {
          return <>{text} companies</>;
        },
      },
      {
        label: intl.formatMessage({ id: '订购状态' }),
        key: 'businessPackageStatus',
        render: text => {
          return <span className={styles.status}>{text === 'EFFECTIVE' ? 'Effective' : 'Expired'}</span>;
        },
      },
      {
        label: intl.formatMessage({ id: '过去30天的公司' }),
        key: 'companyCntLast30Days',
        render: text => {
          return <>{text} companies</>;
        },
      },
      {
        label: intl.formatMessage({ id: '旗舰价格' }),
        key: 'recommendProductPreMonth',
        render: () => {
          return (
            <div className={styles.priceWrap}>
              {packageInfo?.recommendProductPreMonth?.amount === -1 ? (
                <span>-</span>
              ) : (
                <>
                  <div className={styles.price}>
                    {packageInfo?.recommendProductPreMonth?.currency === 'usd' ? <span>$</span> : <span></span>}
                    {(packageInfo?.recommendProductPreMonth?.amount || 0) / 100}
                  </div>
                  <span> Monthly</span>
                </>
              )}
            </div>
          );
        },
      },
    ];
  }, [packageInfo?.recommendProductPreMonth?.amount, packageInfo?.recommendProductPreMonth?.currency]);

  return (
    <div className={styles.container}>
      <section>Subscriptions</section>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.siteName}>
            <div className={styles.title}>{userInfo?.companyName ?? '-'}</div>
            <PackageLogo packageInfo={packageInfo} />
          </div>
          <div className={styles.fileds}>
            {filedInfo.map((f, idx) => {
              const val = (packageInfo as never)?.[f.key];
              if (val === null) return null;
              return <FieldItem key={idx} info={f} value={val} />;
            })}
            {packageInfo?.recommendProductPreMonth &&
              packageInfo.recommendProductPreMonth?.amount !== null &&
              packageInfo.recommendProductPreMonth?.amount >= 0 && (
                <Button
                  style={{ backgroundColor: '#9B59B6', fontWeight: 500 }}
                  onClick={() => navigate(ROUTE_URLS.purchase, { replace: false })}
                  type="primary"
                >
                  {intl.formatMessage({ id: '订单标题' })}
                </Button>
              )}
          </div>
        </div>
        {!!packageInfo?.quotas?.length && (
          <div className={styles.bottom}>
            <div className={styles.fildeName}>Current month limit:</div>
            {packageInfo?.quotas.map((p, idx) => (
              <div className={styles.wrap} key={idx}>
                <label>{p.quotaName === QuotaType.CONTACT_REVEAL_QUOTA ? 'Contact' : 'Companies'}</label>
                <span>
                  {p.used}/{p.total}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Subscriptions;
