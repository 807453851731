import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { MediaIcon } from '@ui/components/media-icon';
import { CountryFlag } from '@ui/components/country-flag';
import { IApiVisitorDetailResp } from '@api/system-global/__generate__/default/models';
import Circle from '@feature/assets/icons/circle.svg';
import User from '@feature/assets/icons/user2.svg';
import { AccessList } from './access-list';
import styles from './style.module.scss';

export interface Props {
  data: IApiVisitorDetailResp;
  showDate: boolean;
  visiterName: string;
}
export const VisitRecordItem: React.FC<Props> = props => {
  const { data, showDate, visiterName } = props;

  const dateDisplay = useMemo(() => {
    if (!data?.startTime) {
      return {
        date: '--/--',
        year: '----',
        time: '--',
      };
    }

    return {
      date: dayjs(data.startTime).format('MM/DD'),
      year: dayjs(data.startTime).format('YYYY'),
      time: dayjs(data.startTime).format('HH:mm'),
    };
  }, [data?.startTime]);

  const pageCount = useMemo(() => {
    const urls = data?.visitorDetails?.map(item => item.url) || [];
    return [...new Set(urls)].length;
  }, [data?.visitorDetails]);

  return (
    <div className={styles.visitRecord}>
      <div className={styles.index}>
        <div className={classnames(styles.date, !showDate ? styles.hidden : '')}>
          <div>{dateDisplay.date}</div>
          <div>{dateDisplay.year}</div>
        </div>
      </div>
      <div className={styles.line}>
        <img src={Circle} alt="" />
      </div>
      <div className={styles.recordInfo}>
        <div className={styles.recordTitle}>
          <span className={styles.visitor}>
            <img src={User} alt="" />
            <FormattedMessage
              id={`访客#访问了#个页面${pageCount < 2 ? '单数' : ''}`}
              values={{ name: visiterName, count: pageCount }}
            />
          </span>
          <i className={styles.divider} />
          <span>
            <FormattedMessage id="访问来源" />：
            <MediaIcon type={data.sourceType} border size={24} />
          </span>
          <i className={styles.divider} />
          <span>
            <FormattedMessage id="访问地区" />：
            <CountryFlag code={data?.userCountry || ''} size={14} />
            <a
              className={styles.location}
              href={`https://maps.google.com?q=${encodeURIComponent(`${data.userCity} ${data.userCountry}`)}&mrt=loc`}
              target="_blank"
              rel="noreferrer"
            >
              {data?.userCountry || '--'}
            </a>
          </span>
        </div>
        <div className={styles.recordTime}>
          <FormattedMessage id="进入时间" />：{dateDisplay.time}
        </div>
        <AccessList data={data?.visitorDetails || []} />
      </div>
    </div>
  );
};
