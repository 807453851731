import { useState } from 'react';
import { VisitorDashboardUi } from '@feature/components/visitor-dashboard';
import { VisitorList } from '@feature/components/visitor-list';
import { useRequest } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { pageAllPost, statisticsPost } from '@api/system-global/__generate__/default/VisitorPageService';
import { IApiCompanyPageReq } from '@api/system-global/__generate__/default/models';
import styles from './index.module.scss';
import { useUpgradeVisible } from './hooks/use-uprgade-visible';
import { UpgradeReminderCard } from '@feature/components/visitor-list/upgrade-reminder';
import { reachLimitStatisticsGet } from '@api/system-global/__generate__/default/UserBizService';
import { FormattedMessage } from 'react-intl';
import { useStore } from '@system-global/store';
import CompanyDetail from '@system-global/components/company-detail';
import { CompanyDetailTabsType } from '@api/system-global/override/GlobalSearch';
import { ROUTE_URLS } from '@system-global/config/route-urls';

const getVisitorList = (params: IApiCompanyPageReq) => {
  return pageAllPost({
    pageNo: 1,
    pageSize: 10,
    ...params,
  }).then(res => res.data.data);
};

const AllVisitorList = () => {
  const { upgradeVisible, status: upgradeStatus, paidBefore } = useUpgradeVisible();
  const [curDomain, setCurDomain] = useState('');
  const [openDefaultActiveTable, setOpenDefaultActiveTable] = useState<CompanyDetailTabsType>(
    CompanyDetailTabsType.OVERVIEW
  );
  const navigate = useNavigate();

  const { data } = useRequest(() => {
    return reachLimitStatisticsGet().then(res => res.data.data);
  });
  // const packageInfo = useStore(state => state.user_packageInfo);
  const siteId = useStore(state => state.user_currentBindSite?.siteId);
  const userInfo = useStore(state => state.user_userInfo);
  const { data: statisticsData } = useRequest(() => {
    return statisticsPost().then(res => res.data.data);
  });
  return (
    <div className={styles.container}>
      {upgradeVisible ? (
        <UpgradeReminderCard
          status={upgradeStatus}
          paidBefore={paidBefore}
          companyInfo={data}
          // companyInfo={{
          //   ...data,
          //   companyNames: ['腾讯', '网易'],
          //   companyCountries: ['中国', '美国'],
          // }}
          jumpToPurchasePage={() => {
            navigate(ROUTE_URLS.purchase);
          }}
        />
      ) : (
        <VisitorDashboardUi data={statisticsData} />
      )}

      <VisitorList
        initialEmail={userInfo?.email}
        isNewVisitorList={false}
        title={<FormattedMessage id="访客列表-标题-访问的公司" />}
        siteId={siteId ?? ''}
        initialSearchParamsFlag="lastWeek"
        getVisitorList={getVisitorList}
        onCompanyClick={(data, activeTab) => {
          setCurDomain(data?.domain || '');
          setOpenDefaultActiveTable(activeTab ?? CompanyDetailTabsType.OVERVIEW);
        }}
      />
      <CompanyDetail domain={curDomain} onClose={() => setCurDomain('')} defaultActiveTab={openDefaultActiveTable} />
    </div>
  );
};

export default AllVisitorList;
