import { CompanyDetailTabsType, GlobalSearchType } from '@api/system-global/override/GlobalSearch';
import VisitorDetail from '@feature/components/visitor-detail';
import { TongyongGuanbiXian } from '@sirius/icons';
import { useUpgradeVisible } from '@system-global/pages/all-visitor-list/hooks/use-uprgade-visible';
import { useStore } from '@system-global/store';
import { Drawer, DrawerProps } from 'antd';

interface DetailProps extends DrawerProps {
  domain: string;
  onClose?: () => void;
  defaultActiveTab?: keyof typeof CompanyDetailTabsType;
  globalSearchType?: keyof typeof GlobalSearchType;
}
const CompanyDetail: React.FC<DetailProps> = props => {
  const { domain, onClose, defaultActiveTab, globalSearchType, ...rest } = props;
  const siteId = useStore(state => state.user_currentBindSite?.siteId);
  const { contactUsedUp } = useUpgradeVisible();
  const fetchUserInfo = useStore(state => state.user_fetchUserInfo);

  const refreshStm = () => {
    // 解锁联系人后，更新用户额度信息
    fetchUserInfo({ refresh: true });
  };
  return (
    <Drawer
      width={880}
      zIndex={10001}
      push={false}
      placement="right"
      closable={false}
      onClose={onClose}
      open={!!domain}
      styles={{ body: { padding: 0 } }}
      extra={<TongyongGuanbiXian onClick={onClose} style={{ fontSize: 20, color: '#6F7485' }} />}
      {...rest}
    >
      <VisitorDetail
        domain={domain}
        onClose={onClose}
        siteId={siteId ?? ''}
        expired={contactUsedUp}
        defaultActiveTab={defaultActiveTab}
        globalSearchType={globalSearchType}
        refreshStm={refreshStm}
      />
    </Drawer>
  );
};

export default CompanyDetail;
