import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { getCompanyText } from './getCompanyText';
import defaultDark from '../../assets/icons/company-avatar-default-dark.svg';
import defaultLight from '../../assets/icons/company-avatar-default-dark.svg';

interface Props {
  size?: number;
  name?: string;
  className?: string;
  mode?: 'dark' | 'light';
  textStyle?: React.CSSProperties;
}
const CompanyAvatar: React.FC<Props> = props => {
  const { size = 66, name = '', mode, textStyle, className } = props;

  const renderContent = useMemo(() => {
    if (!name) {
      return <img width={size} height={size} src={mode === 'dark' ? defaultDark : defaultLight} alt="company" />;
    } else {
      return (
        <div
          className={styles.back}
          style={{
            width: size - 2,
            height: size - 2,
            backgroundColor: mode === 'light' ? '#EBEDF2' : '#000000',
          }}
        >
          <div className={styles.text} style={{ ...textStyle, color: mode === 'light' ? '#000000' : '#FFEB5C' }}>
            {getCompanyText(name)}
          </div>
        </div>
      );
    }
  }, [mode, name, size, textStyle]);
  return (
    <div
      className={classNames(styles.wrapper, className)}
      style={{
        width: size,
        height: size,
        backgroundColor: mode === 'light' ? '#EBEDF2' : '#000000',
      }}
    >
      {renderContent}
    </div>
  );
};

export default CompanyAvatar;
