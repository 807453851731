import { axiosInstance as appAxios } from '@api/system-global/request';
import { genTokenReqInterceptor, genErrorResInterceptor, genUrlPrefixReqInterceptor } from '@utils/request';
import { useStore } from '@system-global/store';
import { router } from '@system-global/config/routes';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { intl } from '@system-global/lang';

const urlPrefixReqInterceptor = genUrlPrefixReqInterceptor({ prefix: '/sirius' });

const tokenRequestHandler = genTokenReqInterceptor({ store: useStore });

const errorResponseHandler = genErrorResInterceptor({
  noErrorHint: false,
  onLogout: () => {
    useStore.getState().global_doLogout(true);
    setTimeout(() => {
      router.navigate(ROUTE_URLS.login);
    }, 1500);
  },
  errorTip: intl.formatMessage({ id: '网络错误' }),
  timeoutTip: intl.formatMessage({ id: '网络超时' }),
  accessErrorTip: intl.formatMessage({ id: '登录过期' }),
});

// 请求拦截器
appAxios.interceptors.request.use(tokenRequestHandler.onFulfilled, tokenRequestHandler.onRejected);

appAxios.interceptors.request.use(urlPrefixReqInterceptor.onFulfilled, urlPrefixReqInterceptor.onRejected);

// 响应拦截器
appAxios.interceptors.response.use(errorResponseHandler.onFulfilled, errorResponseHandler.onRejected);

export { appAxios };
