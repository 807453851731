import { useMemo } from 'react';
import { useStore } from '@system-global/store';
import { UpgradeReminderStatus } from '@feature/components/visitor-list/upgrade-reminder';

interface UpgradeStatusResult {
  /**
   * true 展示升级提示
   */
  upgradeVisible: boolean;
  /**
   * 联系人额度是否用完
   */
  contactUsedUp?: boolean;
  /**
   * 如果upgradeVisible为true，此字段表示提示的状态
   */
  status?: UpgradeReminderStatus;
  /**
   * 如果upgradeVisible为true,
   * 在首页升级卡片里，会展示推荐套餐下可以获取到访客数量的范围
   * * false 试用版过期或额度用完，推荐的访客数量为0 - 400
   * * true  购买过的过期或额度用完，推荐的访客数量为 400 -1000
   * @description
   * 这个字段只用于列表页升级卡片中推荐访客数量逻辑使用
   */
  paidBefore: boolean;
}

/**
 * 用于判断是否展示升级提示
 * @returns
 */
export const useUpgradeVisible = (): UpgradeStatusResult => {
  const userInfo = useStore(state => state.user_userInfo);

  const upgradeVisible = useMemo<UpgradeStatusResult>(() => {
    //  const contactUsedUp = userInfo?.userOrderStatusResp?.currentVersion?.
    const paidBefore = !!userInfo?.userOrderStatusResp.paidBefore;
    const versionExpire = userInfo?.userOrderStatusResp?.versionExpire;
    if (!userInfo || userInfo?.userOrderStatusResp?.officialUser) {
      // 如果版本过期 则默认联系人不可用
      const contactUsedUp = versionExpire
        ? true
        : userInfo?.quotaCountInfoList?.some(item => item?.quotaName === 'CONTACT_REVEAL_QUOTA' && item?.left <= 0);
      return {
        upgradeVisible: false, // 已经付费用户不展示升级
        contactUsedUp,
        paidBefore,
      };
    }

    if (userInfo.userOrderStatusResp.trialDaysLeft <= 0) {
      return {
        upgradeVisible: true,
        contactUsedUp: versionExpire, // 未付费用户不限制联系人解锁数, 只限制过期
        paidBefore,
        status: 'expired',
      };
    }

    if (userInfo.quotaCountInfoList.some(item => item.quotaName === 'IP_IDENTIFY_QUOTA' && item.left <= 0)) {
      return {
        upgradeVisible: true,
        contactUsedUp: versionExpire, // 未付费用户不限制联系人解锁数, 只限制过期
        paidBefore,
        status: 'usedUp',
      };
    }

    return {
      upgradeVisible: false,
      contactUsedUp: versionExpire, // 未付费用户不限制联系人解锁数, 只限制过期
      paidBefore,
    };
  }, [userInfo]);

  return upgradeVisible;
};
