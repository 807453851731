import React, { useCallback, useRef, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { InfiniteScroller } from '@ui/components/infinite-scroll';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import { visitorDetailListGet } from '@api/system-global/__generate__/default/VisitorDetailInfoService';
import { IApiVisitorDetailResp } from '@api/system-global/__generate__/default/models';
import { Overview } from './overview';
import { VisitRecordItem } from './visit-record';
import { ExpiredCard } from '../expired-card';
import styles from './style.module.scss';

export interface Props {
  domain: string;
  siteId: string;
}
interface VisitRecordItem extends IApiVisitorDetailResp {
  showDate: boolean;
  visiterName: string;
}
export const VisitState: React.FC<Props> = props => {
  const { domain, siteId } = props;
  const [visitRecords, setVisitRecords] = useState<Array<VisitRecordItem>>([]);
  const [hasMore, setHasMore] = useState(true);
  const [showExpired, setShowExpired] = useState(false);
  const loading = useRef(false);
  const visiterRecordMap = useRef({
    no: 0,
    map: new Map<string, number>(),
  });
  const [page, setPage] = useState(0);

  const searchDate = useMemo(() => {
    return String(+new Date());
  }, []);

  /**
   * 聚合日期，处理访客名称
   * UI展示同一天的访问记录，只展示一个日期，后端未聚合，每条数据都有一个日期
   * 同一个clientId算一个访客
   * TODO: 数据量大时待优化, 增量聚合
   */
  const parseDate = useCallback((list: IApiVisitorDetailResp[]): VisitRecordItem[] => {
    let preDate = '';
    const result: VisitRecordItem[] = [];
    for (let i = 0; i < list.length; i++) {
      const dateStr = dayjs(list[i].startTime).format('YYYY-MM-DD');
      const newItem = { ...list[i], showDate: true, visiterName: '' };

      // 同一个clientId算一个访客
      if (visiterRecordMap.current.map.has(newItem.clientId)) {
        newItem.visiterName = visiterRecordMap.current.map.get(newItem.clientId)?.toString() || '';
      } else {
        visiterRecordMap.current.no++;
        visiterRecordMap.current.map.set(newItem.clientId, visiterRecordMap.current.no);
        newItem.visiterName = visiterRecordMap.current.no.toString();
      }

      if (dateStr === preDate) {
        newItem.showDate = false;
      } else {
        preDate = dateStr;
      }
      result.push(newItem);
    }
    return result;
  }, []);

  const loadRecords = useCallback(async () => {
    if (loading.current) {
      return;
    }
    try {
      loading.current = true;
      const res = await visitorDetailListGet({ domain, page, pageSize: 20, siteId, searchDate });
      setShowExpired(res?.data?.data?.expiredAfterDays);
      setVisitRecords(parseDate([...visitRecords, ...(res?.data?.data?.visitorDetailRespPage?.records || [])]));
      setHasMore(page + 1 < res?.data?.data?.visitorDetailRespPage?.pages);
      setPage(page + 1);
    } finally {
      loading.current = false;
    }
  }, [domain, siteId, visitRecords, loading, page, searchDate, parseDate]);

  return (
    <div className={styles.wrapper}>
      <Overview domain={domain} siteId={siteId} />
      <div className={styles.title}>
        <FormattedMessage id="访问记录" />
      </div>
      {showExpired ? (
        <ExpiredCard />
      ) : (
        <div>
          <div>
            {visitRecords.map((record, index) => (
              <VisitRecordItem key={index} data={record} visiterName={record.visiterName} showDate={record.showDate} />
            ))}
          </div>
          <InfiniteScroller
            fetchNextPage={loadRecords}
            hasNextPage={hasMore}
            loadingMessage={
              <div className={styles.loader}>
                <Spin indicator={<LoadingOutlined spin color="" />} size="default" />
              </div>
            }
            endingMessage={<div className="loader"></div>}
          />
        </div>
      )}
    </div>
  );
};
