/** 用户注册 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 用户注册 邮箱注册 */
export async function emailSignUpPost(payload: model.IApiEmailSignupReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/pub/user/signup/email`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 用户注册 邮箱注册校验 */
export async function emailSignUpCheckPost(payload: model.IApiEmailSignupCheckReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultEmailSignUpCheckResp>({
    url: `/api/pub/user/signup/email/check`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 用户注册 OAuth注册 */
export async function oAuthSignUpPost(payload: model.IApiOAuthSignupReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultOAuthSignUpResp>({
    url: `/api/pub/user/signup/oauth`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
