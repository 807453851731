import React, { useMemo, useCallback } from 'react';
import { Button } from 'antd';
import { useLocalStorageState } from 'ahooks';
import { FormattedMessage } from 'react-intl';
import GoogleIcon from '@system-global/assets/icons/google.svg?react';
import styles from './style.module.scss';

interface Props {
  type: 'login' | 'register';
}

export const GoogleOauthStateKey = 'GoogleOauthStateKey';

export const GoogleLoginBtn: React.FC<Props> = props => {
  const [_, setAuthState] = useLocalStorageState(GoogleOauthStateKey);

  const googleLoginUrl = useMemo(() => {
    const googleOAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const clientId = encodeURIComponent('816743693939-r8dmrdn7p5qltvmlq54lotnb6ukd15v6.apps.googleusercontent.com');
    const redirectUri = encodeURIComponent(`${window.location.origin}/oauth/google/handle`);
    const scope = encodeURIComponent(
      'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid'
    );
    return `${googleOAuthUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&prompt=select_account`;
  }, []);

  const gotoGoogleOAuth = useCallback(() => {
    const newState = Math.random().toString(36).substring(2) + new Date().getTime();
    setAuthState(newState);
    window.location.href = `${googleLoginUrl}&state=${newState}`;
  }, [googleLoginUrl, setAuthState]);

  return (
    <Button className={styles.button} block onClick={gotoGoogleOAuth}>
      <GoogleIcon />
      <span className={styles.text}>
        <FormattedMessage id={props.type === 'register' ? 'Google登录注册' : 'Google登录'} />
      </span>
    </Button>
  );
};
