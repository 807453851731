import React, { ReactNode } from 'react';
import classnames from 'classnames';
import style from './index.module.scss';

interface OptionSeparatorProps {
  className?: string;
  separator?: ReactNode;
}

const OptionSeparator: React.FC<React.PropsWithChildren<OptionSeparatorProps>> = props => {
  const { className, separator = <span className={style.separator} />, children } = props;
  const length = React.Children.count(children);

  return (
    <div className={classnames(style.optionSeparator, className)}>
      {React.Children.map(children, (child, index) => {
        let enhancedChild = child;

        if (React.isValidElement(child)) {
          enhancedChild = React.cloneElement(child as React.ReactElement, {
            className: classnames(child.props.className, style.option),
            key: index,
          });
        }

        return (
          <React.Fragment key={`separator-${index}`}>
            {enhancedChild}
            {index !== length - 1 ? separator : null}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default OptionSeparator;
