import { useState } from 'react';
import { Divider } from 'antd';
import styles from './styles.module.scss';
import LoginContainer from '@system-global/components/login-and-register-form/login-container';
import { emailSignUpPost } from '@api/system-global/__generate__/default/UserSignupService';
import { IApiEmailSignupReq } from '@api/system-global/__generate__/default/models';
import EmailAndPwdForm, { IEmailAndPwdFieldType } from '@system-global/pages/register/components/email-and-pwd-form';
import AccountForm, { IAccountFieldType } from '@system-global/pages/register/components/account-form';
import { useNavigate } from 'react-router-dom';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { useStore } from '@system-global/store';
import { GoogleLoginBtn } from '@system-global/components/google-login-button';

type IFormType = 'emailAndPwd' | 'account';

const RegisterPage = () => {
  const nav = useNavigate();

  const [loading, setLoading] = useState(false);
  const [formType, setFormType] = useState<IFormType>('emailAndPwd');
  const saveRegisterInfo = useStore(state => state.login_setRegisterInfo);

  const [registerInfo, setRegisterInfo] = useState<IApiEmailSignupReq>({
    companyName: '',
    email: '',
    firstName: '',
    lastName: '',
    pwd: '',
    website: '',
  });

  const onEmailAndPwdSubmit = ({ email, pwd }: IEmailAndPwdFieldType) => {
    setRegisterInfo(v => ({
      ...v,
      email,
      pwd,
    }));
    setFormType('account');
  };

  const onAccountSubmit = ({ companyName, firstName, lastName, website }: IAccountFieldType) => {
    const req: IApiEmailSignupReq = {
      ...registerInfo,
      companyName,
      firstName,
      lastName,
      website,
    };
    setRegisterInfo(req);
    setLoading(true);
    emailSignUpPost(req)
      .then(() => {
        saveRegisterInfo({ email: req.email, sendTime: Date.now() });
        setTimeout(() => {
          nav(ROUTE_URLS.verifyEmail);
        }, 500);
      })
      .catch(e => {
        console.error(e, 'emailSignUpPost error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.registerPage}>
      <LoginContainer>
        <div className={styles.content}>
          {formType === 'emailAndPwd' && <EmailAndPwdForm registerInfo={registerInfo} onSubmit={onEmailAndPwdSubmit} />}
          {formType === 'account' && (
            <AccountForm
              registerInfo={registerInfo}
              loading={loading}
              onSubmit={onAccountSubmit}
              onGoBack={() => setFormType('emailAndPwd')}
            />
          )}
          <div>
            <Divider>OR</Divider>
            <GoogleLoginBtn type="register" />
          </div>
        </div>
      </LoginContainer>
    </div>
  );
};

export default RegisterPage;
