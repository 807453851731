import React from 'react';
import { Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import { onValidBlank } from '@system-global/utils/validator';
import PasswordInput from '../password-input';

interface ICompProps {
  type?: string;
  name: string;
  field: string;
  label?: string | React.ReactNode;
  maxLength?: number;
  addonBefore?: string;
  className?: string;
  onPressEnter?: () => void;
}

type IFieldType = Record<ICompProps['name'], string>;

const RequiredFormItem: React.FC<ICompProps> = ({
  type,
  name,
  label,
  maxLength,
  addonBefore,
  field,
  className,
  onPressEnter,
}) => {
  const intl = useIntl();

  return (
    <Form.Item<IFieldType>
      name={name}
      className={className}
      labelCol={{ span: 24 }}
      label={label ? (typeof label == 'string' ? label.toUpperCase() : label) : field.toUpperCase()}
      rules={[
        {
          validator(rule, value) {
            return onValidBlank(value, intl.formatMessage({ id: '字段不能为空' }, { field }), type !== 'pwd');
          },
        },
      ]}
    >
      {type === 'pwd' ? (
        <PasswordInput onPressEnter={onPressEnter} />
      ) : (
        <Input
          variant="filled"
          addonBefore={addonBefore}
          maxLength={maxLength || 50}
          size="large"
          allowClear
          onPressEnter={onPressEnter}
        />
      )}
    </Form.Item>
  );
};

export default RequiredFormItem;
