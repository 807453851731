import React from 'react';
import { useRequest } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { reachLimitStatisticsGet } from '@api/system-global/__generate__/default/UserBizService';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import { UpgradeReminderCard } from '@feature/components/visitor-list/upgrade-reminder';
import { useUpgradeVisible } from '../../pages/all-visitor-list/hooks/use-uprgade-visible';

export interface UpgradeCardProps {
  className?: string;
}
export const UpgradeCard: React.FC<UpgradeCardProps> = props => {
  const { className } = props;
  const { upgradeVisible, status: upgradeStatus, paidBefore } = useUpgradeVisible();
  const navigate = useNavigate();
  const { data: companyInfo } = useRequest(() => {
    return reachLimitStatisticsGet().then(res => res.data.data);
  });
  if (upgradeVisible) {
    return (
      <UpgradeReminderCard
        className={className}
        status={upgradeStatus}
        companyInfo={companyInfo}
        paidBefore={paidBefore}
        jumpToPurchasePage={() => {
          navigate(ROUTE_URLS.purchase);
        }}
      />
    );
  }
  return null;
};
