import React from 'react';
import { Input as AntInput, InputProps } from 'antd';
import cn from 'classnames';

import styles from './index.module.scss';
/**
 *
 * @param props
 * @param props.size
 *  * large 高度为 40px
 * @returns
 */
export const Input: React.FC<InputProps> = props => {
  return <AntInput {...props} className={cn(styles['input'], props.className)} />;
};
