import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { useSessionStorageState } from 'ahooks';
import { formatNumber } from '@utils/common';
import { ROUTE_URLS } from '@system-global/config/route-urls';
import MaskLoading from '@ui/components/mask-loading';
import { createPayOrderPost, checkOrderStatusGet } from '@api/system-global/__generate__/default/ProductService';
import { IApiProductResp, IApiProductModelResp } from '@api/system-global/__generate__/default/models';
import styles from './card.module.scss';

interface Props {
  data?: IApiProductResp;
}
const OrderCard = (props: Props) => {
  const { data } = props;
  const [showMask, setShowMask] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const intervalRef = useRef<number>();
  const [currentModel, setCurrentModel] = useState<IApiProductModelResp | null>();
  const [_, setPaymentState] = useSessionStorageState('LeadsNaviPurchaseState');

  const orderDuration = useMemo(() => {
    const start = currentModel?.effectDate || '';
    const end = currentModel?.expireDate || '';
    return {
      start: +start ? dayjs(start).format('MMM D, YYYY') : '',
      end: +end ? dayjs(end).format('MMM D, YYYY') : '',
    };
  }, [currentModel?.effectDate, currentModel?.expireDate]);

  const priceInfo = useMemo(() => {
    const amount = currentModel?.currentPriceTotal?.amount || 0;
    const originAmount = currentModel?.originPriceTotal?.amount || 0;
    return {
      current: formatNumber(+(amount / 100).toFixed(2)),
      origin: formatNumber(+(originAmount / 100).toFixed(2)),
    };
  }, [currentModel]);

  const checkVerifyState = useCallback(
    async (orderId: string) => {
      try {
        const res = await checkOrderStatusGet({ payOrderId: orderId });
        if (res?.data?.data?.payOrderStatus === 'SUCCESS') {
          // 支付成功
          setPaymentState((pre: Record<string, IApiProductModelResp>) => ({
            ...pre,
            [orderId]: { ...currentModel, productName: data?.productName },
          }));
          window.location.replace(ROUTE_URLS.purchaseResult.replace(':orderId', String(orderId)));
          setShowMask(false);
          return;
        }

        if (['FAILED', 'CLOSED', 'REFUND'].includes(res?.data?.data?.payOrderStatus)) {
          // 支付失败
          window.location.replace(ROUTE_URLS.purchaseError);
          setShowMask(false);
          return;
        }

        // 继续轮询
        intervalRef.current = window.setTimeout(() => checkVerifyState(orderId), 4000);
      } catch {
        // 接口失败，继续轮询
        intervalRef.current = window.setTimeout(() => checkVerifyState(orderId), 4000);
      }
    },
    [currentModel, setPaymentState, data]
  );

  const pay = useCallback(async () => {
    try {
      setPayLoading(true);
      // 调接口前先打开blank页面，避免被浏览器拦截
      const newWin = window.open(ROUTE_URLS.blank, '_blank');
      const res = await createPayOrderPost({
        productId: data?.productId,
        productModelId: currentModel?.productModeId,
      });

      const orderId = res?.data?.data?.orderId || '';
      const redirectUrl = res?.data?.data?.redirectUrl || '';
      if (redirectUrl) {
        // 替换页面链接为支付链接
        newWin && (newWin.location.href = redirectUrl);
        setShowMask(true);
        checkVerifyState(orderId);
      }
    } catch {
      setPayLoading(false);
    }
  }, [data?.productId, currentModel, checkVerifyState]);

  useEffect(() => {
    setCurrentModel(data?.productModels?.[0] || null);
  }, [data]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.priceCard}>
        <div className={styles.priceType}>{data?.productName || ''}</div>
        <div className={styles.tabs}>
          {data?.productModels?.map(item => (
            <div
              key={item.productModeId}
              className={classnames(
                styles.tab,
                currentModel?.productModeId === item.productModeId ? styles.tabActive : ''
              )}
              onClick={() => setCurrentModel(item)}
            >
              <FormattedMessage id={item.effectiveUnit === 'YEAR' ? '订单年付' : '订单月付'} />
              {Boolean(item.off) && <span>（{+item.off}% off）</span>}
            </div>
          ))}
        </div>
        {Boolean(currentModel) && (
          <>
            <div className={styles.orderTip}>
              <FormattedMessage id="订单期限" values={{ start: orderDuration.start, end: orderDuration.end }} />
            </div>
            <div className={styles.orderPrice}>
              <div className={styles.priceLabel}>
                <FormattedMessage id="订单价格" />
              </div>
              <div className={styles.priceNum}>
                <span>$</span>
                <span>{priceInfo.current}</span>
                {Boolean(currentModel?.off) && (
                  <div className={styles.originPriceNum}>
                    <span className={styles.lineThrough}>${priceInfo.origin}</span>
                    <div className={styles.priceTag}>
                      <FormattedMessage id="折扣XX" values={{ discount: Number(currentModel?.off) }} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.action}>
              <Button type="primary" className={styles.purchaseBtn} loading={payLoading} onClick={pay}>
                <FormattedMessage id="购买" />
              </Button>
            </div>
          </>
        )}

        <div className={styles.proto}>
          <FormattedMessage id="订单协议" />
        </div>
      </div>

      {showMask && (
        <MaskLoading mask>
          <FormattedMessage id="等待支付结果" />
        </MaskLoading>
      )}
    </div>
  );
};

export default OrderCard;
