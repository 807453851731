/** 联系人相关 */
import { axiosInstance as axios } from '../../request';
import { IAxiosCustomParams } from '@api/request';
import type * as model from './models';

/** 联系人相关 导出联系人信息（异步） */
export async function contactExportPost(payload: model.IApiExportContactReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/biz/contact/export`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 联系人相关 解锁联系人列表分页 */
export async function contactPageAllPost(payload: model.IApiContactPageReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultPageRespContactPageItemResp>({
    url: `/api/biz/contact/pageAll`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}

/** 联系人相关 解锁联系人 */
export async function revealContactPost(payload: model.IApiContactRevealReq, customParams?: IAxiosCustomParams) {
  const data = payload;

  const result = await axios.request<model.IApiJsonResultboolean>({
    url: `/api/biz/contact/reveal`,
    method: 'post',
    data,
    customParams,
    headers: { 'Content-Type': 'application/json' },
  });

  return result;
}
