import React from 'react';
import classnames from 'classnames';
import * as IconMap from './icons';
import styles from './style.module.scss';

interface Props {
  type: string;
  style?: React.CSSProperties;
  size?: number;
  border?: boolean;
  className?: string;
  onClick?: () => void;
}
export const MediaIcon: React.FC<Props> = props => {
  const { type, className, size = 20, border = false, ...rest } = props;

  const Icon = IconMap[type as keyof typeof IconMap] || IconMap.DEFAULT;
  const finalSize = border ? size - 2 : size;

  return (
    <span className={classnames(styles.icon, className, border ? styles.hasBorder : '')} {...rest}>
      {React.cloneElement(Icon, { style: { width: finalSize, height: finalSize } })}
    </span>
  );
};
