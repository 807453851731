import React, { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import { useStore } from '@system-global/store';
import { IApiUserInfoResp } from '@api/system-global/__generate__/default/models';
import ResetPwdModal from '@system-global/pages/personal-setting/components/resetPwdModal';
import DefaultAvatarImage from '@system-global/assets/images/default-avatar.png';
import styles from './styles.module.scss';

const SETTING_CONFIG: Array<{ key: keyof IApiUserInfoResp; label: React.ReactElement }> = [
  { key: 'firstName', label: <FormattedMessage id="姓" /> },
  { key: 'lastName', label: <FormattedMessage id="名" /> },
  { key: 'email', label: <FormattedMessage id="邮箱" /> },
  { key: 'companyName', label: <FormattedMessage id="公司名称" /> },
  { key: 'website', label: <FormattedMessage id="网址" /> },
];

const PersonalSettingPage = () => {
  const userInfo = useStore(state => state.user_userInfo);

  const [modalVisible, setModalVisible] = useState(false);

  const userIdentityType = useMemo(() => {
    if (userInfo?.identityType === 'OAUTH_GOOGLE') {
      return 'google';
    }

    if (userInfo?.identityType === 'OAUTH_FACEBOOK') {
      return 'facebook';
    }
    return '';
  }, [userInfo]);

  return (
    <div className={styles.personalSettingPage}>
      <div className={styles.titleWrapper}>
        <p className={styles.title}>
          <FormattedMessage id="个人信息" />
        </p>
        <p className={styles.subTitle}>
          <FormattedMessage id="上述详情仅对您可见" />
        </p>
      </div>
      <div className={styles.avatarWrapper}>
        <div className={styles.avatarTitle}>
          <FormattedMessage id="个人头像" />
        </div>
        <img className={styles.avatar} alt="avatar" src={DefaultAvatarImage} />
      </div>
      <div className={styles.settingContent}>
        {SETTING_CONFIG.map(v => (
          <div className={styles.settingItem} key={v.key}>
            <p className={styles.settingItemTitle}>{v.label}</p>
            <p className={styles.settingItemContent}>{userInfo ? (userInfo[v.key] as string) || '' : ''}</p>
          </div>
        ))}
        <div className={styles.settingItem}>
          <p className={styles.settingItemTitle}>
            <FormattedMessage id="密码" />
          </p>
          {userInfo?.identityType === 'EMAIL' ? (
            <p className={classnames(styles.settingLink)} onClick={() => setModalVisible(true)}>
              <FormattedMessage id="重置密码" />
            </p>
          ) : (
            <Tooltip title={<FormattedMessage id="重置密码不可用提示" values={{ type: userIdentityType }} />}>
              <p className={classnames(styles.settingLink, styles.disabled)}>
                <FormattedMessage id="重置密码" />
              </p>
            </Tooltip>
          )}
        </div>
      </div>
      <ResetPwdModal visible={modalVisible} setVisible={setModalVisible} />
    </div>
  );
};

export default PersonalSettingPage;
