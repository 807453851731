import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DatePicker, Form } from 'antd';
import dayjs from 'dayjs';
import { TongyongJiantou2Zuo } from '@sirius/icons';
import { productPost } from '@api/system-global/__generate__/default/LeaveMessageService';
import BendArrow from '@system-global/assets/icons/bend-arrow.svg?react';
import TipIcon from '@system-global/assets/icons/paytip.svg?react';
import { ReserveModal } from '../reservemodal';
import styles from './styles.module.scss';

const ChatDialog = (props: { daysLeft: number }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const goBack = useCallback(() => {
    window.history.back();
  }, []);

  const submit = useCallback(async () => {
    const formData = await form.validateFields();
    try {
      setLoading(true);
      await productPost({ bookAt: formData?.date?.valueOf(), bizSource: 'TRIAL_LT7' });
      setShowConfirm(true);
    } finally {
      setLoading(false);
    }
  }, [form]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <div className={styles.icon}>
          <TipIcon />
        </div>
        <div className={styles.text}>
          <FormattedMessage id="支付数据不足标题" values={{ days: props.daysLeft || '' }} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.subTitle}>
          <FormattedMessage id="支付数据不足描述1" />
        </div>
        <div className={styles.desc}>
          <FormattedMessage id="支付数据不足描述2" />
        </div>
        <div className={styles.dateRange}>
          <Form form={form} initialValues={{ date: dayjs() }}>
            <Form.Item name="date">
              <DatePicker
                size="large"
                popupClassName={styles.datePicker}
                style={{ width: 160 }}
                allowClear={false}
                disabledDate={current => {
                  return current < dayjs().startOf('day') || current > dayjs().add(13, 'day').startOf('day');
                }}
              />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.arrowLine}>
          <BendArrow />
        </div>
      </div>
      <div className={styles.action}>
        <Button type="primary" loading={loading} className={styles.submit} onClick={submit}>
          <FormattedMessage id="支付联系产品客服" />
        </Button>
      </div>
      <div className={styles.backlink}>
        <span onClick={goBack}>
          <TongyongJiantou2Zuo style={{ marginRight: 8 }} />
          <FormattedMessage id="后退" />
        </span>
      </div>

      <ReserveModal open={showConfirm} onCancel={() => setShowConfirm(false)} />
    </div>
  );
};

export default ChatDialog;
