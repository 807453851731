import React from 'react';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import { TongyongTianjia } from '@sirius/icons';
import style from './index.module.scss';

interface props {
  onClick: () => void;
  children?: React.ReactNode;
  styles?: React.CSSProperties;
  type?: 'primary';
  size?: 'default' | 'small' | 'large';
  classNames?: string;
  disabled?: boolean;
  disabledText?: string;
}
const Button: React.FC<props> = ({ children, onClick, styles, disabledText, classNames, disabled = true }) => {
  return (
    <Tooltip rootClassName={style.feedTooltip} title={disabled && disabledText}>
      <div
        className={classnames(style.btn, classNames, {
          [style.disabled]: disabled === true,
        })}
        onClick={() => (disabled ? '' : onClick())}
        style={styles}
      >
        <TongyongTianjia style={{ color: disabled ? '#c9cbd6' : '#fff' }} />
        {children}
      </div>
    </Tooltip>
  );
};

export default Button;
