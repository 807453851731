import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Form, Radio } from 'antd';
import { Modal } from '@ui/components/modal';
import { Input } from '@ui/components/input';
import FilterFormItem from './filter-form-item/index';
import {
  addNotificationPost,
  updateNotificationPost,
} from '@api/system-global/__generate__/default/EmailNotificationService';
import { IApiAddNotificationReq as FieldType } from '@api/system-global/__generate__/default/models';
import { useStore } from '@system-global/store';
import styles from './styles.module.scss';
import { RenderProps } from '../../index';
// eslint-disable-next-line
export const reg = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
interface ICompProps {
  visible: boolean;
  setVisible(visible: boolean, update?: boolean): void;
  editData?: RenderProps;
  defualtName?: string;
}

const NewFeedModal: React.FC<ICompProps> = ({ visible, setVisible, editData, defualtName }) => {
  const intl = useIntl();
  const userData = useStore(state => state.user_currentBindSite);
  const { message } = App.useApp();
  const [form] = Form.useForm<FieldType>();
  const [saving, setSaving] = React.useState(false);

  const onSubmit = () => {
    form.validateFields().then(() => {
      setSaving(true);
      const fromData = form.getFieldsValue();
      if (editData?.id) {
        const params = {
          id: editData.id,
          siteId: editData.siteId,
          status: editData.status,
          ...fromData,
        };
        updateNotificationPost(params)
          .then(res => {
            if (res.data.success) {
              message.success({
                content: intl.formatMessage({ id: 'feed更新成功' }),
                duration: 1,
              });
              setVisible(false, true);
            }
          })
          .finally(() => {
            setSaving(false);
          });
        return;
      }

      addNotificationPost({ ...fromData, website: userData?.website, siteId: userData?.siteId })
        .then(res => {
          if (res.data.success) {
            message.success({
              content: intl.formatMessage({ id: 'feed创建成功' }),
              duration: 1,
            });
            setVisible(false, true);
          }
        })
        .finally(() => {
          setSaving(false);
        });
    });
  };

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    } else {
      if (editData) {
        form.setFieldsValue({ ...editData });
      } else {
        form.setFieldValue('filterList', [{}]);
        form.setFieldValue('executeType', 'DAILY');
        form.setFieldValue('name', defualtName);
      }
    }
  }, [form, visible, editData, defualtName]);

  return (
    <Modal
      open={visible}
      centered
      width={716}
      title={<FormattedMessage id={editData ? '编辑邮件的标题' : '创建邮件的标题'} />}
      okText={<FormattedMessage id="保存Feed" />}
      cancelText={<FormattedMessage id="取消" />}
      okButtonProps={{
        loading: saving,
      }}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={() => {
        onSubmit();
      }}
      className={styles.newFeedModal}
      bodyClassName={styles.feedBody}
    >
      <Form name="form" form={form} layout="vertical" className={styles.feedForm}>
        <Form.Item<FieldType>
          label={intl.formatMessage({ id: 'feed名称' })}
          name="name"
          rules={[
            {
              required: true,
              message: 'Please enter a feed name',
              whitespace: true,
            },
          ]}
        >
          <Input
            variant="filled"
            style={{ width: 608, height: 40 }}
            placeholder={intl.formatMessage({ id: 'feed名称提示文案' })}
          />
        </Form.Item>
        <FilterFormItem form={form} />
        <Form.Item<FieldType>
          label={intl.formatMessage({ id: 'feed发送频率' })}
          name="executeType"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Radio.Group>
            <Radio style={{ paddingRight: 64, fontSize: 14 }} value={'DAILY'}>
              {intl.formatMessage({ id: 'feed日' })}
            </Radio>
            <Radio style={{ fontSize: 14 }} value={'WEEKLY'}>
              {intl.formatMessage({ id: 'feed周' })}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item<FieldType>
          label={intl.formatMessage({ id: 'feed接受者' })}
          name="emailTo"
          validateFirst="parallel"
          rules={[
            {
              required: true,
              message: 'Please enter the email address you want to send',
            },
            () => ({
              validator(_, value) {
                if (reg.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Incorrect format'));
              },
            }),
          ]}
        >
          <Input
            variant="filled"
            style={{ width: 608, height: 40 }}
            placeholder={intl.formatMessage({ id: 'feed邮件接收' })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewFeedModal;
