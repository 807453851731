import DetailBaseInfo from './detail-base-info';
import DetailHeader from './detail-header';
import styles from './index.module.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRequest, useScroll } from 'ahooks';
import {
  CompanyDetailTabsType,
  GlobalSearchCompanyDetail,
  GlobalSearchType,
  ipDetailGet,
} from '@api/system-global/override/GlobalSearch';
import { visitorScoreGet } from '@api/system-global/__generate__/default/VisitorDetailInfoService';

interface VisitorDetailProps {
  onClose?: () => void;
  domain: string;
  siteId: string;
  globalSearchType?: keyof typeof GlobalSearchType;
  expired?: boolean; // 服务是否过期
  defaultActiveTab?: keyof typeof CompanyDetailTabsType;
  refreshStm?: () => void;
}

const VisitorDetail = (props: VisitorDetailProps) => {
  const { domain = '', siteId = '', globalSearchType = 'RELATED_COMPANY', onClose, ...rest } = props;
  const ref = useRef(null);
  const scroll = useScroll(ref);
  const [detailData, setDetailData] = useState<GlobalSearchCompanyDetail>();
  const sticky = useMemo(() => {
    return scroll ? scroll.top >= 100 : false;
  }, [scroll]);

  const { data } = useRequest(
    () => {
      // 只有访客信息才可以查询相关性算分，如果是访客的相似公司推荐，不能查相关性算分
      if (globalSearchType === 'RELATED_COMPANY' && siteId && domain) {
        return visitorScoreGet({
          siteId,
          domain,
        });
      }
      return Promise.resolve(null);
    },
    {
      refreshDeps: [siteId, domain],
    }
  );

  const fetchData = useCallback(async () => {
    try {
      if (!siteId || !domain) return;
      const res = await ipDetailGet({ siteId, domain, source: 1, globalSearchType });
      const { data } = res || {};
      setDetailData((data?.data || {}) as GlobalSearchCompanyDetail);
    } catch (error) {
      console.log('error: ', error);
    }
  }, [domain, globalSearchType, siteId]);

  useEffect(() => {
    fetchData();
  }, [fetchData, siteId]);

  return (
    <div className={styles.visitorDetail} ref={ref}>
      <DetailHeader scoreData={data?.data.data} data={detailData} toggleSticky={sticky} onClose={onClose} />
      <DetailBaseInfo domain={domain} siteId={siteId} globalSearchType={globalSearchType} {...rest} />
    </div>
  );
};

export default VisitorDetail;
