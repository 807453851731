import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Button, message } from 'antd';
import clipboard from 'clipboard';
import dayjs from 'dayjs';
import { useDebounceFn, useMemoizedFn } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { TongyongYouxiang, TongyongFuzhi2 } from '@sirius/icons';
import { Table } from '@ui/components/table';
import CompanyDetail from '@system-global/components/company-detail';
import { UpgradeCard } from '@system-global/components/upgrade-card';
import { CountryFlag } from '@ui/components/country-flag';
import { SearchFilter, SearchParams } from '@feature/components/search-filter';
import { contactExportPost, contactPageAllPost } from '@api/system-global/__generate__/default/ContactBizService';
import { GlobalSearchType } from '@api/system-global/override/GlobalSearch';
import { IApiContactPageItemResp } from '@api/system-global/__generate__/default/models';
import { ExportBtn, ExportModal } from '@feature/components/data-export-modal';
import styles from './index.module.scss';
import { useStore } from '@system-global/store';

const ContactHistory = () => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<IApiContactPageItemResp[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [detailInfo, setDetailInfo] = useState({ domain: '', revealSource: '' });
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const userInfo = useStore(state => state.user_userInfo);

  const initialParams = useMemo(() => {
    return {
      keyword: '',
      beginDate: undefined,
      endDate: undefined,
    };
  }, []);
  const [searchParams, setSearchParams] = useState<SearchParams>(initialParams);
  const curSearchParamsRef = useRef<SearchParams | null>(initialParams);

  const intl = useIntl();
  const pageSize = 20;

  const fetchList = useCallback(async () => {
    try {
      setLoading(true);
      const res = await contactPageAllPost({
        keyword: searchParams.keyword || '',
        beginDate: searchParams.beginDate || undefined,
        endDate: searchParams.endDate || undefined,
        pageNo: page - 1, // 后端从0开始
        pageSize,
      });
      if (res?.data?.data) {
        setList(res.data.data.content || []);
        setTotal(res.data.data.totalSize || 0);
      }
    } finally {
      setLoading(false);
    }
  }, [page, searchParams]);

  const { run: onFilterChange } = useDebounceFn(
    params => {
      curSearchParamsRef.current = params;
      setSearchParams(params);
    },
    { wait: 300 }
  );

  const copyContact = useCallback(
    async (record: IApiContactPageItemResp) => {
      await clipboard.copy(`${record?.contactName || ''}<${record?.contactEmail || ''}>`);
      message.success(intl.formatMessage({ id: '复制成功' }));
    },
    [intl]
  );

  const showDetail = useCallback(async (record: IApiContactPageItemResp) => {
    setDetailInfo({
      domain: record?.companyDomain || '',
      revealSource: record?.revealSource || '',
    });
  }, []);

  useEffect(() => {
    fetchList();
  }, [fetchList]);
  /**
   * 点击导出执行的函数
   */
  const exportOkHandler = useMemoizedFn(async formValues => {
    const filterParams = curSearchParamsRef.current;
    if (!filterParams) {
      return;
    }
    const res = await contactExportPost({
      beginDate: filterParams.beginDate,
      keyword: filterParams.keyword,
      endDate: filterParams.endDate,
      emailTo: formValues.receiverEmail,
      format: formValues.format,
    });
    if (res.data.success !== true) {
      // message.error(res.data.message);
      return;
    }

    message.success(
      intl.formatMessage(
        { id: '数据导出-成功提示#' },
        {
          email: formValues.receiverEmail,
        }
      )
    );
    setExportModalVisible(false);
  });

  return (
    <div className={styles.wrapper}>
      <UpgradeCard className={styles['mb28']} />
      <div className={styles.title}>
        <FormattedMessage id="全部联系人" />
      </div>

      <div className={styles.search}>
        <SearchFilter
          onFilterChange={onFilterChange}
          rightRightSlot={
            <ExportBtn
              disabled={total === 0}
              tooltipTitle={<FormattedMessage id="数据导出-导出联系人按钮提示" />}
              left={10}
              onClick={() => {
                setExportModalVisible(true);
              }}
            />
          }
        />
      </div>
      <div className={styles.table}>
        <Table
          tableLayout="fixed"
          loading={loading}
          dataSource={list}
          pagination={{
            current: page,
            pageSize,
            total,
            showSizeChanger: false,
            showQuickJumper: false,
            onChange: page => {
              setPage(page);
            },
            showTotal: total => (
              <div className={styles.paginationTotal}>
                <FormattedMessage id="共#个联系人" values={{ total, append: total > 1 ? 'contacts' : 'contact' }} />
              </div>
            ),
          }}
          onRow={record => {
            return {
              onClick: () => {
                showDetail(record);
              }, // 点击行
            };
          }}
          columns={[
            {
              title: <FormattedMessage id="联系人姓名职位大写" />,
              key: 'name',
              width: 200,
              render: (_, record: IApiContactPageItemResp) => {
                return (
                  <div className={styles.tableCell}>
                    <div className={styles.contactName} title={record?.contactName || ''}>
                      {record?.contactName || '--'}
                    </div>
                    <div className={styles.contactTitle} title={record?.contactJobTitle || ''}>
                      {record?.contactJobTitle || ''}
                    </div>
                  </div>
                );
              },
            },
            {
              title: <FormattedMessage id="邮箱大写" />,
              key: 'email',
              width: 300,
              render: (_, record: IApiContactPageItemResp) => {
                return (
                  <div className={styles.tableCell}>
                    <div className={styles.email} title={record?.contactEmail || ''}>
                      <TongyongYouxiang className={styles.mailIcon} width={16} height={16} style={{ marginRight: 6 }} />
                      {record.contactEmail ? (
                        <a href={`mailto: ${record.contactEmail}`} onClick={e => e.stopPropagation()}>
                          {record.contactEmail}
                        </a>
                      ) : (
                        <span>--</span>
                      )}
                    </div>
                  </div>
                );
              },
            },
            {
              title: <FormattedMessage id="公司大写" />,
              key: 'company',
              width: 300,
              render: (_, record: IApiContactPageItemResp) => {
                return (
                  <div className={styles.tableCell}>
                    <div
                      className={styles.companyName}
                      title={record?.companyName || ''}
                      onClick={() => showDetail(record)}
                    >
                      <CountryFlag code={record.companyCountry} size={14} style={{ marginRight: 4 }} />
                      {record?.companyName || <span>--</span>}
                    </div>
                    <div className={styles.companyWebsite} title={record?.companyWebsite || ''}>
                      <a
                        href={record?.companyWebsite || '#'}
                        target="_blank"
                        rel="noreferrer"
                        onClick={e => e.stopPropagation()}
                      >
                        {record?.companyWebsite || '--'}
                      </a>
                    </div>
                  </div>
                );
              },
            },
            {
              title: <FormattedMessage id="联系人解锁时间大写" />,
              key: 'time',
              width: 170,
              render: (_, record: IApiContactPageItemResp) => {
                return (
                  <div className={styles.tableCell}>
                    <div className={styles.revealAt}>
                      {record?.revealAt ? dayjs(record.revealAt).format('D MMM, YYYY') : '--'}
                    </div>
                  </div>
                );
              },
            },
            {
              title: <FormattedMessage id="操作大写" />,
              key: 'op',
              align: 'center',
              width: 120,
              render: (_, record: IApiContactPageItemResp) => {
                return (
                  <div className={styles.tableCell}>
                    <Button
                      className={styles.copyBtn}
                      type="text"
                      onClick={e => {
                        e.stopPropagation();
                        copyContact(record);
                      }}
                    >
                      <TongyongFuzhi2 />
                      <FormattedMessage id="复制" />
                    </Button>
                  </div>
                );
              },
            },
          ]}
        />
      </div>

      <CompanyDetail
        domain={detailInfo.domain}
        globalSearchType={detailInfo.revealSource as GlobalSearchType}
        onClose={() => setDetailInfo({ domain: '', revealSource: '' })}
      />
      <ExportModal
        initialEmail={userInfo?.email}
        visible={exportModalVisible}
        onOk={exportOkHandler}
        onCancel={() => {
          setExportModalVisible(false);
        }}
      />
    </div>
  );
};

export default ContactHistory;
